import 'react-toastify/dist/ReactToastify.css';
import React from 'react';
import { ToastContainer } from 'react-toastify';
import HeaderProfile from './header/HeaderProfile';
import LogoutButton from './header/LogoutButton';
import MessagesHolder from './header/MessagesHolder';
import NotificationHolder from './header/NotificationHolder';

const Header = ({showNav, navBarShowUpdated}) => {
    return (
        <div className="dashboardContentHeader">
            <div className="dashboardContentHeaderIcon" onClick={navBarShowUpdated}>
                <i className="fas fa-align-left dashboardContentHeaderIco"></i>
            </div>
            <div className="dashboardContentHeaderRight">
                <MessagesHolder showBubble={false} />
                <NotificationHolder showBubble={true} />
                <HeaderProfile />
                <LogoutButton />
            </div>
            <ToastContainer style={{ zIndex: "99999 !important" }} />
        </div>
    );
};

export default Header;
