import React from 'react'
import PlainContainer from '../../../general/containers/PlainContainer'

const Heading = ({children}) => {
    return (
        <PlainContainer styles={{width: '100%', float: 'left', display: 'flex', flexDirection: 'row', borderBottom: '1px solid #3d3d3d'}}>
            {children}
        </PlainContainer>
    )
}

export default Heading