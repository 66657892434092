import React, { useEffect, useState } from 'react';
import { BRAND_NAME } from '../../../config';
import PlainContainer from '../../general/containers/PlainContainer';
import Text from '../../general/typography/Text';
import Header from './Header';
import Navbar from './Navbar';

import { useSelector } from 'react-redux';

const Layout = ({children}) => {

  const admin = useSelector((state) => state.admin.value);

  const [showFullNavigation, setShowFullNavigation] = useState(true);

  const enableOrDisableNavbar = () => {
    setShowFullNavigation(showFullNavigation ? false : true);
  }

  return (
      <>
        <Navbar showNav={showFullNavigation} />

        <div className={showFullNavigation ? "dashboardContainer hasNav" : "dashboardContainer full"}>
          <Header showNav={showFullNavigation} navBarShowUpdated={enableOrDisableNavbar} />

          <PlainContainer type='fullwidth' styles={{padding: '20px', position: 'static'}}>
            <PlainContainer type='fullwidth'>
              <PlainContainer type='fullwidth'>
                <Text variant='page-heading' type='default'>Hi, Welcome to {BRAND_NAME}.</Text>
              </PlainContainer>
                
              <PlainContainer type='fullwidth' styles={{marginTop: '5px'}}>
                <Text variant='page-subheading' type='default'>Manage Everything About {BRAND_NAME} In One Dashboard!</Text>
              </PlainContainer>
            </PlainContainer>

            <PlainContainer type='fullwidth' styles={{marginTop: '20px', position: 'static'}}>
              {children}
            </PlainContainer>
          </PlainContainer>
        </div>
      </>
  );
};

export default Layout;
