import React from 'react'
import DetailSectionHeadingButton from './DetailSectionHeadingButton'

const DetailSection = ({lable, hasPrimaryAction, lablePrimaryAction, primaryAction, hasSecondaryAction, lableSecondaryAction, secondaryAction, children}) => {
    return (
        <div className="detailSection">
            <div className="detailSectionHeading">
                <div className="detailSectionHeadingLable">
                    <p className="detailSectionHeadingLableTxt">{lable}</p>
                </div>
                <div className="detailSectionHeadingActions">
                    {
                        hasPrimaryAction
                        ?
                        <DetailSectionHeadingButton lable={lablePrimaryAction} action={primaryAction} />
                        :
                        <></>
                    }
                    {
                        hasSecondaryAction
                        ?
                        <DetailSectionHeadingButton lable={lableSecondaryAction} action={secondaryAction} />
                        :
                        <></>
                    }
                </div>
            </div>

            <div className="detailSectionContent">
                {children}
            </div>
        </div>
    )
}

export default DetailSection