import React, { useState, useEffect, useRef, forwardRef} from 'react';
import { useLocation, useNavigate } from "react-router-dom";

import PlainContainer from '../../../../../../components/general/containers/PlainContainer';
import SplitContainer from '../../../../../../components/general/containers/SplitContainer';
import Section from '../../../../../../components/general/containers/Section';
import Form from '../../../../../../components/general/forms/core/Form';
import FormActions from '../../../../../../components/general/forms/core/FormActions';
import TextInput from '../../../../../../components/general/forms/TextInput';
import Button from '../../../../../../components/general/forms/Button';

import axios from 'axios';

import { useSelector } from 'react-redux';
import { API_BASE_URL, LOGGER } from '../../../../../../config';
import { notifyError, notifySuccess } from '../../../../../../utils/toastify';
import { CONTACT_DETAILS_UPDATED, REQUEST_ERROR } from '../../../../../../utils/messages';

const EditHeaderContactDetailsPage = () => {
    let navigate = useNavigate();
    const location = useLocation();

    const [routeArray, setRouteArray] = useState(location.pathname.substring(1).split('/'));
    const [routeText, setRouteText] = useState('');

    useEffect(() => {
        let tempRoute = '';
        routeArray.map(route => {
            tempRoute = tempRoute.concat(`${route} / `);
            tempRoute = tempRoute.replace('-', ' ');
        });
        setRouteText(removeLastChars(tempRoute, 3));
    }, []);

    let removeLastChars = (str, chars) => {
        return str.substring(0, str.length - chars);
    }

    const admin = useSelector((state) => state.admin.value);

    const config = {
        headers: {
        'Authorization': admin.access_token
        }
    }

    const [locationHeading, setLocationHeading] = useState('');
    const [locationDescription, setLocationDescription] = useState('');
    const [phoneHeading, setPhoneHeading] = useState('');
    const [phoneDescription, setPhoneDescription] = useState('');
    const [emailHeading, setEmailHeading] = useState('');
    const [emailDescription, setEmailDescription] = useState('');

    const [isLocationHeadingError, setIsLocationHeadingError] = useState(false);
    const [isLocationDescriptionError, setIsLocationDescriptionError] = useState(false);
    const [isPhoneHeadingError, setIsPhoneHeadingError] = useState(false);
    const [isPhoneDescriptionError, setIsPhoneDescriptionError] = useState(false);
    const [isEmailHeadingError, setIsEmailHeadingError] = useState(false);
    const [isEmailDescriptionError, setIsEmailDescriptionError] = useState(false);

    const [locationHeadingErrorTxt, setLocationHeadingErrorTxt] = useState("");
    const [locationDescriptionErrorTxt, setLocationDescriptionErrorTxt] = useState("");
    const [phoneHeadingErrorTxt, setPhoneHeadingErrorTxt] = useState("");
    const [phoneDescriptionErrorTxt, setPhoneDescriptionErrorTxt] = useState("");
    const [emailHeadingErrorTxt, setEmailHeadingErrorTxt] = useState("");
    const [emailDescriptionErrorTxt, setEmailDescriptionErrorTxt] = useState("");

    const [resetAttachment, setResetAttachment] = useState(false);

    const getHeaderContactDetailsHandler = async () => {
        try {
            const getHeaderContactDetailsReq = await axios.get(`${API_BASE_URL}header/contact-details`);
            console.log(getHeaderContactDetailsReq);
            setLocationHeading(getHeaderContactDetailsReq.data.location_heading);
            setLocationDescription(getHeaderContactDetailsReq.data.location_description);
            setPhoneHeading(getHeaderContactDetailsReq.data.phone_no_heading);
            setPhoneDescription(getHeaderContactDetailsReq.data.phone_no_description);
            setEmailHeading(getHeaderContactDetailsReq.data.email_id_heading);
            setEmailDescription(getHeaderContactDetailsReq.data.email_id_description);
        }
        catch (error) {
            if(error.response.status === 404){
                console.log('not found');
            }
        }
    }

    useEffect(() => {
        getHeaderContactDetailsHandler();
    }, []);

    const submitEditThisForm = async (e) => {
        e.preventDefault();

        if(locationHeading == ''){
            setLocationHeadingErrorTxt('Location heading is required');
            setIsLocationHeadingError(true);
            return;
        }
        else{
            setLocationHeadingErrorTxt('');
            setIsLocationHeadingError(false);
        }

        if(locationDescription == ''){
            setLocationDescriptionErrorTxt('Location description is required');
            setIsLocationDescriptionError(true);
            return;
        }
        else{
            setLocationDescriptionErrorTxt('');
            setIsLocationDescriptionError(false);
        }

        if(phoneHeading == ''){
            setPhoneHeadingErrorTxt('Phone heading is required');
            setIsPhoneHeadingError(true);
            return;
        }
        else{
            setPhoneHeadingErrorTxt('');
            setIsPhoneHeadingError(false);
        }

        if(phoneDescription == ''){
            setPhoneDescriptionErrorTxt('Phone description is required');
            setIsPhoneDescriptionError(true);
            return;
        }
        else{
            setPhoneDescriptionErrorTxt('');
            setIsPhoneDescriptionError(false);
        }

        if(emailHeading == ''){
            setEmailHeadingErrorTxt('Email heading is required');
            setIsEmailHeadingError(true);
            return;
        }
        else{
            setEmailHeadingErrorTxt('');
            setIsEmailHeadingError(false);
        }

        if(emailDescription == ''){
            setEmailDescriptionErrorTxt('Email description is required');
            setIsEmailDescriptionError(true);
            return;
        }
        else{
            setEmailDescriptionErrorTxt('');
            setIsEmailDescriptionError(false);
        }

        const data = {
            location_heading: locationHeading,
            location_description: locationDescription,
            phone_no_heading: phoneHeading,
            phone_no_description: phoneDescription,
            email_id_heading: emailHeading,
            email_id_description: emailDescription
        }

        try {
            const updateHeaderContactDetailsReq = await axios.patch(`${API_BASE_URL}header/contact-details/edit`, data, config);
            notifySuccess(CONTACT_DETAILS_UPDATED);
            navigate('/dashboard/general/header/contact-details');
            LOGGER &&  console.log(updateHeaderContactDetailsReq);
        }
        catch (error) {
            notifyError(REQUEST_ERROR);
            LOGGER &&  console.log(error);
        }
    }

    const resetForm = () => {
        
    }

    return (
        <Section heading="contact details" description={routeText} action={() => navigate("/dashboard/general/header/contact-details")} actionLable="back" showAction={true}>
            <PlainContainer styles={{width: '100%', display: 'flex', flexDirection: 'column', position: 'static'}}>
                <form onSubmit={(e) => submitEditThisForm(e)} noValidate>
                    <Form>
                        <SplitContainer type="fullwidth">
                            <TextInput lable="location heading" isMandatory={true} isError={isLocationHeadingError} error={locationHeadingErrorTxt}>
                                <input type="text" className="formStepInputTxt capitalize" value={locationHeading} placeholder="Please Enter The Location Heading" onChange={(e) => setLocationHeading(e.target.value)} />
                            </TextInput>
                            <TextInput lable="location description" isMandatory={true} isError={isLocationDescriptionError} error={locationDescriptionErrorTxt}>
                                <input type="text" className="formStepInputTxt capitalize" value={locationDescription} placeholder="Please Enter The Location Description" onChange={(e) => setLocationDescription(e.target.value)} />
                            </TextInput>
                        </SplitContainer>
                        <SplitContainer type="fullwidth">
                            <TextInput lable="phone no. heading" isMandatory={true} isError={isPhoneHeadingError} error={phoneHeadingErrorTxt}>
                                <input type="text" className="formStepInputTxt capitalize" value={phoneHeading} placeholder="Please Enter The Phone No. Heading" onChange={(e) => setPhoneHeading(e.target.value)} />
                            </TextInput>
                            <TextInput lable="phone no. description" isMandatory={true} isError={isPhoneDescriptionError} error={phoneDescriptionErrorTxt}>
                                <input type="text" className="formStepInputTxt capitalize" value={phoneDescription} placeholder="Please Enter The Phone No. Description" onChange={(e) => setPhoneDescription(e.target.value)} />
                            </TextInput>
                        </SplitContainer>
                        <SplitContainer type="fullwidth">
                            <TextInput lable="email heading" isMandatory={true} isError={isEmailHeadingError} error={emailHeadingErrorTxt}>
                                <input type="text" className="formStepInputTxt capitalize" value={emailHeading} placeholder="Please Enter The Email Heading" onChange={(e) => setEmailHeading(e.target.value)} />
                            </TextInput>
                            <TextInput lable="email description" isMandatory={true} isError={isEmailDescriptionError} error={emailDescriptionErrorTxt}>
                                <input type="text" className="formStepInputTxt capitalize" value={emailDescription} placeholder="Please Enter The Email Description" onChange={(e) => setEmailDescription(e.target.value)} />
                            </TextInput>
                        </SplitContainer>
                        <FormActions isPrimary={true} isSecondary={true} primaryLable="submit" secondaryLable="reset" secondaryAction={() => resetForm()} />
                    </Form>
                </form>
            </PlainContainer>
        </Section>
    )
}

export default EditHeaderContactDetailsPage