import React, { useEffect, useState } from "react"
import Section from "../../../../../../components/general/containers/Section";
import PlainContainer from "../../../../../../components/general/containers/PlainContainer";
import Form from "../../../../../../components/general/forms/core/Form";
import SplitContainer from "../../../../../../components/general/containers/SplitContainer";
import TextInput from "../../../../../../components/general/forms/TextInput";
import TextArea from "../../../../../../components/general/forms/TextArea";
import FileInput from "../../../../../../components/general/forms/FileInput";
import FormActions from "../../../../../../components/general/forms/core/FormActions";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { API_BASE_URL, LOGGER } from "../../../../../../config";
import { OPENING_UPDATED, PRODUCTS_UPDATED } from "../../../../../../utils/messages";
import { notifyError, notifySuccess } from "../../../../../../utils/toastify";
import axios from "axios";


const EditPopupAd = () => {
    const location = useLocation();
    let navigate = useNavigate();

    let { adslid_id } = useParams();

    const [routeArray, setRouteArray] = useState(location.pathname.substring(1).split('/'));
    const [routeText, setRouteText] = useState('');

    useEffect(() => {
        let tempRoute = '';
        routeArray.map(route => {
            tempRoute = tempRoute.concat(`${route} / `);
            tempRoute = tempRoute.replace('-', ' ');
        });
        setRouteText(removeLastChars(tempRoute, 3));
    }, []);

    let removeLastChars = (str, chars) => {
        return str.substring(0, str.length - chars);
    }


    const admin = useSelector((state) => state.admin.value);

    const config = {
        headers: {
            'Authorization': admin.access_token
        }
    }



    const [productTitle, setProductTitle] = useState('');
    const [seoURL, setSeoURL] = useState('');
    const [adDescription, setAdDescription] = useState('');
    const [image, setImage] = useState('');
    const [imageID, setImageID] = useState('');

    const [showImagePreview, setShowImagePreview] = useState(false);
    const [resetAttachment, setResetAttachment] = useState(false);

    const [isProductTitleError, setIsProductTitleError] = useState(false);
    const [isSeoURLError, setIsSeoURLError] = useState(false);
    const [isAdDescriptionError, setIsAdDescriptionError] = useState(false);
    const [isFeaturedImageError, setIsFeaturedImageError] = useState(false);

    const [productTitleErrorTxt, setProductTitleErrorTxt] = useState('');
    const [seoURLErrorTxt, setSeoURLErrorTxt] = useState('');
    const [adDescriptionErrorTxt, setAdDescriptionErrorTxt] = useState('');
    const [featuredImageErrorTxt, setFeaturedImageErrorTxt] = useState('');


    const getThisPopupHandler = async () => {
        try {

            const getThisAdslideProductReq = await axios.get(`${API_BASE_URL}home/popupad/get`);
            
            setProductTitle(getThisAdslideProductReq.data.title);
            setAdDescription(getThisAdslideProductReq.data.description)
            setImageID(getThisAdslideProductReq.data.image.id)
            setSeoURL(getThisAdslideProductReq.data.seoUrl);
            setImage(getThisAdslideProductReq.data.image.url)
            setShowImagePreview(true)
        }
        catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getThisPopupHandler();
    }, []);





    const submitFormData = async (e) => {
        e.preventDefault();

        // Validation logic
        if (!productTitle || productTitle.trim() === '') {
            setProductTitleErrorTxt('Product Title is required');
            setIsProductTitleError(true);
            return;
        } else {
            setProductTitleErrorTxt('');
            setIsProductTitleError(false);
        }

        if (!seoURL || seoURL.trim() === '') {
            setSeoURLErrorTxt('SEO URL is required');
            setIsSeoURLError(true);
            return;
        } else {
            setSeoURLErrorTxt('');
            setIsSeoURLError(false);
        }

        if (!adDescription || adDescription.trim() === '') {
            setAdDescriptionErrorTxt('Ad Description is required');
            setIsAdDescriptionError(true);
            return;
        } else {
            setAdDescriptionErrorTxt('');
            setIsAdDescriptionError(false);
        }

        if (!imageID) {
            setFeaturedImageErrorTxt('Featured Image is required');
            setIsFeaturedImageError(true);
            return;
        } else {
            setFeaturedImageErrorTxt('');
            setIsFeaturedImageError(false);
        }

        // Placeholder API call
        // const formData = new FormData();
        // formData.append('title', productTitle);
        // formData.append('seoUrl', seoURL);
        // formData.append('description', adDescription);
        // formData.append('image', imageID);

        const data = {
            title: productTitle,
            description: adDescription,
            image: imageID,
            seoUrl: seoURL
        }

        try {
            const response = await axios.post(`${API_BASE_URL}home/popupad/edit`, data, config);
            notifySuccess(PRODUCTS_UPDATED);
            LOGGER && console.log(response);
            navigate("/dashboard/pages/home/popup-ad");
        } catch (error) {
            notifyError('Error submitting data');
            LOGGER && console.error(error);
        }
    };




    // const submitFormData = async (e) => {
    //     e.preventDefault();

    //     // Validation logic
    //     if (productTitle.trim() === '') {
    //         setProductTitleErrorTxt('Product Title is required');
    //         setIsProductTitleError(true);
    //         return;
    //     } else {
    //         setProductTitleErrorTxt('');
    //         setIsProductTitleError(false);
    //     }

    //     if (seoURL.trim() === '') {
    //         setSeoURLErrorTxt('SEO URL is required');
    //         setIsSeoURLError(true);
    //         return;
    //     } else {
    //         setSeoURLErrorTxt('');
    //         setIsSeoURLError(false);
    //     }

    //     if (adDescription.trim() === '') {
    //         setAdDescriptionErrorTxt('Ad Description is required');
    //         setIsAdDescriptionError(true);
    //         return;
    //     } else {
    //         setAdDescriptionErrorTxt('');
    //         setIsAdDescriptionError(false);
    //     }

    //     if (imageID === null) {
    //         setFeaturedImageErrorTxt('Featured Image is required');
    //         setIsFeaturedImageError(true);
    //         return;
    //     } else {
    //         setFeaturedImageErrorTxt('');
    //         setIsFeaturedImageError(false);
    //     }

    //     // Placeholder API call
    //     // const formData = new FormData();
    //     // formData.append('title', productTitle);
    //     // formData.append('seoUrl', seoURL);
    //     // formData.append('description', adDescription);
    //     // formData.append('image', imageID);

    //     const data = {
    //         title: productTitle,
    //         description: adDescription,
    //         image: imageID,
    //         seoUrl: seoURL
    //     }



    //     try {
    //         const response = await axios.post(`${API_BASE_URL}home/popupad/edit`, data, config);
    //         notifySuccess(PRODUCTS_UPDATED);
    //         LOGGER && console.log(response);
    //         navigate("/dashboard/pages/home/main-ads-slider");
    //     } catch (error) {
    //         notifyError('Error submitting data');
    //         LOGGER && console.error(error);
    //     }
    // };

    const resetForm = () => {
        setProductTitle('');
        setSeoURL('');
        setAdDescription('');
        setImageID(null);
        setIsProductTitleError(false);
        setIsSeoURLError(false);
        setIsAdDescriptionError(false);
        setIsFeaturedImageError(false);
        setProductTitleErrorTxt('');
        setSeoURLErrorTxt('');
        setAdDescriptionErrorTxt('');
        setFeaturedImageErrorTxt('');
    };

    return (
        <Section heading="Pop Ad" description={routeText} action={() => navigate("/dashboard/pages/home/popup-ad")} actionLable="back" showAction={true}>

            <PlainContainer styles={{ width: '100%', display: 'flex', flexDirection: 'column', position: 'static' }}>
                <form onSubmit={(e) => submitFormData(e)} noValidate>

                    <Form >

                        <SplitContainer type={`fullwidth`}>
                            <TextInput lable="Product title" isMandatory={true} isError={isProductTitleError} error={productTitleErrorTxt} >
                                <input type="text" className="formStepInputTxt capitalize" placeholder='Enter Product Title'
                                    value={productTitle}
                                    onChange={(e) => setProductTitle(e.target.value)}
                                />

                            </TextInput>
                            <TextInput lable="SEO URL" isMandatory={true} isError={isSeoURLError} error={seoURLErrorTxt}>
                                <input type="text" className="formStepInputTxt capitalize" placeholder='Enter the product full url'
                                    value={seoURL}
                                    onChange={(e) => setSeoURL(e.target.value)}
                                />
                            </TextInput>
                        </SplitContainer>
                        <TextArea lable="Ad Description" rows={3}
                            placeholder='Enter Description'
                            isMandatory={true} isError={isAdDescriptionError} error={adDescriptionErrorTxt} value={adDescription} onchange={(e) => setAdDescription(e)}

                        />


                        <FileInput lable="Featured image"
                            isMandatory={true}
                            value={imageID}
                            preview={image}
                            onchange={(data) => { setImageID(data); LOGGER && console.log(data) }}
                            isError={isFeaturedImageError}
                            error={featuredImageErrorTxt}
                            showPreview={showImagePreview}
                            reset={resetAttachment} />

                        <FormActions isPrimary={true} isSecondary={true} primaryLable="submit" secondaryLable="reset" secondaryAction={() => resetForm()} />

                    </Form>
                </form>
            </PlainContainer>
        </Section>

    )

}

export default EditPopupAd