import React, { useState, useEffect, useRef, forwardRef} from 'react';
import { useLocation, useNavigate } from "react-router-dom";

import PlainContainer from '../../../../../../components/general/containers/PlainContainer';
import SplitContainer from '../../../../../../components/general/containers/SplitContainer';
import Section from '../../../../../../components/general/containers/Section';
import Form from '../../../../../../components/general/forms/core/Form';
import FormActions from '../../../../../../components/general/forms/core/FormActions';
import TextInput from '../../../../../../components/general/forms/TextInput';
import TextArea from '../../../../../../components/general/forms/TextArea';
import Button from '../../../../../../components/general/forms/Button';

import axios from 'axios';

import { useSelector } from 'react-redux';
import { API_BASE_URL, LOGGER } from '../../../../../../config';
import { notifyError, notifySuccess } from '../../../../../../utils/toastify';
import { REQUEST_ERROR, SEO_UPDATED } from '../../../../../../utils/messages';

const EditGallerySeoPage = () => {
    let navigate = useNavigate();
    const location = useLocation();

    const [routeArray, setRouteArray] = useState(location.pathname.substring(1).split('/'));
    const [routeText, setRouteText] = useState('');

    useEffect(() => {
        let tempRoute = '';
        routeArray.map(route => {
            tempRoute = tempRoute.concat(`${route} / `);
            tempRoute = tempRoute.replace('-', ' ');
        });
        setRouteText(removeLastChars(tempRoute, 3));
    }, []);

    let removeLastChars = (str, chars) => {
        return str.substring(0, str.length - chars);
    }

    const admin = useSelector((state) => state.admin.value);

    const config = {
        headers: {
        'Authorization': admin.access_token
        }
    }

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [keywords, setKeywords] = useState('');

    const [isTitleError, setIsTitleError] = useState(false);
    const [isDescriptionError, setIsDescriptionError] = useState(false);
    const [isKeywordsError, setIsKeywordsError] = useState(false);

    const [titleErrorTxt, setTitleErrorTxt] = useState("");
    const [descriptionErrorTxt, setDescriptionErrorTxt] = useState("");
    const [keywordsErrorTxt, setKeywordsErrorTxt] = useState("");

    const [resetAttachment, setResetAttachment] = useState(false);

    const getGallerySeoHandler = async () => {
        try {
            const getGallerySeoReq = await axios.get(`${API_BASE_URL}gallery/seo`);
            console.log(getGallerySeoReq);
            setTitle(getGallerySeoReq.data.title);
            setDescription(getGallerySeoReq.data.description);
            setKeywords(getGallerySeoReq.data.keywords);
        }
        catch (error) {
            if(error.response.status === 404){
                console.log('not found');
            }
        }
    }

    useEffect(() => {
        getGallerySeoHandler();
    }, []);

    const submitEditThisForm = async (e) => {
        e.preventDefault();

        if(title == ''){
            setTitleErrorTxt('Title is required');
            setIsTitleError(true);
            return;
        }
        else{
            setTitleErrorTxt('');
            setIsTitleError(false);
        }

        const data = {
            title: title,
            description: description,
            keywords: keywords
        }

        try {
            const updateGallerySeoReq = await axios.patch(`${API_BASE_URL}gallery/seo/edit`, data, config);
            notifySuccess(SEO_UPDATED);
            navigate('/dashboard/pages/gallery/seo');
            LOGGER &&  console.log(updateGallerySeoReq);
        }
        catch (error) {
            notifyError(REQUEST_ERROR);
            LOGGER &&  console.log(error);
        }
    }

    const resetForm = () => {
        
    }

    return (
        <Section heading="SEO" description={routeText} action={() => navigate("/dashboard/pages/gallery/seo")} actionLable="back" showAction={true}>
            <PlainContainer styles={{width: '100%', display: 'flex', flexDirection: 'column', position: 'static'}}>
                <form onSubmit={(e) => submitEditThisForm(e)} noValidate>
                    <Form>
                        <TextInput lable="title" isMandatory={true} isError={isTitleError} error={titleErrorTxt}>
                            <input type="text" className="formStepInputTxt capitalize" value={title} placeholder="Please Enter The Title" onChange={(e) => setTitle(e.target.value)} />
                        </TextInput>
                        <TextArea lable="description" rows={3} isMandatory={false} placeholder="Please Enter The Description" value={description} onchange={(data) => setDescription(data)} isError={isDescriptionError} error={descriptionErrorTxt} />
                        <TextArea lable="keywords" rows={3} isMandatory={false} placeholder="Please Enter The Keywords" value={keywords} onchange={(data) => setKeywords(data)} isError={isKeywordsError} error={keywordsErrorTxt} />
                        <FormActions isPrimary={true} isSecondary={true} primaryLable="submit" secondaryLable="reset" secondaryAction={() => resetForm()} />
                    </Form>
                </form>
            </PlainContainer>
        </Section>
    )
}

export default EditGallerySeoPage