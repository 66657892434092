import React, { useState, useEffect} from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import Section from '../../../../components/general/containers/Section';
import PageLinkButton from '../../../../components/general/forms/PageLinkButton';
import PageLinkSection from '../../../../components/general/forms/PageLinkSection';

const ProductsPage = () => {
    let navigate = useNavigate();
    const location = useLocation();
    const [routeArray, setRouteArray] = useState(location.pathname.substring(1).split('/'));
    const [routeText, setRouteText] = useState('');

    useEffect(() => {
        let tempRoute = '';
        routeArray.map(route => {
            tempRoute = tempRoute.concat(`${route} / `);
            tempRoute = tempRoute.replace('-', ' ');
        });
        setRouteText(removeLastChars(tempRoute, 3));
    }, []);

    let removeLastChars = (str, chars) => {
        return str.substring(0, str.length - chars);
    }

    return (
        <Section heading="products page" description={routeText} action={""} showAction={false}>
            <PageLinkSection>
                <PageLinkButton lable="SEO" to="seo" />
                <PageLinkButton lable="headings" to="headings" />
                <PageLinkButton lable="categories" to="categories" />
                <PageLinkButton lable="products" to="products" />
            </PageLinkSection>
        </Section>
    )
}

export default ProductsPage