import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Section from "../../../../../components/general/containers/Section";
import PlainContainer from "../../../../../components/general/containers/PlainContainer";
import DetailSection from "../../../../../components/dashboard/common/detailSection/DetailSection";
import DetailSectionContent from "../../../../../components/dashboard/common/detailSection/DetailSectionContent";
import DetailSectionRow from "../../../../../components/dashboard/common/detailSection/DetailSectionRow";
import { notifySuccess } from "../../../../../utils/toastify";
import { API_BASE_URL } from "../../../../../config";
import { OPENING_DELETED } from "../../../../../utils/messages";
import { useSelector } from "react-redux";

const HomeSliderContaint = () => {
    const admin = useSelector((state) => state.admin.value);

    let navigate = useNavigate();
    const location = useLocation();

    const [routeArray, setRouteArray] = useState(location.pathname.substring(1).split('/'));
    const [routeText, setRouteText] = useState('');
    const [adsliders, setAdSliders] = useState([]);

    useEffect(() => {
        let tempRoute = '';
        routeArray.map(route => {
            tempRoute = tempRoute.concat(`${route} / `);
            tempRoute = tempRoute.replace('-', ' ');
        });
        setRouteText(removeLastChars(tempRoute, 3));
    }, []);

    // Function to remove last characters from a string
    const removeLastChars = (str, chars) => {
        return str.substring(0, str.length - chars);
    }

    const config = {
        headers: {
            'Authorization': admin.access_token
        }
    }

    const fetchSlidersData = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}home/mainadslide/get`);
            console.log(response.data)
            setAdSliders(response.data);
        } catch (error) {
            if (error.response.status === 404) {
                console.log('not found');
            }
        }
    };

    useEffect(() => {
        fetchSlidersData();
    }, []);

    const deleteThisSlide = async (id) => {
        
        try {
            const deleteThisadslideReq = await axios.delete(`${API_BASE_URL}home/mainadslide/${id}`, config);
            console.log(deleteThisadslideReq.data)
            fetchSlidersData();
            notifySuccess(OPENING_DELETED);
        } catch (error) {
            console.error('Error deleting slide:', error);

            if (error.response && error.response.status === 404) {
                console.log('Slide not found');
            } else {
                console.log('An unexpected error occurred');
            }
        }
    };

    return (
        <Section heading="Main slider Ads" description={routeText} action={() => navigate("/dashboard/pages/home")} actionLable="back" showAction={true}>
            <PlainContainer type="fullwidth">
                <DetailSection lable="List of all Sliders" hasPrimaryAction={true} lablePrimaryAction={`create`} primaryAction={() => navigate("/dashboard/pages/home/main-ads-slider/create")} hasSecondaryAction={false}>
                    <div style={{ paddingTop: `20px`, paddingLeft: `20px`, paddingRight: `20px` }}>
                        {adsliders.map((adslide) => (
                            <div style={{ width: `100%`, float: `left`, marginBottom: `20px` }} key={adslide.id}>
                                <DetailSection lable={adslide.title} hasPrimaryAction={true} lablePrimaryAction={`edit`} primaryAction={() => navigate(`/dashboard/pages/home/main-ads-slider/edit/${adslide._id}`)} hasSecondaryAction={true} lableSecondaryAction={`delete`} secondaryAction={() => deleteThisSlide(adslide._id)}>
                                    <DetailSectionContent hasPadding={true}>
                                        <DetailSectionRow lable={`title`} value={adslide.title} isImage={false} isIcon={false} hasChildren={false} />
                                        <DetailSectionRow lable={`description`} value={adslide.description} isImage={false} isIcon={false} hasChildren={false} />
                                        <DetailSectionRow lable={`path`} value={adslide.seoUrl} isImage={false} isIcon={false} hasChildren={false} />
                                        <DetailSectionRow lable={`image`} value={adslide.image.url} isImage={true} isIcon={false} hasChildren={false} />
                                    </DetailSectionContent>
                                </DetailSection>
                            </div>
                        ))}
                    </div>
                </DetailSection>
            </PlainContainer>
        </Section>
    );
};

export default HomeSliderContaint;
