import React from 'react';

const MessagesHolder = ({showBubble}) => {
    return (
        <>
            <div className="dashboardContentHeaderSmall">
                <div className="dashboardContentHeaderSmallInner">
                    <div className="dashboardContentHeaderSmallIcon">
                        <i className="far fa-envelope dashboardContentHeaderSmallIco" />
                        {
                            showBubble
                            ?
                            <div className="dashboardContentHeaderSmallIconNew">&nbsp;</div>
                            :
                            <></>
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default MessagesHolder;
