import React from 'react'
import PlainContainer from '../../../general/containers/PlainContainer'
import Text from '../../../general/typography/Text'

const RowItem = ({size, lable, isCenter, hasChildren, isCapital, children }) => {
    return (
        <div className={isCenter ? `tableRowItem center col${size}` : `tableRowItem vCenter col${size}`} style={{flex: size}}>
            <PlainContainer styles={ hasChildren ? {padding: '0px 10px', width: '100%', display: 'flex', justifyContent: 'space-between'} : {padding: '0px 10px'}}>
                {
                    hasChildren === false
                    ?
                    <Text variant="table-content" type="default" color="#3d3d3d" isCapital={isCapital}>{lable}</Text>
                    :
                    children
                }
            </PlainContainer>
            
        </div>
    )
}

export default RowItem