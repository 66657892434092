import React from 'react';

const Form = ({paddingTop, paddingLeft, paddingRight, paddingBottom, padding, children}) => {
    let parentClass = "formContainer";
    if(padding === true){
        parentClass = parentClass.concat(" padding");
    }
    else if(paddingTop === true){
        parentClass = parentClass.concat(" paddingTop");
    }
    else if(paddingRight === true){
        parentClass = parentClass.concat(" paddingRight");
    }
    else if(paddingLeft === true){
        parentClass = parentClass.concat(" paddingLeft");
    }
    else if(paddingBottom === true){
        parentClass = parentClass.concat(" paddingBottom");
    }

    return (
        <div className={parentClass}>
            {children}
        </div>
    );
};

export default Form;
