import React, { useState, useEffect, useRef, forwardRef} from 'react';
import { useLocation, useNavigate } from "react-router-dom";

import PlainContainer from '../../../../../../components/general/containers/PlainContainer';
import SplitContainer from '../../../../../../components/general/containers/SplitContainer';
import Section from '../../../../../../components/general/containers/Section';
import Form from '../../../../../../components/general/forms/core/Form';
import FormActions from '../../../../../../components/general/forms/core/FormActions';
import TextInput from '../../../../../../components/general/forms/TextInput';
import TextArea from '../../../../../../components/general/forms/TextArea';
import Button from '../../../../../../components/general/forms/Button';

import axios from 'axios';

import { useSelector } from 'react-redux';
import { API_BASE_URL, LOGGER } from '../../../../../../config';
import { notifyError, notifySuccess } from '../../../../../../utils/toastify';
import { COUPON_CREATED, REQUEST_ERROR, SPCIALIZATIONS_UPDATED } from '../../../../../../utils/messages';

const EditHomeSpecializationsPage = () => {
    let navigate = useNavigate();
    const location = useLocation();

    const [routeArray, setRouteArray] = useState(location.pathname.substring(1).split('/'));
    const [routeText, setRouteText] = useState('');

    useEffect(() => {
        let tempRoute = '';
        routeArray.map(route => {
            tempRoute = tempRoute.concat(`${route} / `);
            tempRoute = tempRoute.replace('-', ' ');
        });
        setRouteText(removeLastChars(tempRoute, 3));
    }, []);

    let removeLastChars = (str, chars) => {
        return str.substring(0, str.length - chars);
    }

    const admin = useSelector((state) => state.admin.value);

    const config = {
        headers: {
        'Authorization': admin.access_token
        }
    }

    const [specialization1Title, setSpecialization1Title] = useState('');
    const [specialization1Description, setSpecialization1Description] = useState('');
    const [specialization1Icon, setSpecialization1Icon] = useState('');
    const [specialization2Title, setSpecialization2Title] = useState('');
    const [specialization2Description, setSpecialization2Description] = useState('');
    const [specialization2Icon, setSpecialization2Icon] = useState('');
    const [specialization3Title, setSpecialization3Title] = useState('');
    const [specialization3Description, setSpecialization3Description] = useState('');
    const [specialization3Icon, setSpecialization3Icon] = useState('');

    const [isSpecialization1TitleError, setIsSpecialization1TitleError] = useState(false);
    const [isSpecialization1DescriptionError, setIsSpecialization1DescriptionError] = useState(false);
    const [isSpecialization1IconError, setIsSpecialization1IconError] = useState(false);
    const [isSpecialization2TitleError, setIsSpecialization2TitleError] = useState(false);
    const [isSpecialization2DescriptionError, setIsSpecialization2DescriptionError] = useState(false);
    const [isSpecialization2IconError, setIsSpecialization2IconError] = useState(false);
    const [isSpecialization3TitleError, setIsSpecialization3TitleError] = useState(false);
    const [isSpecialization3DescriptionError, setIsSpecialization3DescriptionError] = useState(false);
    const [isSpecialization3IconError, setIsSpecialization3IconError] = useState(false);

    const [specialization1TitleErrorTxt, setSpecialization1TitleErrorTxt] = useState("");
    const [specialization1DescriptionErrorTxt, setSpecialization1DescriptionErrorTxt] = useState("");
    const [specialization1IconErrorTxt, setSpecialization1IconErrorTxt] = useState("");
    const [specialization2TitleErrorTxt, setSpecialization2TitleErrorTxt] = useState("");
    const [specialization2DescriptionErrorTxt, setSpecialization2DescriptionErrorTxt] = useState("");
    const [specialization2IconErrorTxt, setSpecialization2IconErrorTxt] = useState("");
    const [specialization3TitleErrorTxt, setSpecialization3TitleErrorTxt] = useState("");
    const [specialization3DescriptionErrorTxt, setSpecialization3DescriptionErrorTxt] = useState("");
    const [specialization3IconErrorTxt, setSpecialization3IconErrorTxt] = useState("");

    const [resetAttachment, setResetAttachment] = useState(false);

    

    const getHomeSpecializationsHandler = async () => {
        try {
            const getHomeSpecializationsReq = await axios.get(`${API_BASE_URL}home/specializations`);
            console.log(getHomeSpecializationsReq);
            setSpecialization1Title(getHomeSpecializationsReq.data.specializations1_title);
            setSpecialization1Description(getHomeSpecializationsReq.data.specializations1_description);
            setSpecialization1Icon(getHomeSpecializationsReq.data.specializations1_icon);
            setSpecialization2Title(getHomeSpecializationsReq.data.specializations2_title);
            setSpecialization2Description(getHomeSpecializationsReq.data.specializations2_description);
            setSpecialization2Icon(getHomeSpecializationsReq.data.specializations2_icon);
            setSpecialization3Title(getHomeSpecializationsReq.data.specializations3_title);
            setSpecialization3Description(getHomeSpecializationsReq.data.specializations3_description);
            setSpecialization3Icon(getHomeSpecializationsReq.data.specializations3_icon);
        }
        catch (error) {
            if(error.response.status === 404){
                console.log('not found');
            }
        }
    }

    useEffect(() => {
        getHomeSpecializationsHandler();
    }, []);

    const submitEditThisForm = async (e) => {
        e.preventDefault();

        if(specialization1Title == ''){
            setSpecialization1TitleErrorTxt('Specialization 1 Title is required');
            setIsSpecialization1TitleError(true);
            return;
        }
        else{
            setSpecialization1TitleErrorTxt('');
            setIsSpecialization1TitleError(false);
        }

        if(specialization1Description == ''){
            setSpecialization1DescriptionErrorTxt('Specialization 1 Description is required');
            setIsSpecialization1DescriptionError(true);
            return;
        }
        else{
            setSpecialization1DescriptionErrorTxt('');
            setIsSpecialization1DescriptionError(false);
        }

        if(specialization1Icon == ''){
            setSpecialization1IconErrorTxt('Specialization 1 icon is required');
            setIsSpecialization1IconError(true);
            return;
        }
        else{
            setSpecialization1IconErrorTxt('');
            setIsSpecialization1IconError(false);
        }

        if(specialization2Title == ''){
            setSpecialization2TitleErrorTxt('Specialization 2 Title is required');
            setIsSpecialization2TitleError(true);
            return;
        }
        else{
            setSpecialization2TitleErrorTxt('');
            setIsSpecialization2TitleError(false);
        }

        if(specialization2Description == ''){
            setSpecialization2DescriptionErrorTxt('Specialization 2 Description is required');
            setIsSpecialization2DescriptionError(true);
            return;
        }
        else{
            setSpecialization2DescriptionErrorTxt('');
            setIsSpecialization2DescriptionError(false);
        }

        if(specialization2Icon == ''){
            setSpecialization2IconErrorTxt('Specialization 2 icon is required');
            setIsSpecialization2IconError(true);
            return;
        }
        else{
            setSpecialization2IconErrorTxt('');
            setIsSpecialization2IconError(false);
        }

        if(specialization3Title == ''){
            setSpecialization3TitleErrorTxt('Specialization 3 Title is required');
            setIsSpecialization3TitleError(true);
            return;
        }
        else{
            setSpecialization3TitleErrorTxt('');
            setIsSpecialization3TitleError(false);
        }

        if(specialization3Description == ''){
            setSpecialization3DescriptionErrorTxt('Specialization 3 Description is required');
            setIsSpecialization3DescriptionError(true);
            return;
        }
        else{
            setSpecialization3DescriptionErrorTxt('');
            setIsSpecialization3DescriptionError(false);
        }

        if(specialization3Icon == ''){
            setSpecialization3IconErrorTxt('Specialization 3 icon is required');
            setIsSpecialization3IconError(true);
            return;
        }
        else{
            setSpecialization3IconErrorTxt('');
            setIsSpecialization3IconError(false);
        }

        const data = {
            specializations1_title: specialization1Title,
            specializations1_description: specialization1Description,
            specializations1_icon: specialization1Icon,
            specializations2_title: specialization2Title,
            specializations2_description: specialization2Description,
            specializations2_icon: specialization2Icon,
            specializations3_title: specialization3Title,
            specializations3_description: specialization3Description,
            specializations3_icon: specialization3Icon,
        }

        try {
            const updateHomeSpecializationsReq = await axios.patch(`${API_BASE_URL}home/specializations/edit`, data, config);
            notifySuccess(SPCIALIZATIONS_UPDATED);
            navigate('/dashboard/pages/home/specializations');
            LOGGER &&  console.log(updateHomeSpecializationsReq);
        }
        catch (error) {
            notifyError(REQUEST_ERROR);
            LOGGER &&  console.log(error);
        }
    }

    const resetForm = () => {
        
    }

    return (
        <Section heading="SEO" description={routeText} action={() => navigate("/dashboard/pages/home/specializations")} actionLable="back" showAction={true}>
            <PlainContainer styles={{width: '100%', display: 'flex', flexDirection: 'column', position: 'static'}}>
                <form onSubmit={(e) => submitEditThisForm(e)} noValidate>
                    <Form>
                        <SplitContainer type="fullwidth">
                            <TextInput lable="specialization 1 title" isMandatory={true} isError={isSpecialization1TitleError} error={specialization1TitleErrorTxt}>
                                <input type="text" className="formStepInputTxt capitalize" value={specialization1Title} placeholder="Please Enter The Specialization 1 Title" onChange={(e) => setSpecialization1Title(e.target.value)} />
                            </TextInput>
                            <TextInput lable="specialization 1 icon" isMandatory={true} isError={isSpecialization1IconError} error={specialization1IconErrorTxt}>
                                <input type="text" className="formStepInputTxt capitalize" value={specialization1Icon} placeholder="Please Enter The Specialization 1 Icon" onChange={(e) => setSpecialization1Icon(e.target.value)} />
                            </TextInput>
                        </SplitContainer>
                        <TextArea lable="specialization 1 description" rows={3} isMandatory={true} placeholder="Please Enter The Specialization 1 Description" value={specialization1Description} onchange={(data) => setSpecialization1Description(data)} isError={isSpecialization1DescriptionError} error={specialization1DescriptionErrorTxt} />
                        <SplitContainer type="fullwidth">
                            <TextInput lable="specialization 2 title" isMandatory={true} isError={isSpecialization2TitleError} error={specialization2TitleErrorTxt}>
                                <input type="text" className="formStepInputTxt capitalize" value={specialization2Title} placeholder="Please Enter The Specialization 2 Title" onChange={(e) => setSpecialization2Title(e.target.value)} />
                            </TextInput>
                            <TextInput lable="specialization 2 icon" isMandatory={true} isError={isSpecialization2IconError} error={specialization2IconErrorTxt}>
                                <input type="text" className="formStepInputTxt capitalize" value={specialization2Icon} placeholder="Please Enter The Specialization 2 Icon" onChange={(e) => setSpecialization2Icon(e.target.value)} />
                            </TextInput>
                        </SplitContainer>
                        <TextArea lable="specialization 2 description" rows={3} isMandatory={true} placeholder="Please Enter The Specialization 2 Description" value={specialization2Description} onchange={(data) => setSpecialization2Description(data)} isError={isSpecialization2DescriptionError} error={specialization2DescriptionErrorTxt} />
                        <SplitContainer type="fullwidth">
                            <TextInput lable="specialization 3 title" isMandatory={true} isError={isSpecialization3TitleError} error={specialization3TitleErrorTxt}>
                                <input type="text" className="formStepInputTxt capitalize" value={specialization3Title} placeholder="Please Enter The Specialization 3 Title" onChange={(e) => setSpecialization3Title(e.target.value)} />
                            </TextInput>
                            <TextInput lable="specialization 3 icon" isMandatory={true} isError={isSpecialization3IconError} error={specialization3IconErrorTxt}>
                                <input type="text" className="formStepInputTxt capitalize" value={specialization3Icon} placeholder="Please Enter The Specialization 3 Icon" onChange={(e) => setSpecialization3Icon(e.target.value)} />
                            </TextInput>
                        </SplitContainer>
                        <TextArea lable="specialization 3 description" rows={3} isMandatory={true} placeholder="Please Enter The Specialization 3 Description" value={specialization3Description} onchange={(data) => setSpecialization3Description(data)} isError={isSpecialization3DescriptionError} error={specialization3DescriptionErrorTxt} />
                        <FormActions isPrimary={true} isSecondary={true} primaryLable="submit" secondaryLable="reset" secondaryAction={() => resetForm()} />
                    </Form>
                </form>
            </PlainContainer>
        </Section>
    )
}

export default EditHomeSpecializationsPage