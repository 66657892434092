import React, { useState, useEffect, useRef, forwardRef} from 'react';
import { useLocation, useNavigate } from "react-router-dom";

import PlainContainer from '../../../../../../components/general/containers/PlainContainer';
import SplitContainer from '../../../../../../components/general/containers/SplitContainer';
import Section from '../../../../../../components/general/containers/Section';
import Form from '../../../../../../components/general/forms/core/Form';
import FormActions from '../../../../../../components/general/forms/core/FormActions';
import TextInput from '../../../../../../components/general/forms/TextInput';
import TextArea from '../../../../../../components/general/forms/TextArea';
import Button from '../../../../../../components/general/forms/Button';

import axios from 'axios';

import { useSelector } from 'react-redux';
import { API_BASE_URL, LOGGER } from '../../../../../../config';
import { notifyError, notifySuccess } from '../../../../../../utils/toastify';
import { GALLERY_UPDATED, REQUEST_ERROR } from '../../../../../../utils/messages';

const EditHomeGalleryPage = () => {
    let navigate = useNavigate();
    const location = useLocation();

    const [routeArray, setRouteArray] = useState(location.pathname.substring(1).split('/'));
    const [routeText, setRouteText] = useState('');

    useEffect(() => {
        let tempRoute = '';
        routeArray.map(route => {
            tempRoute = tempRoute.concat(`${route} / `);
            tempRoute = tempRoute.replace('-', ' ');
        });
        setRouteText(removeLastChars(tempRoute, 3));
    }, []);

    let removeLastChars = (str, chars) => {
        return str.substring(0, str.length - chars);
    }

    const admin = useSelector((state) => state.admin.value);

    const config = {
        headers: {
        'Authorization': admin.access_token
        }
    }

    const [sectionHeading, setSectionHeading] = useState('');
    const [headingBold, setHeadingBold] = useState('');
    const [headingRegular, setHeadingRegular] = useState('');

    const [isSectionHeadingError, setIsSectionHeadingError] = useState(false);
    const [isHeadingBoldError, setIsHeadingBoldError] = useState(false);
    const [isHeadingRegularError, setIsHeadingRegularError] = useState(false);

    const [sectionHeadingErrorTxt, setSectionHeadingErrorTxt] = useState("");
    const [headingBoldErrorTxt, setHeadingBoldErrorTxt] = useState("");
    const [headingRegularErrorTxt, setHeadingRegularErrorTxt] = useState("");

    const [resetAttachment, setResetAttachment] = useState(false);

    const getHomeGalleryHandler = async () => {
        try {
            const getHomeGalleryReq = await axios.get(`${API_BASE_URL}home/gallery`);
            console.log(getHomeGalleryReq);
            setSectionHeading(getHomeGalleryReq.data.section_heading);
            setHeadingBold(getHomeGalleryReq.data.heading_bold);
            setHeadingRegular(getHomeGalleryReq.data.heading_regular);
        }
        catch (error) {
            if(error.response.status === 404){
                console.log('not found');
            }
        }
    }

    useEffect(() => {
        getHomeGalleryHandler();
    }, []);

    const submitEditThisForm = async (e) => {
        e.preventDefault();

        if(sectionHeading == ''){
            setSectionHeadingErrorTxt('Section heading is required');
            setIsSectionHeadingError(true);
            return;
        }
        else{
            setSectionHeadingErrorTxt('');
            setIsSectionHeadingError(false);
        }

        if(headingBold == ''){
            setHeadingBoldErrorTxt('Heading bold is required');
            setIsHeadingBoldError(true);
            return;
        }
        else{
            setHeadingBoldErrorTxt('');
            setIsHeadingBoldError(false);
        }

        const data = {
            section_heading: sectionHeading,
            heading_bold: headingBold,
            heading_regular: headingRegular
        }

        try {
            const updateHomeGalleryReq = await axios.patch(`${API_BASE_URL}home/gallery/edit`, data, config);
            notifySuccess(GALLERY_UPDATED);
            navigate('/dashboard/pages/home/gallery');
            LOGGER &&  console.log(updateHomeGalleryReq);
        }
        catch (error) {
            notifyError(REQUEST_ERROR);
            LOGGER &&  console.log(error);
        }
    }

    const resetForm = () => {
        
    }

    return (
        <Section heading="gallery" description={routeText} action={() => navigate("/dashboard/pages/home/gallery")} actionLable="back" showAction={true}>
            <PlainContainer styles={{width: '100%', display: 'flex', flexDirection: 'column', position: 'static'}}>
                <form onSubmit={(e) => submitEditThisForm(e)} noValidate>
                    <Form>
                        <TextInput lable="section heading" isMandatory={true} isError={isSectionHeadingError} error={sectionHeadingErrorTxt}>
                            <input type="text" className="formStepInputTxt capitalize" value={sectionHeading} placeholder="Please Enter The Section Heading" onChange={(e) => setSectionHeading(e.target.value)} />
                        </TextInput>
                        <SplitContainer type={`fullwidth`}>
                            <TextInput lable="heading bold" isMandatory={true} isError={isHeadingBoldError} error={headingBoldErrorTxt}>
                                <input type="text" className="formStepInputTxt capitalize" value={headingBold} placeholder="Please Enter The Heading Bold" onChange={(e) => setHeadingBold(e.target.value)} />
                            </TextInput>
                            <TextInput lable="heading regular" isMandatory={false} isError={isHeadingRegularError} error={headingRegularErrorTxt}>
                                <input type="text" className="formStepInputTxt capitalize" value={headingRegular} placeholder="Please Enter The Heading Regular" onChange={(e) => setHeadingRegular(e.target.value)} />
                            </TextInput>
                        </SplitContainer>
                        <FormActions isPrimary={true} isSecondary={true} primaryLable="submit" secondaryLable="reset" secondaryAction={() => resetForm()} />
                    </Form>
                </form>
            </PlainContainer>
        </Section>
    )
}

export default EditHomeGalleryPage