import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import Heading from '../../../../../components/dashboard/common/table/Heading';
import HeadingItem from '../../../../../components/dashboard/common/table/HeadingItem';
import RowItem from '../../../../../components/dashboard/common/table/RowItem';
import Table from '../../../../../components/dashboard/common/table/Table';
import TableButton from '../../../../../components/dashboard/common/table/TableButton';
import TableRow from '../../../../../components/dashboard/common/table/TableRow';
import PlainContainer from '../../../../../components/general/containers/PlainContainer';
import Section from '../../../../../components/general/containers/Section';
import { API_BASE_URL, DEFAULT_PAGE_SIZE, FIRST_PAGE, LOGGER } from '../../../../../config';
import { REQUEST_ERROR, PHONE_NUMBER_DELETED } from '../../../../../utils/messages';
import { notifyError, notifySuccess } from '../../../../../utils/toastify';

const FooterPhoneNumbersPage = () => {
    const admin = useSelector((state) => state.admin.value);
    let navigate = useNavigate();
    const location = useLocation();
    const [routeArray, setRouteArray] = useState(location.pathname.substring(1).split('/'));
    const [routeText, setRouteText] = useState('');
    const [footerPhoneNumbers, setFooterPhoneNumbers] = useState([]);
  
    useEffect(() => {
        let tempRoute = '';
        routeArray.map(route => {
            tempRoute = tempRoute.concat(`${route} / `);
            tempRoute = tempRoute.replace('-', ' ');
        });
        setRouteText(removeLastChars(tempRoute, 3));
    }, []);
  
    let removeLastChars = (str, chars) => {
        return str.substring(0, str.length - chars);
    }
  
    const config = {
        headers: {
            'Authorization': admin.access_token
        }
    }

    const getFooterPhoneNoHandler = async () => {
        try {
            const getFooterPhoneNoReq = await axios.get(`${API_BASE_URL}footer/phone-numbers`);
            console.log(getFooterPhoneNoReq.data);
            setFooterPhoneNumbers(getFooterPhoneNoReq.data);
        }
        catch (error) {
            if(error.response.status === 404){
                console.log('not found');
            }
        }
    }

    useEffect(() => {
        getFooterPhoneNoHandler();
    }, []);

    const deleteItem = async (id) => {
        try {
            const deleteFooterPhoneNoReq = await axios.delete(`${API_BASE_URL}footer/phone-numbers/${id}`, config);
            notifySuccess(PHONE_NUMBER_DELETED);
            getFooterPhoneNoHandler();
            LOGGER &&  console.log(deleteFooterPhoneNoReq);
        }
        catch (error) {
            notifyError(REQUEST_ERROR);
            LOGGER &&  console.log(error);
        }
    }

    return (
        <Section heading="phone numbers" description={routeText} action={() => navigate("create")} actionLable="add new" showAction={true}>
            <Table>
                <Heading>
                    <HeadingItem size="1" isIcon={false} icon="" lable={`sl no`} />
                    <HeadingItem size="2" isIcon={false} icon="" lable={`lable`} />
                    <HeadingItem size="4" isIcon={false} icon="" lable={`phone no.`} />
                    <HeadingItem size="3" isIcon={false} icon="" lable={`action`} />
                </Heading>
                <PlainContainer styles={{width: '100%'}}>
                    {
                        footerPhoneNumbers.map(phone_number => {
                            return (
                                <TableRow key={phone_number.id}>
                                    <RowItem size="1" lable={phone_number.counter} isCenter={true} hasChildren={false}></RowItem>
                                    <RowItem size="2" lable={phone_number.lable} isCenter={true} hasChildren={false}></RowItem>
                                    <RowItem size="4" lable={phone_number.number} isCenter={false} hasChildren={false}></RowItem>
                                    <RowItem size="3" lable="3" isCenter={false} hasChildren={true}>
                                        <TableButton styles={{width: 'calc(50% - 5px)', marginRight: '10px'}} action={() => navigate(`edit/${phone_number.id}`)} type="primary">edit</TableButton>
                                        <TableButton styles={{width: 'calc(50% - 5px)'}} action={() => deleteItem(phone_number.id)} type="danger">delete</TableButton>
                                    </RowItem>
                                </TableRow>
                            )
                        })
                    }
                </PlainContainer>
            </Table>
        </Section>
    )
}

export default FooterPhoneNumbersPage