import React from 'react';
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';

import Stats from '../../../components/dashboard/common/Stats';
import Heading from '../../../components/dashboard/common/table/Heading';
import HeadingItem from '../../../components/dashboard/common/table/HeadingItem';
import RowItem from '../../../components/dashboard/common/table/RowItem';
import Table from '../../../components/dashboard/common/table/Table';
import TableRow from '../../../components/dashboard/common/table/TableRow';
import PlainContainer from '../../../components/general/containers/PlainContainer';
import Section from '../../../components/general/containers/Section';
import TableButton from '../../../components/dashboard/common/table/TableButton';
import RowIcon from '../../../components/dashboard/common/table/RowIcon';
import RecentStoreOrders from '../../../components/dashboard/home/RecentStoreOrders';
import NewCustomers from '../../../components/dashboard/home/NewCustomers';

const Home = () => {
  let navigate = useNavigate();

  const admin = useSelector((state) => state.admin.value);

  const config = {
    headers: {
      'Authorization': admin.access_token
    }
  }

  let today = new Date();
  let dd = String(today.getDate()).padStart(2, '0');
  let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  let yyyy = today.getFullYear();

  today = yyyy + '-' + mm + '-' + dd;

  return (
    <>
      <Stats auth={config} from={today} to={today} />

      <PlainContainer styles={{marginTop: '20px', width: '100%'}}>
        <PlainContainer styles={{width: '50%', paddingRight: '10px'}}>
          <Section heading="received orders" description="Lorem Ipsum Is Simply Dummy Text." action={() => navigate("/")} actionLable="view all" showAction={true}>
            <Table>
              <Heading>
                <HeadingItem size="1" isIcon={false} icon="" lable={`id`} />
                <HeadingItem size="2" isIcon={false} icon="" lable={`name`} />
                <HeadingItem size="2" isIcon={false} icon="" lable={`phone no.`} />
                <HeadingItem size="1" isIcon={true} icon="fa-solid fa-indian-rupee-sign" lable={`products`} />
                <HeadingItem size="1" isIcon={false} icon="" lable={``} />
              </Heading>
              <PlainContainer styles={{width: '100%'}}>
                <TableRow>
                  <RowItem size="1" lable="1" isCenter={true} hasChildren={false}></RowItem>
                  <RowItem size="2" lable="sharath" isCenter={false} hasChildren={false}></RowItem>
                  <RowItem size="2" lable="9844555504" isCenter={false} hasChildren={false}></RowItem>
                  <RowItem size="1" lable="186" isCenter={true} hasChildren={false}></RowItem>
                  <RowItem size="1" lable="2" isCenter={false} hasChildren={true}>
                    <RowIcon name="fa-solid fa-eye" action={() => navigate(`details/1`)} styles={{cursor: 'pointer'}} />
                  </RowItem>
                </TableRow>
                <TableRow>
                  <RowItem size="1" lable="1" isCenter={true} hasChildren={false}></RowItem>
                  <RowItem size="2" lable="sharath" isCenter={false} hasChildren={false}></RowItem>
                  <RowItem size="2" lable="9844555504" isCenter={false} hasChildren={false}></RowItem>
                  <RowItem size="1" lable="186" isCenter={true} hasChildren={false}></RowItem>
                  <RowItem size="1" lable="2" isCenter={false} hasChildren={true}>
                    <RowIcon name="fa-solid fa-eye" action={() => navigate(`details/1`)} styles={{cursor: 'pointer'}} />
                  </RowItem>
                </TableRow>
                <TableRow>
                  <RowItem size="1" lable="1" isCenter={true} hasChildren={false}></RowItem>
                  <RowItem size="2" lable="sharath" isCenter={false} hasChildren={false}></RowItem>
                  <RowItem size="2" lable="9844555504" isCenter={false} hasChildren={false}></RowItem>
                  <RowItem size="1" lable="186" isCenter={true} hasChildren={false}></RowItem>
                  <RowItem size="1" lable="2" isCenter={false} hasChildren={true}>
                    <RowIcon name="fa-solid fa-eye" action={() => navigate(`details/1`)} styles={{cursor: 'pointer'}} />
                  </RowItem>
                </TableRow>
                <TableRow>
                  <RowItem size="1" lable="1" isCenter={true} hasChildren={false}></RowItem>
                  <RowItem size="2" lable="sharath" isCenter={false} hasChildren={false}></RowItem>
                  <RowItem size="2" lable="9844555504" isCenter={false} hasChildren={false}></RowItem>
                  <RowItem size="1" lable="186" isCenter={true} hasChildren={false}></RowItem>
                  <RowItem size="1" lable="2" isCenter={false} hasChildren={true}>
                    <RowIcon name="fa-solid fa-eye" action={() => navigate(`details/1`)} styles={{cursor: 'pointer'}} />
                  </RowItem>
                </TableRow>
              </PlainContainer>
            </Table>
          </Section>
        </PlainContainer>
        
        <PlainContainer styles={{width: '50%', paddingLeft: '10px'}}>
          <Section heading="accepted orders" description="Lorem Ipsum Is Simply Dummy Text." action={() => navigate("/")} actionLable="view all" showAction={true}>
          <Table>
              <Heading>
                <HeadingItem size="1" isIcon={false} icon="" lable={`id`} />
                <HeadingItem size="2" isIcon={false} icon="" lable={`name`} />
                <HeadingItem size="2" isIcon={false} icon="" lable={`phone no.`} />
                <HeadingItem size="1" isIcon={true} icon="fa-solid fa-indian-rupee-sign" lable={`products`} />
                <HeadingItem size="1" isIcon={false} icon="" lable={``} />
              </Heading>
              <PlainContainer styles={{width: '100%'}}>
                <TableRow>
                  <RowItem size="1" lable="1" isCenter={true} hasChildren={false}></RowItem>
                  <RowItem size="2" lable="sharath" isCenter={false} hasChildren={false}></RowItem>
                  <RowItem size="2" lable="9844555504" isCenter={false} hasChildren={false}></RowItem>
                  <RowItem size="1" lable="186" isCenter={true} hasChildren={false}></RowItem>
                  <RowItem size="1" lable="2" isCenter={false} hasChildren={true}>
                    <RowIcon name="fa-solid fa-eye" action={() => navigate(`details/1`)} styles={{cursor: 'pointer'}} />
                  </RowItem>
                </TableRow>
                <TableRow>
                  <RowItem size="1" lable="1" isCenter={true} hasChildren={false}></RowItem>
                  <RowItem size="2" lable="sharath" isCenter={false} hasChildren={false}></RowItem>
                  <RowItem size="2" lable="9844555504" isCenter={false} hasChildren={false}></RowItem>
                  <RowItem size="1" lable="186" isCenter={true} hasChildren={false}></RowItem>
                  <RowItem size="1" lable="2" isCenter={false} hasChildren={true}>
                    <RowIcon name="fa-solid fa-eye" action={() => navigate(`details/1`)} styles={{cursor: 'pointer'}} />
                  </RowItem>
                </TableRow>
                <TableRow>
                  <RowItem size="1" lable="1" isCenter={true} hasChildren={false}></RowItem>
                  <RowItem size="2" lable="sharath" isCenter={false} hasChildren={false}></RowItem>
                  <RowItem size="2" lable="9844555504" isCenter={false} hasChildren={false}></RowItem>
                  <RowItem size="1" lable="186" isCenter={true} hasChildren={false}></RowItem>
                  <RowItem size="1" lable="2" isCenter={false} hasChildren={true}>
                    <RowIcon name="fa-solid fa-eye" action={() => navigate(`details/1`)} styles={{cursor: 'pointer'}} />
                  </RowItem>
                </TableRow>
                <TableRow>
                  <RowItem size="1" lable="1" isCenter={true} hasChildren={false}></RowItem>
                  <RowItem size="2" lable="sharath" isCenter={false} hasChildren={false}></RowItem>
                  <RowItem size="2" lable="9844555504" isCenter={false} hasChildren={false}></RowItem>
                  <RowItem size="1" lable="186" isCenter={true} hasChildren={false}></RowItem>
                  <RowItem size="1" lable="2" isCenter={false} hasChildren={true}>
                    <RowIcon name="fa-solid fa-eye" action={() => navigate(`details/1`)} styles={{cursor: 'pointer'}} />
                  </RowItem>
                </TableRow>
              </PlainContainer>
            </Table>
          </Section>
        </PlainContainer>
      </PlainContainer>
    </>  
  );
};

export default Home;