import React from 'react'

const DetailSectionRow = ({lable, value, isImage, isIcon, hasChildren, children}) => {
    return (
        <div className="detailSectionRow">
            <div className="detailSectionRowLable">
                <p className="detailSectionRowLableTxt">{lable}</p>
            </div>
            <div className="detailSectionRowSeperator">
                <p className="detailSectionRowSeperatorTxt">:</p>
            </div>
            <div className="detailSectionRowContent">
                {
                    isImage === false && isIcon === false && hasChildren === false
                    ?
                        <p className="detailSectionRowContentTxt">{value}</p>
                    :
                    isIcon === true
                    ?
                        <i className={value}></i>
                    :
                    isImage === true
                    ?
                        <img src={value} className='detailSectionRowContentImage' />
                    :
                    hasChildren === true
                    ?
                        <>{children}</>
                    :
                        <></>
                }
            </div>
        </div>
    )
}

export default DetailSectionRow