import React, { useState, useEffect, useRef, forwardRef} from 'react';
import { useLocation, useNavigate } from "react-router-dom";

import PlainContainer from '../../../../../../components/general/containers/PlainContainer';
import SplitContainer from '../../../../../../components/general/containers/SplitContainer';
import Section from '../../../../../../components/general/containers/Section';
import Form from '../../../../../../components/general/forms/core/Form';
import FormActions from '../../../../../../components/general/forms/core/FormActions';
import TextInput from '../../../../../../components/general/forms/TextInput';
import TextArea from '../../../../../../components/general/forms/TextArea';
import Button from '../../../../../../components/general/forms/Button';

import axios from 'axios';

import { useSelector } from 'react-redux';
import { API_BASE_URL, LOGGER } from '../../../../../../config';
import { notifyError, notifySuccess } from '../../../../../../utils/toastify';
import { REQUEST_ERROR, HEADINGS_UPDATED } from '../../../../../../utils/messages';

const EditBlogHeadingsPage = () => {
    let navigate = useNavigate();
    const location = useLocation();

    const [routeArray, setRouteArray] = useState(location.pathname.substring(1).split('/'));
    const [routeText, setRouteText] = useState('');

    useEffect(() => {
        let tempRoute = '';
        routeArray.map(route => {
            tempRoute = tempRoute.concat(`${route} / `);
            tempRoute = tempRoute.replace('-', ' ');
        });
        setRouteText(removeLastChars(tempRoute, 3));
    }, []);

    let removeLastChars = (str, chars) => {
        return str.substring(0, str.length - chars);
    }

    const admin = useSelector((state) => state.admin.value);

    const config = {
        headers: {
        'Authorization': admin.access_token
        }
    }

    const [headingBold, setHeadingBold] = useState('');
    const [headingRegular, setHeadingRegular] = useState('');
    const [description, setDescription] = useState('');

    const [isHeadingBoldError, setIsHeadingBoldError] = useState(false);
    const [isHeadingRegularError, setIsHeadingRegularError] = useState(false);
    const [isDescriptionError, setIsDescriptionError] = useState(false);

    const [headingBoldErrorTxt, setHeadingBoldErrorTxt] = useState("");
    const [headingRegularErrorTxt, setHeadingRegularErrorTxt] = useState("");
    const [descriptionErrorTxt, setDescriptionErrorTxt] = useState("");

    const [resetAttachment, setResetAttachment] = useState(false);

    const getBlogHeadingsHandler = async () => {
        try {
            const getBlogHeadingsReq = await axios.get(`${API_BASE_URL}blog/heading/heading`);
            console.log(getBlogHeadingsReq);
            setHeadingBold(getBlogHeadingsReq.data.heading_bold);
            setHeadingRegular(getBlogHeadingsReq.data.heading_regular);
            setDescription(getBlogHeadingsReq.data.description);
        }
        catch (error) {
            if(error.response.status === 404){
                console.log('not found');
            }
        }
    }

    useEffect(() => {
        getBlogHeadingsHandler();
    }, []);

    const submitEditThisForm = async (e) => {
        e.preventDefault();

        if(headingBold == ''){
            setHeadingBoldErrorTxt('Heading bold is required');
            setIsHeadingBoldError(true);
            return;
        }
        else{
            setHeadingBoldErrorTxt('');
            setIsHeadingBoldError(false);
        }

        if(headingRegular == ''){
            setHeadingRegularErrorTxt('Heading regular is required');
            setIsHeadingRegularError(true);
            return;
        }
        else{
            setHeadingRegularErrorTxt('');
            setIsHeadingRegularError(false);
        }

        if(description == ''){
            setDescriptionErrorTxt('description is required');
            setIsDescriptionError(true);
            return;
        }
        else{
            setDescriptionErrorTxt('');
            setIsDescriptionError(false);
        }

        const data = {
            heading_bold: headingBold,
            heading_regular: headingRegular,
            description: description
        }

        try {
            const updateBlogHeadingsReq = await axios.patch(`${API_BASE_URL}blog/heading/heading/edit`, data, config);
            notifySuccess(HEADINGS_UPDATED);
            navigate('/dashboard/pages/blogs/headings');
            LOGGER &&  console.log(updateBlogHeadingsReq);
        }
        catch (error) {
            notifyError(REQUEST_ERROR);
            LOGGER &&  console.log(error);
        }
    }

    const resetForm = () => {
        
    }

    return (
        <Section heading="Headings" description={routeText} action={() => navigate("/dashboard/pages/blogs/headings")} actionLable="back" showAction={true}>
            <PlainContainer styles={{width: '100%', display: 'flex', flexDirection: 'column', position: 'static'}}>
                <form onSubmit={(e) => submitEditThisForm(e)} noValidate>
                    <Form>
                        <SplitContainer type={`fullwidth`}>
                            <TextInput lable="heading bold" isMandatory={true} isError={isHeadingBoldError} error={headingBoldErrorTxt}>
                                <input type="text" className="formStepInputTxt capitalize" value={headingBold} placeholder="Please Enter The Heading Bold" onChange={(e) => setHeadingBold(e.target.value)} />
                            </TextInput>
                            <TextInput lable="heading regular" isMandatory={true} isError={isHeadingRegularError} error={headingRegularErrorTxt}>
                                <input type="text" className="formStepInputTxt capitalize" value={headingRegular} placeholder="Please Enter The Heading Regular" onChange={(e) => setHeadingRegular(e.target.value)} />
                            </TextInput>
                        </SplitContainer>
                        <TextArea lable="description" rows={3} isMandatory={true} placeholder="Please Enter The Description" value={description} onchange={(data) => setDescription(data)} isError={isDescriptionError} error={descriptionErrorTxt} />
                        <FormActions isPrimary={true} isSecondary={true} primaryLable="submit" secondaryLable="reset" secondaryAction={() => resetForm()} />
                    </Form>
                </form>
            </PlainContainer>
        </Section>
    )
}

export default EditBlogHeadingsPage