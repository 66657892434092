import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';

import verifyToken from './verify-jwt';
import { ROLES, LOGGER } from './config';

import RootOutlet from './pages/dashboard/rootOutlet';
import DefaultOutlet from './components/general/DefaultOutlet';

import Login from './pages/login';
import Home from './pages/dashboard/home';
import HeaderPage from './pages/dashboard/general/header/HeaderPage';
import FooterPage from './pages/dashboard/general/footer/FooterPage';
import HeaderActionBannerPage from './pages/dashboard/general/header/action-banner/ActionBanner';
import EditHeaderActionBannerPage from './pages/dashboard/general/header/action-banner/edit/EditActionBanner';
import HeaderContactDetailsPage from './pages/dashboard/general/header/contact-details/ContactDetails';
import EditHeaderContactDetailsPage from './pages/dashboard/general/header/contact-details/edit/EditContactDetails';
import HeaderSocialMediaLinksPage from './pages/dashboard/general/header/social-media-links/SocialMediaLinks';
import EditHeaderSocialMediaLinksPage from './pages/dashboard/general/header/social-media-links/edit/EditSocialMediaLinks';
import FooterAddressPage from './pages/dashboard/general/footer/address/Address';
import EditFooterAddressPage from './pages/dashboard/general/footer/address/edit/EditAddress';
import FooterPhoneNumbersPage from './pages/dashboard/general/footer/phone-numbers/PhoneNumbers';
import EditFooterPhoneNoPage from './pages/dashboard/general/footer/phone-numbers/edit/EditPhoneNumber';
import AddFooterPhoneNoPage from './pages/dashboard/general/footer/phone-numbers/add/AddPhoneNumber';
import FooterEmailIdsPage from './pages/dashboard/general/footer/email-ids/EmailIds';
import AddFooterEmailIdPage from './pages/dashboard/general/footer/email-ids/add/AddEmailId';
import EditFooterEmailIdPage from './pages/dashboard/general/footer/email-ids/edit/EditEmailId';
import HomePage from './pages/dashboard/pages/home/HomePage';
import AboutUsPage from './pages/dashboard/pages/about-us/AboutUsPage';
import ProductsPage from './pages/dashboard/pages/products/ProductsPage';
import GalleryPage from './pages/dashboard/pages/gallery/GalleryPage';
import ClientsPage from './pages/dashboard/pages/clients/ClientsPage';
import ContactUsPage from './pages/dashboard/pages/contact-us/ContactUsPage';
import CareersPage from './pages/dashboard/pages/careers/CareersPage';
import DealershipFormPage from './pages/dashboard/pages/dealership-form/DealershipForm';
import BlogPage from './pages/dashboard/pages/blog/BlogPage';
import HomeSeoPage from './pages/dashboard/pages/home/seo/HomeSeoPage';
import EditHomeSeoPage from './pages/dashboard/pages/home/seo/edit/EditHomeSeoPage';
import HomeSpecializationsPage from './pages/dashboard/pages/home/specializations/HomeSpecializationsPage';
import EditHomeSpecializationsPage from './pages/dashboard/pages/home/specializations/edit/EditHomeSpecializationsPage';
import HomeProductsPage from './pages/dashboard/pages/home/products/HomeProducts';
import EditHomeProductsPage from './pages/dashboard/pages/home/products/edit/EditHomeProducts';
import HomeGalleryPage from './pages/dashboard/pages/home/gallery/HomeGalleryPage';
import EditHomeGalleryPage from './pages/dashboard/pages/home/gallery/edit/EditHomeGalleryPage';
import HomeAdBannerPage from './pages/dashboard/pages/home/ad-banner/HomeAdBannerPage';
import EditHomeAdBannerPage from './pages/dashboard/pages/home/ad-banner/edit/EditHomeAdBannerPage';
import HomeMakeAppointmentPage from './pages/dashboard/pages/home/make-appointment/HomeMakeAppointmentPage';
import EditHomeMakeAppointmentPage from './pages/dashboard/pages/home/make-appointment/edit/EditHomeMakeAppointmentPage';
import HomeClientsPage from './pages/dashboard/pages/home/clients/HomeClientsPage';
import EditHomeClientsPage from './pages/dashboard/pages/home/clients/edit/EditHomeClientsPage';
import HomeVideoPage from './pages/dashboard/pages/home/video/HomeVideoPage';
import EditHomeVideoPage from './pages/dashboard/pages/home/video/edit/EditHomeVideoPage';
import HomePartnerPortalsPage from './pages/dashboard/pages/home/partner-portals/HomePartnerPortalsPage';
import HomePartnerPortalsHeadingsPage from './pages/dashboard/pages/home/partner-portals/headings/HomePartnerPortalHeadingsPage';
import EditHomePartnerPortalsHeadingsPage from './pages/dashboard/pages/home/partner-portals/headings/edit/EditHomePartnerPortalHeadingsPage';
import HomePartnerPortalsListPage from './pages/dashboard/pages/home/partner-portals/portals/HomePartnerPortalListPage';
import AboutUsSeoPage from './pages/dashboard/pages/about-us/seo/AboutUsSeoPage';
import EditAboutUsSeoPage from './pages/dashboard/pages/about-us/seo/edit/EditAboutUsSeoPage';
import AboutUsHeadingsPage from './pages/dashboard/pages/about-us/headings/AboutUsHeadingsPage';
import EditAboutUsHeadingsPage from './pages/dashboard/pages/about-us/headings/edit/EditAboutUsHeadingsPage';
import AboutUsHighlightsPage from './pages/dashboard/pages/about-us/highlights/AboutUsHighlightsPage';
import EditAboutUsHighlightsPage from './pages/dashboard/pages/about-us/highlights/edit/EditAboutUsHighlightsPage';
import HeaderLogoPage from './pages/dashboard/general/header/logo/HeaderLogoPage';
import EditHeaderLogoPage from './pages/dashboard/general/header/logo/edit/EditHeaderLogoPage';
import FooterLogoPage from './pages/dashboard/general/footer/logo/FooterLogoPage';
import EditFooterLogoPage from './pages/dashboard/general/footer/logo/edit/EditFooterLogoPage';
import HomeBannerPage from './pages/dashboard/pages/home/banner/HomeBannerPage';
import EditHomeBannerPage from './pages/dashboard/pages/home/banner/edit/EditHomeBannerPage';
import HomeAboutUsPage from './pages/dashboard/pages/home/about-us/HomeAboutUsPage';
import EditHomeAboutUsPage from './pages/dashboard/pages/home/about-us/edit/EditHomeAboutUsPage';
import AboutUsContentsPage from './pages/dashboard/pages/about-us/contents/AboutUsContentsPage';
import EditAboutUsContentsPage from './pages/dashboard/pages/about-us/contents/edit/EditAboutUsContentsPage';
import DealershipFormSeoPage from './pages/dashboard/pages/dealership-form/seo/DealershipFormSeoPage';
import EditDealershipFormSeoPage from './pages/dashboard/pages/dealership-form/seo/edit/EditDealershipFormSeoPage';
import DealershipFormHeadingsPage from './pages/dashboard/pages/dealership-form/headings/DealershipFormHeadingsPage';
import EditDealershipFormHeadingsPage from './pages/dashboard/pages/dealership-form/headings/edit/EditDealershipFormHeadings';
import CareerSeoPage from './pages/dashboard/pages/careers/seo/CareerSeoPage';
import EditCareerSeoPage from './pages/dashboard/pages/careers/seo/edit/EditCareerSeoPage';
import CareerHeadingsPage from './pages/dashboard/pages/careers/headings/CareerHeadingsPage';
import EditCareerHeadingsPage from './pages/dashboard/pages/careers/headings/edit/EditCareerHeadingsPage';
import CareerOpeningsPage from './pages/dashboard/pages/careers/openings/CareerOpeningsPage';
import AddCareerOpeningPage from './pages/dashboard/pages/careers/openings/add/AddCareerOpeningPage';
import EditCareerOpeningPage from './pages/dashboard/pages/careers/openings/edit/EditCareerOpening';
import ContactUsSeoPage from './pages/dashboard/pages/contact-us/seo/ContactUsSeoPage';
import EditContactUsSeoPage from './pages/dashboard/pages/contact-us/seo/edit/EditContactUsSeoPage';
import ContactUsEnquiryFormPage from './pages/dashboard/pages/contact-us/enquiry-form/ContactEnquiryFormPage';
import EditContactUsEnquiryFormPage from './pages/dashboard/pages/contact-us/enquiry-form/edit/EditContactEnquiryFormPage';
import ContactUsAddressPage from './pages/dashboard/pages/contact-us/address/ContactAddressPage';
import AddContactAddressPage from './pages/dashboard/pages/contact-us/address/add/AddContactAddressPage';
import EditContactAddressPage from './pages/dashboard/pages/contact-us/address/edit/EditContactAddressPage';
import ContactUsPhoneNumbersPage from './pages/dashboard/pages/contact-us/phone-number/ContactPhoneNumberPage';
import AddContactPhoneNumberPage from './pages/dashboard/pages/contact-us/phone-number/add/AddContactPhoneNumberPage';
import EditContactPhoneNumberPage from './pages/dashboard/pages/contact-us/phone-number/edit/EditContactPhoneNumberPage';
import ContactEmailIdsPage from './pages/dashboard/pages/contact-us/email-ids/ContactEmailIdsPage';
import AddContactEmailIdPage from './pages/dashboard/pages/contact-us/email-ids/add/AddContactEmailIdPage';
import EditContactEmailIdPage from './pages/dashboard/pages/contact-us/email-ids/edit/EditContactEmailIdPage';
import ContactUsBrochurePage from './pages/dashboard/pages/contact-us/brochure/ContactBrochurePage';
import EditContactUsBrochurePage from './pages/dashboard/pages/contact-us/brochure/edit/EditContactBrochurePage';
import ClientSeoPage from './pages/dashboard/pages/clients/seo/ClientSeoPage';
import EditClientSeoPage from './pages/dashboard/pages/clients/seo/edit/EditClientSeoPage';
import ClientHeadingsPage from './pages/dashboard/pages/clients/headings/ClientsHeadingsPage';
import EditClientHeadingsPage from './pages/dashboard/pages/clients/headings/edit/EditClientsHeadingsPage';
import ClientsListPage from './pages/dashboard/pages/clients/client/ClientsListPage';
import AddClientPage from './pages/dashboard/pages/clients/client/add/AddClientPage';
import EditClientPage from './pages/dashboard/pages/clients/client/edit/EditClientPage';
import ProductsSeoPage from './pages/dashboard/pages/products/seo/ProductsSeoPage';
import EditProductsSeoPage from './pages/dashboard/pages/products/seo/edit/EditProductsSeoPage';
import ProductsHeadingsPage from './pages/dashboard/pages/products/headings/ProductsHeadingsPage';
import EditProductsHeadingsPage from './pages/dashboard/pages/products/headings/edit/EditProductsHeadingsPage';
import ProductsCategoriesPage from './pages/dashboard/pages/products/categories/ProductCategoriesPage';
import AddProductCategoryPage from './pages/dashboard/pages/products/categories/add/AddProductCategoryPage';
import ProductsCategoryDetailsPage from './pages/dashboard/pages/products/categories/details/ProductCategoryDetailsPage';
import AddProductCategoryHighlightPage from './pages/dashboard/pages/products/categories/highlights/add/AddProductCategoryHighlightPage';
import EditProductCategoryHighlightPage from './pages/dashboard/pages/products/categories/highlights/edit/EditProductCategoryHighlightPage';
import AddProductCategoryAccessoryPage from './pages/dashboard/pages/products/categories/accessories/add/AddProductCategoryAccessoryPage';
import EditProductCategoryAccessoryPage from './pages/dashboard/pages/products/categories/accessories/edit/EditProductCategoryAccessoryPage';
import AddProductCategoryPdfPage from './pages/dashboard/pages/products/categories/pdfs/add/AddProductCategoryPdfPage';
import EditProductCategoryPdfPage from './pages/dashboard/pages/products/categories/pdfs/edit/EditProductCategoryPdfPage';
import ProductsListPage from './pages/dashboard/pages/products/products/ProductPage';
import ProductDetailsPage from './pages/dashboard/pages/products/products/details/ProductDetailsPage';
import AddProductPage from './pages/dashboard/pages/products/products/add/AddProductPage';
import AddProductHighlightPage from './pages/dashboard/pages/products/products/highlights/add/AddProductHighlightPage';
import EditProductHighlightPage from './pages/dashboard/pages/products/products/highlights/edit/EditProductHighlightPage';
import AddProductPdfPage from './pages/dashboard/pages/products/products/pdfs/add/AddProductPdfPage';
import EditProductPdfPage from './pages/dashboard/pages/products/products/pdfs/edit/EditProductPdfPage';
import AddProductAccessoryPage from './pages/dashboard/pages/products/products/accessories/add/AddProductAccessoryPage';
import EditProductAccessoryPage from './pages/dashboard/pages/products/products/accessories/edit/EditProductAccessoryPage';
import EditProductCategoryPage from './pages/dashboard/pages/products/categories/edit/EditProductCategoryPage';
import EditProductPage from './pages/dashboard/pages/products/products/edit/EditProductPage';
import HomeTestimonialsPage from './pages/dashboard/pages/home/testimonials/HomeTestimonialsPage';
import AddHomeTestimonyPage from './pages/dashboard/pages/home/testimonials/add/AddHomeTestimonyPage';
import EditHomeTestimonyPage from './pages/dashboard/pages/home/testimonials/edit/EditHomeTestimonyPage';
import AddHomePartnerPortalPage from './pages/dashboard/pages/home/partner-portals/portals/add/AddHomePartnerPortalPage';
import EditHomePartnerPortalPage from './pages/dashboard/pages/home/partner-portals/portals/edit/EditHomePartnerPortalListPage';
import GallerySeoPage from './pages/dashboard/pages/gallery/seo/GallerySeoPage';
import EditGallerySeoPage from './pages/dashboard/pages/gallery/seo/edit/EditGallerySeoPage';
import GalleryHeadingsPage from './pages/dashboard/pages/gallery/headings/GalleryHeadingsPage';
import EditGalleryHeadingsPage from './pages/dashboard/pages/gallery/headings/edit/EditGalleryHeadingsPage';
import GalleryImagesPage from './pages/dashboard/pages/gallery/photos/GalleryPhotosPage';
import AddGalleryImagesPage from './pages/dashboard/pages/gallery/photos/add/AddGalleryPhotosPage';
import BlogSeoPage from './pages/dashboard/pages/blog/seo/BlogSeoPage';
import EditBlogSeoPage from './pages/dashboard/pages/blog/seo/edit/EditBlogSeoPage';
import BlogHeadingsPage from './pages/dashboard/pages/blog/headings/BlogHeadingsPage';
import EditBlogHeadingsPage from './pages/dashboard/pages/blog/headings/edit/EditBlogHeadingsPage';
import BlogCategoriesPage from './pages/dashboard/pages/blog/categories/BlogCategoriesPage';
import AddBlogCategoryPage from './pages/dashboard/pages/blog/categories/add/AddBlogCategoryPage';
import EditBlogCategoryPage from './pages/dashboard/pages/blog/categories/edit/EditBlogCategoryPage';
import BlogArticlesPage from './pages/dashboard/pages/blog/articles/BlogArticlesPage';
import AddBlogArticlePage from './pages/dashboard/pages/blog/articles/add/AddBlogArticlePage';
import EditBlogArticlePage from './pages/dashboard/pages/blog/articles/edit/EditBlogArticlePage';


/* update by hemendra */
import HomeAddSliderMain from "./pages/dashboard/pages/home/main-slider-ads/add/HomeAddSliderMain"
import SliderContaint from './pages/dashboard/pages/home/main-slider-ads/HomeSliderContaint';
import HomeSliderContaint from './pages/dashboard/pages/home/main-slider-ads/HomeSliderContaint';
import EditMainslideAdd from './pages/dashboard/pages/home/main-slider-ads/edit/EditMainSlideAdd';
import HomePopupAd from './pages/dashboard/pages/home/popup-ad/HomePopupAd';
import EditPopupAd from './pages/dashboard/pages/home/popup-ad/edit/EditPopupAd';


/* =============*/
const AppRouter = () => {

  const [auth, setAuth] = useState(null);

  const admin = useSelector((state) => state.admin.value);

  const isAdmin = ROLES.ADMIN === admin.role;
  const isSuperAdmin = ROLES.SUPER_ADMIN === admin.role;
  const isStoreAdmin = ROLES.STORE_ADMIN === admin.role;

  useEffect(() => {
    async function checkIsLoggedIn() {
      if (admin.is_logged_in === true) {
        const checkToken = await verifyToken(admin.access_token);
        setAuth(checkToken.status);
      }
      else {
        setAuth(false);
      }
    }
    checkIsLoggedIn();
  }, [admin]);

  return (
    <>
      {
        auth != null
          ?
          <Routes>
            {!auth && (
              <Route path="/" element={<Login />} />
            )}
            {auth && (
              <>
                <Route path="/" element={<Navigate to={"/dashboard"} />} />
                <Route path="/dashboard" element={<RootOutlet />}>
                  <Route index element={<Home />} />
                  <Route path="general" element={isSuperAdmin || isAdmin ? <DefaultOutlet /> : <Navigate to={"/dashboard"} />} >
                    <Route path="header" element={<DefaultOutlet />}>
                      <Route index element={<HeaderPage />} />
                      <Route path="action-banner" element={<DefaultOutlet />}>
                        <Route index element={<HeaderActionBannerPage />} />
                        <Route path='edit' element={<EditHeaderActionBannerPage />} />
                      </Route>
                      <Route path="logo" element={<DefaultOutlet />}>
                        <Route index element={<HeaderLogoPage />} />
                        <Route path='edit' element={<EditHeaderLogoPage />} />
                      </Route>
                      <Route path="contact-details" element={<DefaultOutlet />}>
                        <Route index element={<HeaderContactDetailsPage />} />
                        <Route path='edit' element={<EditHeaderContactDetailsPage />} />
                      </Route>
                      <Route path="social-media-links" element={<DefaultOutlet />}>
                        <Route index element={<HeaderSocialMediaLinksPage />} />
                        <Route path='edit' element={<EditHeaderSocialMediaLinksPage />} />
                      </Route>
                    </Route>
                    <Route path="footer" element={<DefaultOutlet />}>
                      <Route index element={<FooterPage />} />
                      <Route path="logo" element={<DefaultOutlet />}>
                        <Route index element={<FooterLogoPage />} />
                        <Route path='edit' element={<EditFooterLogoPage />} />
                      </Route>
                      <Route path="phone-numbers" element={<DefaultOutlet />}>
                        <Route index element={<FooterPhoneNumbersPage />} />
                        <Route path='create' element={<AddFooterPhoneNoPage />} />
                        <Route path='edit/:phone_id' element={<EditFooterPhoneNoPage />} />
                      </Route>
                      <Route path="email-ids" element={<DefaultOutlet />}>
                        <Route index element={<FooterEmailIdsPage />} />
                        <Route path='create' element={<AddFooterEmailIdPage />} />
                        <Route path='edit/:email_id' element={<EditFooterEmailIdPage />} />
                      </Route>
                      <Route path="address" element={<DefaultOutlet />}>
                        <Route index element={<FooterAddressPage />} />
                        <Route path='edit' element={<EditFooterAddressPage />} />
                      </Route>
                    </Route>
                  </Route>
                  <Route path="pages" element={isSuperAdmin || isAdmin ? <DefaultOutlet /> : <Navigate to={"/dashboard"} />} >
                    <Route path="home" element={<DefaultOutlet />}>
                      <Route index element={<HomePage />} />
                      <Route path="seo" element={<DefaultOutlet />}>
                        <Route index element={<HomeSeoPage />} />
                        <Route path='edit' element={<EditHomeSeoPage />} />
                      </Route>
                      <Route path="banner" element={<DefaultOutlet />}>
                        <Route index element={<HomeBannerPage />} />
                        <Route path='edit' element={<EditHomeBannerPage />} />
                      </Route>
                      <Route path="about-us" element={<DefaultOutlet />}>
                        <Route index element={<HomeAboutUsPage />} />
                        <Route path='edit' element={<EditHomeAboutUsPage />} />
                      </Route>
                      <Route path="specializations" element={<DefaultOutlet />}>
                        <Route index element={<HomeSpecializationsPage />} />
                        <Route path='edit' element={<EditHomeSpecializationsPage />} />
                      </Route>
                      <Route path="products" element={<DefaultOutlet />}>
                        <Route index element={<HomeProductsPage />} />
                        <Route path='edit' element={<EditHomeProductsPage />} />
                      </Route>
                      <Route path="gallery" element={<DefaultOutlet />}>
                        <Route index element={<HomeGalleryPage />} />
                        <Route path='edit' element={<EditHomeGalleryPage />} />
                      </Route>
                      <Route path="ad-banner" element={<DefaultOutlet />}>
                        <Route index element={<HomeAdBannerPage />} />
                        <Route path='edit' element={<EditHomeAdBannerPage />} />
                      </Route>
                      <Route path="make-appointment" element={<DefaultOutlet />}>
                        <Route index element={<HomeMakeAppointmentPage />} />
                        <Route path='edit' element={<EditHomeMakeAppointmentPage />} />
                      </Route>
                      <Route path="clients" element={<DefaultOutlet />}>
                        <Route index element={<HomeClientsPage />} />
                        <Route path='edit' element={<EditHomeClientsPage />} />
                      </Route>
                      <Route path="partner-portals" element={<DefaultOutlet />}>
                        <Route index element={<HomePartnerPortalsPage />} />
                        <Route path='headings' element={<DefaultOutlet />}>
                          <Route index element={<HomePartnerPortalsHeadingsPage />} />
                          <Route path='edit' element={<EditHomePartnerPortalsHeadingsPage />} />
                        </Route>
                        <Route path='portals' element={<DefaultOutlet />}>
                          <Route index element={<HomePartnerPortalsListPage />} />
                          <Route path='create' element={<AddHomePartnerPortalPage />} />
                          <Route path='edit/:portal_id' element={<EditHomePartnerPortalPage />} />
                        </Route>
                      </Route>
                      <Route path="video" element={<DefaultOutlet />}>
                        <Route index element={<HomeVideoPage />} />
                        <Route path='edit' element={<EditHomeVideoPage />} />
                      </Route>
                      <Route path="testimonials" element={<DefaultOutlet />}>
                        <Route index element={<HomeTestimonialsPage />} />
                        <Route path='create' element={<AddHomeTestimonyPage />} />
                        <Route path='edit/:testimony_id' element={<EditHomeTestimonyPage />} />
                      </Route>

                      <Route path='main-ads-slider' element={<DefaultOutlet />}>
                        <Route index element={<HomeSliderContaint />} />
                        <Route path='create' element={<HomeAddSliderMain />} />
                        <Route path='edit/:adslid_id' element={<EditMainslideAdd />} />
                      </Route>

                      <Route path='popup-ad' element={<DefaultOutlet />}>
                        <Route index element={<HomePopupAd />} />
                        <Route path='edit' element={<EditPopupAd />} />
                      </Route>
                    </Route>
                    <Route path="about-us" element={<DefaultOutlet />}>
                      <Route index element={<AboutUsPage />} />
                      <Route path="seo" element={<DefaultOutlet />}>
                        <Route index element={<AboutUsSeoPage />} />
                        <Route path='edit' element={<EditAboutUsSeoPage />} />
                      </Route>
                      <Route path="headings" element={<DefaultOutlet />}>
                        <Route index element={<AboutUsHeadingsPage />} />
                        <Route path='edit' element={<EditAboutUsHeadingsPage />} />
                      </Route>
                      <Route path="highlights" element={<DefaultOutlet />}>
                        <Route index element={<AboutUsHighlightsPage />} />
                        <Route path='edit' element={<EditAboutUsHighlightsPage />} />
                      </Route>
                      <Route path="content" element={<DefaultOutlet />}>
                        <Route index element={<AboutUsContentsPage />} />
                        <Route path='edit' element={<EditAboutUsContentsPage />} />
                      </Route>
                    </Route>
                    <Route path="products" element={<DefaultOutlet />}>
                      <Route index element={<ProductsPage />} />
                      <Route path="seo" element={<DefaultOutlet />}>
                        <Route index element={<ProductsSeoPage />} />
                        <Route path='edit' element={<EditProductsSeoPage />} />
                      </Route>
                      <Route path="headings" element={<DefaultOutlet />}>
                        <Route index element={<ProductsHeadingsPage />} />
                        <Route path='edit' element={<EditProductsHeadingsPage />} />
                      </Route>
                      <Route path="categories" element={<DefaultOutlet />}>
                        <Route index element={<ProductsCategoriesPage />} />
                        <Route path='create' element={<AddProductCategoryPage />} />
                        <Route path='edit/:category_id' element={<EditProductCategoryPage />} />
                        <Route path='details/:category_id' element={<ProductsCategoryDetailsPage />} />
                        <Route path="highlights" element={<DefaultOutlet />}>
                          <Route path='create/:category_id' element={<AddProductCategoryHighlightPage />} />
                          <Route path='edit/:highlight_id' element={<EditProductCategoryHighlightPage />} />
                        </Route>
                        <Route path="accessories" element={<DefaultOutlet />}>
                          <Route path='create/:category_id' element={<AddProductCategoryAccessoryPage />} />
                          <Route path='edit/:accessory_id' element={<EditProductCategoryAccessoryPage />} />
                        </Route>
                        <Route path="pdfs" element={<DefaultOutlet />}>
                          <Route path='create/:category_id' element={<AddProductCategoryPdfPage />} />
                          <Route path='edit/:pdf_id' element={<EditProductCategoryPdfPage />} />
                        </Route>
                      </Route>
                      <Route path="products" element={<DefaultOutlet />}>
                        <Route index element={<ProductsListPage />} />
                        <Route path='create' element={<AddProductPage />} />
                        <Route path='edit/:product_id' element={<EditProductPage />} />
                        <Route path='details/:product_id' element={<ProductDetailsPage />} />
                        <Route path="highlights" element={<DefaultOutlet />}>
                          <Route path='create/:product_id' element={<AddProductHighlightPage />} />
                          <Route path='edit/:highlight_id' element={<EditProductHighlightPage />} />
                        </Route>
                        <Route path="pdfs" element={<DefaultOutlet />}>
                          <Route path='create/:product_id' element={<AddProductPdfPage />} />
                          <Route path='edit/:pdf_id' element={<EditProductPdfPage />} />
                        </Route>
                        <Route path="accessories" element={<DefaultOutlet />}>
                          <Route path='create/:product_id' element={<AddProductAccessoryPage />} />
                          <Route path='edit/:accessory_id' element={<EditProductAccessoryPage />} />
                        </Route>
                      </Route>
                    </Route>
                    <Route path="gallery" element={<DefaultOutlet />}>
                      <Route index element={<GalleryPage />} />
                      <Route path="seo" element={<DefaultOutlet />}>
                        <Route index element={<GallerySeoPage />} />
                        <Route path='edit' element={<EditGallerySeoPage />} />
                      </Route>
                      <Route path="headings" element={<DefaultOutlet />}>
                        <Route index element={<GalleryHeadingsPage />} />
                        <Route path='edit' element={<EditGalleryHeadingsPage />} />
                      </Route>
                      <Route path="images" element={<DefaultOutlet />}>
                        <Route index element={<GalleryImagesPage />} />
                        <Route path='create' element={<AddGalleryImagesPage />} />
                      </Route>
                    </Route>
                    <Route path="clients" element={<DefaultOutlet />}>
                      <Route index element={<ClientsPage />} />
                      <Route path="seo" element={<DefaultOutlet />}>
                        <Route index element={<ClientSeoPage />} />
                        <Route path='edit' element={<EditClientSeoPage />} />
                      </Route>
                      <Route path="headings" element={<DefaultOutlet />}>
                        <Route index element={<ClientHeadingsPage />} />
                        <Route path='edit' element={<EditClientHeadingsPage />} />
                      </Route>
                      <Route path="clients" element={<DefaultOutlet />}>
                        <Route index element={<ClientsListPage />} />
                        <Route path='create' element={<AddClientPage />} />
                        <Route path='edit/:client_id' element={<EditClientPage />} />
                      </Route>
                    </Route>
                    <Route path="contact-us" element={<DefaultOutlet />}>
                      <Route index element={<ContactUsPage />} />
                      <Route path="seo" element={<DefaultOutlet />}>
                        <Route index element={<ContactUsSeoPage />} />
                        <Route path='edit' element={<EditContactUsSeoPage />} />
                      </Route>
                      <Route path="address" element={<DefaultOutlet />}>
                        <Route index element={<ContactUsAddressPage />} />
                        <Route path='create' element={<AddContactAddressPage />} />
                        <Route path='edit/:address_id' element={<EditContactAddressPage />} />
                      </Route>
                      <Route path="phone-numbers" element={<DefaultOutlet />}>
                        <Route index element={<ContactUsPhoneNumbersPage />} />
                        <Route path='create' element={<AddContactPhoneNumberPage />} />
                        <Route path='edit/:phone_number_id' element={<EditContactPhoneNumberPage />} />
                      </Route>
                      <Route path="email-ids" element={<DefaultOutlet />}>
                        <Route index element={<ContactEmailIdsPage />} />
                        <Route path='create' element={<AddContactEmailIdPage />} />
                        <Route path='edit/:email_id' element={<EditContactEmailIdPage />} />
                      </Route>
                      <Route path="brochure" element={<DefaultOutlet />}>
                        <Route index element={<ContactUsBrochurePage />} />
                        <Route path='edit' element={<EditContactUsBrochurePage />} />
                      </Route>
                      <Route path="enquiry-form" element={<DefaultOutlet />}>
                        <Route index element={<ContactUsEnquiryFormPage />} />
                        <Route path='edit' element={<EditContactUsEnquiryFormPage />} />
                      </Route>
                    </Route>
                    <Route path="careers" element={<DefaultOutlet />}>
                      <Route index element={<CareersPage />} />
                      <Route path="seo" element={<DefaultOutlet />}>
                        <Route index element={<CareerSeoPage />} />
                        <Route path='edit' element={<EditCareerSeoPage />} />
                      </Route>
                      <Route path="headings" element={<DefaultOutlet />}>
                        <Route index element={<CareerHeadingsPage />} />
                        <Route path='edit' element={<EditCareerHeadingsPage />} />
                      </Route>
                      <Route path="openings" element={<DefaultOutlet />}>
                        <Route index element={<CareerOpeningsPage />} />
                        <Route path='create' element={<AddCareerOpeningPage />} />
                        <Route path='edit/:opening_id' element={<EditCareerOpeningPage />} />
                      </Route>
                    </Route>
                    <Route path="dealership-form" element={<DefaultOutlet />}>
                      <Route index element={<DealershipFormPage />} />
                      <Route path="seo" element={<DefaultOutlet />}>
                        <Route index element={<DealershipFormSeoPage />} />
                        <Route path='edit' element={<EditDealershipFormSeoPage />} />
                      </Route>
                      <Route path="headings" element={<DefaultOutlet />}>
                        <Route index element={<DealershipFormHeadingsPage />} />
                        <Route path='edit' element={<EditDealershipFormHeadingsPage />} />
                      </Route>
                    </Route>
                    <Route path="blogs" element={<DefaultOutlet />}>
                      <Route index element={<BlogPage />} />
                      <Route path="seo" element={<DefaultOutlet />}>
                        <Route index element={<BlogSeoPage />} />
                        <Route path='edit' element={<EditBlogSeoPage />} />
                      </Route>
                      <Route path="headings" element={<DefaultOutlet />}>
                        <Route index element={<BlogHeadingsPage />} />
                        <Route path='edit' element={<EditBlogHeadingsPage />} />
                      </Route>
                      <Route path="categories" element={<DefaultOutlet />}>
                        <Route index element={<BlogCategoriesPage />} />
                        <Route path='create' element={<AddBlogCategoryPage />} />
                        <Route path='edit/:blog_id' element={<EditBlogCategoryPage />} />
                      </Route>
                      <Route path="articles" element={<DefaultOutlet />}>
                        <Route index element={<BlogArticlesPage />} />
                        <Route path='create' element={<AddBlogArticlePage />} />
                        <Route path='edit/:article_id' element={<EditBlogArticlePage />} />
                      </Route>
                    </Route>
                  </Route>
                </Route>
              </>
            )}
            {/* <Route path="/404" element={<div>404</div>} /> */}
            <Route path="*" element={<Navigate to={auth ? "/dashboard" : "/"} />} />
          </Routes>
          :
          <></>
      }
    </>
  );
};

export default AppRouter;
