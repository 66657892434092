import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import DetailSection from '../../../../../components/dashboard/common/detailSection/DetailSection';
import DetailSectionContent from '../../../../../components/dashboard/common/detailSection/DetailSectionContent';
import DetailSectionRow from '../../../../../components/dashboard/common/detailSection/DetailSectionRow';

import Table from '../../../../../components/dashboard/common/table/Table';
import Heading from '../../../../../components/dashboard/common/table/Heading';
import HeadingItem from '../../../../../components/dashboard/common/table/HeadingItem';
import TableRow from '../../../../../components/dashboard/common/table/TableRow';
import RowItem from '../../../../../components/dashboard/common/table/RowItem';
import RowIcon from '../../../../../components/dashboard/common/table/RowIcon';
import TableButton from '../../../../../components/dashboard/common/table/TableButton';

import NoDataContainer from '../../../../../components/general/containers/NoDataContainer';
import PlainContainer from '../../../../../components/general/containers/PlainContainer';
import Section from '../../../../../components/general/containers/Section';
import { API_BASE_URL, LOGGER } from '../../../../../config';
import { notifyError, notifySuccess } from '../../../../../utils/toastify';

const ProductsCategoriesPage = () => {
    const admin = useSelector((state) => state.admin.value);
    let navigate = useNavigate();
    const location = useLocation();
    const [routeArray, setRouteArray] = useState(location.pathname.substring(1).split('/'));
    const [routeText, setRouteText] = useState('');
    const [productCategories, setProductCategories] = useState([]);
  
    useEffect(() => {
        let tempRoute = '';
        routeArray.map(route => {
            tempRoute = tempRoute.concat(`${route} / `);
            tempRoute = tempRoute.replace('-', ' ');
        });
        setRouteText(removeLastChars(tempRoute, 3));
    }, []);
  
    let removeLastChars = (str, chars) => {
        return str.substring(0, str.length - chars);
    }
  
    const config = {
        headers: {
            'Authorization': admin.access_token
        }
    }

    const getProductCategoriesHandler = async () => {
        try {
            const getProductCategoriesReq = await axios.get(`${API_BASE_URL}product/category`);
            console.log(getProductCategoriesReq.data);
            setProductCategories(getProductCategoriesReq.data);
        }
        catch (error) {
            if(error.response.status === 404){
                console.log('not found');
            }
        }
    }

    useEffect(() => {
        getProductCategoriesHandler();
    }, []);

    const deleteItem = async (item_id) => {

    }

    return (
        <>
            <Section heading="Categories" description={routeText} action={() => navigate("/dashboard/pages/products/categories/create")} actionLable="add new" showAction={true}>
                <PlainContainer type="fullwidth">
                    <Table>
                        <Heading>
                            <HeadingItem size="1" isIcon={false} icon="" lable={`sl no`} />
                            <HeadingItem size="4" isIcon={false} icon="" lable={`name`} />
                            <HeadingItem size="1" isIcon={false} icon="" lable={``} />
                            <HeadingItem size="3" isIcon={false} icon="" lable={`action`} />
                        </Heading>
                        <PlainContainer type="fullwidth">
                        {
                            productCategories.map((category, i) => {
                                return (
                                    <TableRow key={category.id}>
                                        <RowItem size="1" lable={i+1} isCenter={true} hasChildren={false}></RowItem>
                                        <RowItem size="4" lable={category.category.name} isCenter={false} hasChildren={false}></RowItem>
                                        <RowItem size="1" isCenter={false} hasChildren={true}>
                                            <RowIcon name="fa-solid fa-eye" action={()=> navigate(`/dashboard/pages/products/categories/details/${category.id}`)} styles={{cursor: 'pointer'}} />
                                        </RowItem>
                                        <RowItem size="3" lable="3" isCenter={false} hasChildren={true}>
                                            <TableButton styles={{width: 'calc(50% - 5px)', marginRight: '10px'}} action={() => navigate(`edit/${category.id}`)} type="primary">edit</TableButton>
                                            <TableButton styles={{width: 'calc(50% - 5px)'}} action={() => deleteItem(category.id)} type="danger">delete</TableButton>
                                        </RowItem>
                                    </TableRow>
                                )
                            })
                        }
                        </PlainContainer>
                    </Table>
                </PlainContainer>
            </Section>
        </>
    )
}

export default ProductsCategoriesPage

