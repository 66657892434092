import React from 'react'

const RowIcon = ({name, action, styles}) => {
  return (
    <div className="tableRowItemIcon center" onClick={action} style={styles}>
        <i className={`${name} tableRowItemIco`}></i>
    </div>
  )
}

export default RowIcon