import AppRouter from './AppRouter';
import './styles/global.scss';
import './styles/all.min.css';
import './styles/icofont.min.css';

function App() {
  return <AppRouter />
}

export default App;
