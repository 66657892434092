import React, { useState, useEffect, useRef, forwardRef} from 'react';
import { useLocation, useNavigate } from "react-router-dom";

import PlainContainer from '../../../../../../components/general/containers/PlainContainer';
import SplitContainer from '../../../../../../components/general/containers/SplitContainer';
import Section from '../../../../../../components/general/containers/Section';
import Form from '../../../../../../components/general/forms/core/Form';
import FormActions from '../../../../../../components/general/forms/core/FormActions';
import TextInput from '../../../../../../components/general/forms/TextInput';
import Button from '../../../../../../components/general/forms/Button';

import axios from 'axios';

import { useSelector } from 'react-redux';
import { API_BASE_URL, LOGGER } from '../../../../../../config';
import { notifyError, notifySuccess } from '../../../../../../utils/toastify';
import { ADDRESS_UPDATED, REQUEST_ERROR } from '../../../../../../utils/messages';

const EditFooterAddressPage = () => {
    let navigate = useNavigate();
    const location = useLocation();

    const [routeArray, setRouteArray] = useState(location.pathname.substring(1).split('/'));
    const [routeText, setRouteText] = useState('');

    useEffect(() => {
        let tempRoute = '';
        routeArray.map(route => {
            tempRoute = tempRoute.concat(`${route} / `);
            tempRoute = tempRoute.replace('-', ' ');
        });
        setRouteText(removeLastChars(tempRoute, 3));
    }, []);

    let removeLastChars = (str, chars) => {
        return str.substring(0, str.length - chars);
    }

    const admin = useSelector((state) => state.admin.value);

    const config = {
        headers: {
        'Authorization': admin.access_token
        }
    }

    const [heading, setHeading] = useState('');
    const [address, setAddress] = useState('');

    const [isHeadingError, setIsHeadingError] = useState(false);
    const [isAddressError, setIsAddressError] = useState(false);

    const [headingErrorTxt, setHeadingErrorTxt] = useState("");
    const [addressErrorTxt, setAddressErrorTxt] = useState("");

    const [resetAttachment, setResetAttachment] = useState(false);

    const getFooterAddressHandler = async () => {
        try {
            const getFooterAddressReq = await axios.get(`${API_BASE_URL}footer/address`);
            console.log(getFooterAddressReq);
            setHeading(getFooterAddressReq.data.heading);
            setAddress(getFooterAddressReq.data.description);
        }
        catch (error) {
            if(error.response.status === 404){
                console.log('not found');
            }
        }
    }

    useEffect(() => {
        getFooterAddressHandler();
    }, []);

    const submitEditThisForm = async (e) => {
        e.preventDefault();

        if(heading == ''){
            setHeadingErrorTxt('Heading is required');
            setIsHeadingError(true);
            return;
        }
        else{
            setHeadingErrorTxt('');
            setIsHeadingError(false);
        }

        if(address == ''){
            setAddressErrorTxt('Address is required');
            setIsAddressError(true);
            return;
        }
        else{
            setAddressErrorTxt('');
            setIsAddressError(false);
        }

        const data = {
            heading: heading,
            description: address
        }

        try {
            const updateFooterAddressReq = await axios.patch(`${API_BASE_URL}footer/address/edit`, data, config);
            notifySuccess(ADDRESS_UPDATED);
            navigate('/dashboard/general/footer/address');
            LOGGER &&  console.log(updateFooterAddressReq);
        }
        catch (error) {
            notifyError(REQUEST_ERROR);
            LOGGER &&  console.log(error);
        }
    }

    const resetForm = () => {
        
    }

    return (
        <Section heading="address" description={routeText} action={() => navigate("/dashboard/general/footer/address")} actionLable="back" showAction={true}>
            <PlainContainer styles={{width: '100%', display: 'flex', flexDirection: 'column', position: 'static'}}>
                <form onSubmit={(e) => submitEditThisForm(e)} noValidate>
                    <Form>
                        <SplitContainer type="fullwidth">
                            <TextInput lable="section heading" isMandatory={true} isError={isHeadingError} error={headingErrorTxt}>
                                <input type="text" className="formStepInputTxt capitalize" value={heading} placeholder="Please Enter The Section Heading" onChange={(e) => setHeading(e.target.value)} />
                            </TextInput>
                            <TextInput lable="address" isMandatory={true} isError={isAddressError} error={addressErrorTxt}>
                                <input type="text" className="formStepInputTxt capitalize" value={address} placeholder="Please Enter The Address" onChange={(e) => setAddress(e.target.value)} />
                            </TextInput>
                        </SplitContainer>
                        <FormActions isPrimary={true} isSecondary={true} primaryLable="submit" secondaryLable="reset" secondaryAction={() => resetForm()} />
                    </Form>
                </form>
            </PlainContainer>
        </Section>
    )
}

export default EditFooterAddressPage