import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import Heading from '../../../../../components/dashboard/common/table/Heading';
import HeadingItem from '../../../../../components/dashboard/common/table/HeadingItem';
import RowItem from '../../../../../components/dashboard/common/table/RowItem';
import Table from '../../../../../components/dashboard/common/table/Table';
import TableButton from '../../../../../components/dashboard/common/table/TableButton';
import TableRow from '../../../../../components/dashboard/common/table/TableRow';
import PlainContainer from '../../../../../components/general/containers/PlainContainer';
import Section from '../../../../../components/general/containers/Section';
import { API_BASE_URL, DEFAULT_PAGE_SIZE, FIRST_PAGE, LOGGER } from '../../../../../config';
import { REQUEST_ERROR, EMAIL_ID_DELETED } from '../../../../../utils/messages';
import { notifyError, notifySuccess } from '../../../../../utils/toastify';

const FooterEmailIdsPage = () => {
    const admin = useSelector((state) => state.admin.value);
    let navigate = useNavigate();
    const location = useLocation();
    const [routeArray, setRouteArray] = useState(location.pathname.substring(1).split('/'));
    const [routeText, setRouteText] = useState('');
    const [footerEmailIds, setFooterEmailIds] = useState([]);
  
    useEffect(() => {
        let tempRoute = '';
        routeArray.map(route => {
            tempRoute = tempRoute.concat(`${route} / `);
            tempRoute = tempRoute.replace('-', ' ');
        });
        setRouteText(removeLastChars(tempRoute, 3));
    }, []);
  
    let removeLastChars = (str, chars) => {
        return str.substring(0, str.length - chars);
    }
  
    const config = {
        headers: {
            'Authorization': admin.access_token
        }
    }

    const getFooterEmailIdsHandler = async () => {
        try {
            const getFooterEmailIdsReq = await axios.get(`${API_BASE_URL}footer/email-ids`);
            console.log(getFooterEmailIdsReq.data);
            setFooterEmailIds(getFooterEmailIdsReq.data);
        }
        catch (error) {
            if(error.response.status === 404){
                console.log('not found');
            }
        }
    }

    useEffect(() => {
        getFooterEmailIdsHandler();
    }, []);

    const deleteItem = async (id) => {
        try {
            const deleteFooterEmailIdReq = await axios.delete(`${API_BASE_URL}footer/email-ids/${id}`, config);
            notifySuccess(EMAIL_ID_DELETED);
            getFooterEmailIdsHandler();
            LOGGER &&  console.log(deleteFooterEmailIdReq);
        }
        catch (error) {
            notifyError(REQUEST_ERROR);
            LOGGER &&  console.log(error);
        }
    }

    return (
        <Section heading="Email ID's" description={routeText} action={() => navigate("create")} actionLable="add new" showAction={true}>
            <Table>
                <Heading>
                    <HeadingItem size="1" isIcon={false} icon="" lable={`sl no`} />
                    <HeadingItem size="4" isIcon={false} icon="" lable={`Email ID`} />
                    <HeadingItem size="3" isIcon={false} icon="" lable={`action`} />
                </Heading>
                <PlainContainer styles={{width: '100%'}}>
                    {
                        footerEmailIds.map(email_id => {
                            return (
                                <TableRow key={email_id.id}>
                                    <RowItem size="1" lable={email_id.counter} isCenter={true} hasChildren={false}></RowItem>
                                    <RowItem size="4" lable={email_id.email_id} isCenter={false} hasChildren={false}></RowItem>
                                    <RowItem size="3" lable="3" isCenter={false} hasChildren={true}>
                                        <TableButton styles={{width: 'calc(50% - 5px)', marginRight: '10px'}} action={() => navigate(`edit/${email_id.id}`)} type="primary">edit</TableButton>
                                        <TableButton styles={{width: 'calc(50% - 5px)'}} action={() => deleteItem(email_id.id)} type="danger">delete</TableButton>
                                    </RowItem>
                                </TableRow>
                            )
                        })
                    }
                </PlainContainer>
            </Table>
        </Section>
    )
}

export default FooterEmailIdsPage