export const REQUEST_ERROR = "Something went wrong";

//Image Upload
export const IMAGE_UPLOADED = "Image uploaded successfully";

// header > action banner
export const ACTION_BANNER_UPDATED = "Action banner successfully updated";

// header > contact details
export const CONTACT_DETAILS_UPDATED = "Contact details successfully updated";

// header > social media links
export const SOCIAL_MEDIA_UPDATED = "Social media successfully updated";

// footer > address
export const ADDRESS_UPDATED = "Address successfully updated";

// footer > phone numbers
export const PHONE_NUMBER_ADDED = "Phone No. successfully added";
export const PHONE_NUMBER_UPDATED = "Phone No. successfully updated";
export const PHONE_NUMBER_DELETED = "Phone No. successfully deleted";

// footer > email ids
export const EMAIL_IDS_ADDED = "Email ID successfully added";
export const EMAIL_IDS_UPDATED = "Email ID successfully updated";
export const EMAIL_ID_DELETED = "Email ID successfully deleted";

// SEO
export const SEO_UPDATED = "SEO successfully updated";

// home > specializations
export const SPCIALIZATIONS_UPDATED = "Specializations successfully updated";

// home > about us
export const HOME_ABOUT_US_UPDATED = "About us successfully updated";

// home > products
export const PRODUCTS_UPDATED = "Products successfully updated";

// home > gallery
export const GALLERY_UPDATED = "Gallery successfully updated";

// home > ad banner
export const AD_BANNER_UPDATED = "Ad banner successfully updated";

// home > banner
export const BANNER_UPDATED = "Banner successfully updated";

// home > make appointment
export const MAKE_APPOINTMENT_UPDATED = "Make appointment successfully updated";

// home > clients
export const CLIENTS_UPDATED = "Clients successfully updated";

// home > video
export const VIDEO_UPDATED = "Video successfully updated";

// home > headings
export const HEADINGS_UPDATED = "Headings successfully updated";

// home > highlights
export const HIGHLIGHTS_UPDATED = "Highlights successfully updated";

// about us > contents
export const ABOUT_US_CONTENTS_UPDATED = "Contents successfully updated";

// careers > openings
export const OPENING_CREATED = "Opening successfully created";
export const OPENING_UPDATED = "Opening successfully updated";
export const OPENING_DELETED = "Opening successfully deleted";

// clients > list
export const CLIENT_CREATED = "Client successfully created";
export const CLIENT_UPDATED = "Client successfully updated";
export const CLIENT_DELETED = "Client successfully deleted";

// contact us > enquiry form
export const ENQUIRY_FORM_UPDATED = "Enquiry form successfully updated";

// contact us > address
export const CONTACT_ADDRESS_ADDED = "Address successfully added";
export const CONTACT_ADDRESS_UPDATED = "Address successfully updated";
export const CONTACT_ADDRESS_DELETED = "Address successfully deleted";

// contact us > address
export const CONTACT_BROCHURE_ADDED = "Brochure successfully added";
export const CONTACT_BROCHURE_UPDATED = "Brochure successfully updated";
export const CONTACT_BROCHURE_DELETED = "Brochure successfully deleted";

// product > category
export const CATEGORY_ADDED = "Category successfully added";
export const CATEGORY_UPDATED = "Category successfully updated";
export const CATEGORY_DELETED = "Category successfully deleted";

//location > Countries
export const COUNTRY_CREATED = "Country created successfully";
export const COUNTRY_UPDATED = "Country updated successfully";
export const COUNTRY_DELETED = "Country deleted successfully";
export const COUNTRY_INACTIVATED = "Country inactivated successfully";
export const COUNTRY_ACTIVATED = "Country activated successfully";

//location > cities
export const CITY_CREATED = "City created successfully";
export const CITY_UPDATED = "City updated successfully";
export const CITY_DELETED = "City deleted successfully";
export const CITY_INACTIVATED = "City inactivated successfully";
export const CITY_ACTIVATED = "City activated successfully";

//location > locality
export const LOCALITY_CREATED = "Locality created successfully";
export const LOCALITY_UPDATED = "Locality updated successfully";
export const LOCALITY_DELETED = "Locality deleted successfully";
export const LOCALITY_INACTIVATED = "Locality inactivated successfully";
export const LOCALITY_ACTIVATED = "Locality activated successfully";

//location > state
export const STATE_CREATED = "State created successfully";
export const STATE_UPDATED = "State updated successfully";
export const STATE_DELETED = "State deleted successfully";
export const STATE_INACTIVATED = "State inactivated successfully";
export const STATE_ACTIVATED = "State activated successfully";



// super admin
export const SUPER_ADMIN_CREATED = "Super admin created successfully";
export const SUPER_ADMIN_UPDATED = "Super admin updated successfully";
export const SUPER_ADMIN_DELETED = "Super admin deleted successfully";
export const SUPER_ADMIN_INACTIVATED = "Super admin inactivated successfully";
export const SUPER_ADMIN_ACTIVATED = "Super admin activated successfully";
export const SUPER_ADMIN_LOGIN_FAILED = "Invalid Email ID or Password";
export const SUPER_ADMIN_LOGIN_SUCCESS = "Login Successfull";

// admin
export const ADMIN_CREATED = "Admin created successfully";
export const ADMIN_UPDATED = "Admin updated successfully";
export const ADMIN_DELETED = "Admin deleted successfully";
export const ADMIN_INACTIVATED = "Admin inactivated successfully";
export const ADMIN_ACTIVATED = "Admin activated successfully";
export const ADMIN_LOGIN_FAILED = "Invalid Email ID or Password";
export const ADMIN_LOGIN_SUCCESS = "Login Successfull";
