import React from 'react';
import PlainContainer from './PlainContainer';
import Text from '../typography/Text';
import Button from '../forms/Button';

const Section = ({heading, description, action, actionLable, showAction, styles, children}) => {
    return (
        <PlainContainer styles={{display: 'flex', flexDirection: 'column', width: '100%', background: "#fff", borderRadius: '10px', padding: '15px 20px', boxShadow: '1px 2px 3px rgb(0 0 0 / 10%)', position: 'inherit'}}>
            <PlainContainer styles={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', position: 'static'}}>
                <PlainContainer styles={{display: 'flex', flexDirection: 'column'}}>
                    <Text type="default" variant="section-heading">{heading}</Text>
                    <Text type="default" variant="section-description" styles={{marginTop: '5px'}}>{description}</Text>
                </PlainContainer>
                {
                    showAction === true
                    ?
                        <Button action={action} type="primary">{actionLable}</Button>  
                    :
                    <></>
                }
            </PlainContainer>

            <PlainContainer styles={{width: '100%', marginTop: '20px', position: 'static'}}>
                {children}
            </PlainContainer>
        </PlainContainer>
    )
}

export default Section