import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { API_BASE_URL, LOGGER } from '../../../config';
import { REQUEST_ERROR } from '../../../utils/messages';
import { notifyError } from '../../../utils/toastify';
import PlainContainer from '../../general/containers/PlainContainer';
import StatSingle from './StatSingle';

const Stats = ({auth, from, to, newUsers = false}) => {

    const [newOrders, setNewOrders] = useState(0);
    const [newCustomers, setNewCustomers] = useState(0);
    const [orders, setOrders] = useState(0);
    const [revenue, setRevenue] = useState(0);
    const [cancellations, setCancellations] = useState(0);

    const getOrderStats = async () => {

        const data = {
            from: from,
            to: to
        }

        try{
            const getOrderStatsReq = await axios.post(`${API_BASE_URL}order/revenue-range`, data, auth);
            console.log(getOrderStatsReq);
            if (getOrderStatsReq.status === 200) {
                setNewCustomers(parseInt(getOrderStatsReq.data.newUsers));
                setOrders(parseInt(getOrderStatsReq.data.totalOrders));
                setRevenue(parseFloat(getOrderStatsReq.data.totalRevenue));
                setCancellations(parseInt(getOrderStatsReq.data.totalCancellations));
                setNewOrders(parseInt(getOrderStatsReq.data.newOrders));
            }
        }
        catch(error) {
            notifyError(REQUEST_ERROR);
            LOGGER &&  console.log(error);
        }
    }

    // useEffect(() => {
    //     getOrderStats();
    // }, []);

    // useEffect(() => {
    //     getOrderStats();
    // }, [from]);

    // useEffect(() => {
    //     getOrderStats();
    // }, [to]);

    return (
        <PlainContainer type="fullwidth" styles={{display: 'flex', flexDirection: 'row'}}>
            <StatSingle color="#c02329" showIcon={false} icon="" lable="appointment requests" value={newOrders} />
            <StatSingle color="#278855" showIcon={false} icon="" lable="contact enquiries" value={orders} />
            <StatSingle color="#0a1f3e" showIcon={false} icon="" lable="callback requests" value={revenue} />
            <StatSingle color="#9c9a2d" showIcon={false} icon="" lable="dealership requests" value={cancellations} />
        </PlainContainer>
    )
}

export default Stats