import React from 'react';
import PlainContainer from '../../general/containers/PlainContainer';
import Text from '../../general/typography/Text';

const StatSingle = ({color, showIcon, icon, lable, value}) => {
  return (
    <div className="statSingle">
      <PlainContainer styles={{width: '100%', padding: '20px 30px', background: color, borderRadius: '10px', display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
        <Text variant="stats-value" type="default">{value}</Text>
        <PlainContainer styles={{width: '80px', height: '2px', background: '#fff', margin: '10px 0px 10px'}}>&nbsp;</PlainContainer>
        <Text variant="stats-lable" type="default" styles={{marginTop: '5px'}}>{lable}</Text>
      </PlainContainer>
    </div>
  );
};

export default StatSingle;
