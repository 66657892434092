import React, { useEffect, useState } from 'react';
import { API_BASE_URL } from '../../../config';
import FileUploadPopUp from '../../dashboard/popups/FileUploadPopUp';

const FileInput = ({lable, isMandatory, value, preview, showPreview, onchange, isError, error, reset}) => {

    const [showError, setShowError] = useState(isError);
    const [errorTxt, setErrorTxt] = useState(error);
    const [valueTxt, setValueTxt] = useState(value);
    const [valueId, setValueId] = useState(value);

    const [hideFilePopUp, setHideFilePopUp] = useState(true);

    const [isFileAttached, setIsFileAttached] = useState(false);

    useEffect(() => {
        setShowError(isError);
        setErrorTxt(error);
    }, [isError]);

    useEffect(() => {
        setValueId(value);
    }, [value]);

    useEffect(() => {
        setShowError(false);
        setErrorTxt('');
        setValueTxt(value);
        setValueId(value);
        setHideFilePopUp(true);
        setIsFileAttached(false);
    }, [reset]);

    const getUploadedImageUrl = (data) => {
        if (data.url) {
            setValueTxt(data.url);
            setValueId(data.id);
            setIsFileAttached(true);
            setHideFilePopUp(true);
            onchange(data.id);
        }
        setHideFilePopUp(true);
    }

    const closeFileUploadPopUp = () => {
        setHideFilePopUp(true);
    }

    return (
        <>
            <div className='formStepFileSingle'>
                <div className="formStepFile center" onClick={() => { setHideFilePopUp(false) }}>
                    <div className="formStepFileMain">
                        <div className="formStepFileMainIcon">
                            <i className="far fa-image formStepFileMainIco"></i>
                        </div>
                        <div className="formStepFileMainLable hCenter">
                            <p className="formStepFileMainLableTxt">{lable} {isMandatory === true ? <span className="formStepLableTxtCheck">*</span> : <></>}</p>
                        </div>
                    </div>
                    {
                        isFileAttached === true
                            ? 
                            <div className="formStepFileMainPreview">
                                <div className="formStepFileMainPreviewImage center">
                                    <img src={`${API_BASE_URL}${valueTxt}`} className="formStepFileMainPreviewImg" />
                                </div>
                            </div>
                            : 
                            <></>
                    }
                    {
                        showPreview === true
                            ? 
                            <div className="formStepFileMainPreview">
                                <div className="formStepFileMainPreviewImage center">
                                    <img src={`${preview}`} className="formStepFileMainPreviewImg" />
                                </div>
                            </div>
                            : 
                            <></>
                    }
                </div>
                {
                    showError === true
                    ?
                        <div className="formStepError">
                            <p className="formStepErrorTxt">{errorTxt}</p>
                        </div>
                    :
                        <></>
                }
            </div>
            

            {
                hideFilePopUp === false
                ?
                <FileUploadPopUp lable="Upload Image" primaryLable="upload" primaryAction={(e) => getUploadedImageUrl(e)} secondaryType="danger" secondaryLable="cancel" secondaryAction={() => closeFileUploadPopUp()} getUploadedImageUrl={(e) => getUploadedImageUrl(e)} />
                :
                <></>
            }
        </>
    )
}

export default FileInput