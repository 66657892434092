import React from 'react';

import { useDispatch } from 'react-redux';
import { logout } from '../../../../reducers/admin';

import { useNavigate } from 'react-router-dom';

const LogoutButton = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const logoutHandler = () => {
        dispatch(logout());
        navigate('/');
    }

    return (
        <>
            <div className="dashboardContentHeaderLogout" onClick={logoutHandler}>
                <div className="dashboardContentHeaderLogoutInner">
                    <div className="dashboardContentHeaderLogoutIcon">
                        <i className="fas fa-power-off dashboardContentHeaderLogoutIco" />
                    </div>
                </div>
            </div>  
        </>
    );
};

export default LogoutButton;
