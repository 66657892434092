import React from 'react';

const FormButtons = ({action, isPrimary, children}) => {
    return (
        <>
            {
                isPrimary === true
                ?
                <button type="submit" onClick={action} className={isPrimary ? "formStepInputButton primary" : "formStepInputButton secondary"}>{children}</button>
                :
                <button type="button" onClick={action} className={isPrimary ? "formStepInputButton primary" : "formStepInputButton secondary"}>{children}</button>
            }
            
        </>
    );
};

export default FormButtons;
