import React from 'react'
import PlainContainer from '../../../general/containers/PlainContainer'

const Table = ({children}) => {
    return (
        <PlainContainer styles={{width: '100%', overflowX: 'auto'}}>
            <PlainContainer styles={{minWidth: '100%', display: 'flex', flexDirection: 'column', border: '1px solid #3d3d3d'}}>
                {children}
            </PlainContainer>
        </PlainContainer>
        
    )
}

export default Table