import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import DetailSection from '../../../../../components/dashboard/common/detailSection/DetailSection';
import DetailSectionContent from '../../../../../components/dashboard/common/detailSection/DetailSectionContent';
import DetailSectionRow from '../../../../../components/dashboard/common/detailSection/DetailSectionRow';

import NoDataContainer from '../../../../../components/general/containers/NoDataContainer';
import PlainContainer from '../../../../../components/general/containers/PlainContainer';
import Section from '../../../../../components/general/containers/Section';
import { API_BASE_URL, LOGGER } from '../../../../../config';

import { OPENING_DELETED, REQUEST_ERROR } from '../../../../../utils/messages';
import { notifyError, notifySuccess } from '../../../../../utils/toastify';

const CareerOpeningsPage = () => {
    const admin = useSelector((state) => state.admin.value);
    let navigate = useNavigate();
    const location = useLocation();
    const [routeArray, setRouteArray] = useState(location.pathname.substring(1).split('/'));
    const [routeText, setRouteText] = useState('');
    const [careerOpenings, setCareerOpenings] = useState([]);
  
    useEffect(() => {
        let tempRoute = '';
        routeArray.map(route => {
            tempRoute = tempRoute.concat(`${route} / `);
            tempRoute = tempRoute.replace('-', ' ');
        });
        setRouteText(removeLastChars(tempRoute, 3));
    }, []);
  
    let removeLastChars = (str, chars) => {
        return str.substring(0, str.length - chars);
    }
  
    const config = {
        headers: {
            'Authorization': admin.access_token
        }
    }

    const getCareersOpeningsHandler = async () => {
        try {
            const getCareersOpeningsReq = await axios.get(`${API_BASE_URL}career/opening`);
            console.log(getCareersOpeningsReq.data);
            setCareerOpenings(getCareersOpeningsReq.data);
        }
        catch (error) {
            if(error.response.status === 404){
                console.log('not found');
            }
        }
    }

    useEffect(() => {
        getCareersOpeningsHandler();
    }, []);

    const deleteThisCareerOpeningHandler = async (id) => {
        try {
            const deleteThisCareerOpeningHandlerReq = await axios.delete(`${API_BASE_URL}career/opening/${id}`, config);
            console.log(deleteThisCareerOpeningHandlerReq.data);
            getCareersOpeningsHandler();
            notifySuccess(OPENING_DELETED);
        }
        catch (error) {
            if(error.response.status === 404){
                console.log('not found');
            }
        }
    }

    return (
        <>
            <Section heading="Openings" description={routeText} action={() => navigate("/dashboard/pages/careers")} actionLable="back" showAction={true}>
                <PlainContainer type="fullwidth">
                    <DetailSection lable="List of Openings" hasPrimaryAction={true} lablePrimaryAction={`create`} primaryAction={() => navigate("/dashboard/pages/careers/openings/create")} hasSecondaryAction={false}>
                        <div style={{paddingTop: `20px`, paddingLeft: `20px`, paddingRight: `20px`}}>
                            {
                                careerOpenings.map(opening => {
                                    return (
                                        <div style={{width: `100%`, float: `left`, marginBottom: `20px`}} key={opening.id}>
                                            <DetailSection lable={opening.title} hasPrimaryAction={true} lablePrimaryAction={`edit`} primaryAction={() => navigate(`/dashboard/pages/careers/openings/edit/${opening.id}`)} hasSecondaryAction={true} lableSecondaryAction={`delete`} secondaryAction={() => deleteThisCareerOpeningHandler(opening.id)}>
                                                <DetailSectionContent hasPadding={true}>
                                                    <DetailSectionRow lable={`experience`} value={opening.experience} isImage={false} isIcon={false} hasChildren={false} />
                                                    <DetailSectionRow lable={`job location`} value={opening.location} isImage={false} isIcon={false} hasChildren={false} />
                                                    <DetailSectionRow lable={`no. of positions`} value={opening.no_of_positions} isImage={false} isIcon={false} hasChildren={false} />
                                                    <DetailSectionRow lable={`description`} value={opening.description} isImage={false} isIcon={false} hasChildren={false} />
                                                </DetailSectionContent>
                                            </DetailSection>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </DetailSection>
                </PlainContainer>
            </Section>
        </>
    )
}

export default CareerOpeningsPage