import React, { useState, useEffect, useRef, forwardRef} from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';

import PlainContainer from '../../../../../../components/general/containers/PlainContainer';
import SplitContainer from '../../../../../../components/general/containers/SplitContainer';
import Section from '../../../../../../components/general/containers/Section';
import Form from '../../../../../../components/general/forms/core/Form';
import FormActions from '../../../../../../components/general/forms/core/FormActions';
import TextInput from '../../../../../../components/general/forms/TextInput';
import TextArea from '../../../../../../components/general/forms/TextArea';
import Button from '../../../../../../components/general/forms/Button';

import axios from 'axios';

import { useSelector } from 'react-redux';
import { API_BASE_URL, LOGGER } from '../../../../../../config';
import { notifyError, notifySuccess } from '../../../../../../utils/toastify';
import { REQUEST_ERROR, OPENING_UPDATED } from '../../../../../../utils/messages';

const EditCareerOpeningPage = () => {

    let navigate = useNavigate();
    const location = useLocation();
    let { opening_id } = useParams(); 

    const [routeArray, setRouteArray] = useState(location.pathname.substring(1).split('/'));
    const [routeText, setRouteText] = useState('');

    useEffect(() => {
        let tempRoute = '';
        routeArray.map(route => {
            tempRoute = tempRoute.concat(`${route} / `);
            tempRoute = tempRoute.replace('-', ' ');
        });
        setRouteText(removeLastChars(tempRoute, 3));
    }, []);

    let removeLastChars = (str, chars) => {
        return str.substring(0, str.length - chars);
    }

    const admin = useSelector((state) => state.admin.value);

    const config = {
        headers: {
        'Authorization': admin.access_token
        }
    }

    const [title, setTitle] = useState('');
    const [experience, setExperience] = useState('');
    const [noOfPositions, setNoOfPositions] = useState('');
    const [jobLocation, setJobLocation] = useState('');
    const [description, setDescription] = useState('');

    const [isTitleError, setIsTitleError] = useState(false);
    const [isExperienceError, setIsExperienceError] = useState(false);
    const [isNoOfPositionsError, setIsNoOfPositionsError] = useState(false);
    const [isJobLocationError, setIsJobLocationError] = useState(false);
    const [isDescriptionError, setIsDescriptionError] = useState(false);

    const [titleErrorTxt, setTitleErrorTxt] = useState("");
    const [experienceErrorTxt, setExperienceErrorTxt] = useState("");
    const [noOfPositionsErrorTxt, setNoOfPositionsErrorTxt] = useState("");
    const [jobLocationErrorTxt, setJobLocationErrorTxt] = useState("");
    const [descriptionErrorTxt, setDescriptionErrorTxt] = useState("");

    const [resetAttachment, setResetAttachment] = useState(false);

    const getThisCareerOpeningHandler = async () => {
        try {
            const getThisCareerOpeningReq = await axios.get(`${API_BASE_URL}career/opening/${opening_id}`);
            console.log(getThisCareerOpeningReq.data);
            setTitle(getThisCareerOpeningReq.data.title);
            setExperience(getThisCareerOpeningReq.data.experience);
            setNoOfPositions(getThisCareerOpeningReq.data.no_of_positions);
            setJobLocation(getThisCareerOpeningReq.data.location);
            setDescription(getThisCareerOpeningReq.data.description);
        }
        catch (error) {
            if(error.response.status === 404){
                console.log('not found');
            }
        }
    }

    useEffect(() => {
        getThisCareerOpeningHandler();
    }, []);

    const submitEditThisForm = async (e) => {
        e.preventDefault();

        if(title == ''){
            setTitleErrorTxt('Title is required');
            setIsTitleError(true);
            return;
        }
        else{
            setTitleErrorTxt('');
            setIsTitleError(false);
        }

        if(experience == ''){
            setExperienceErrorTxt('Experience is required');
            setIsExperienceError(true);
            return;
        }
        else{
            setExperienceErrorTxt('');
            setIsExperienceError(false);
        }

        if(noOfPositions == ''){
            setNoOfPositionsErrorTxt('No. of Positions is required');
            setIsNoOfPositionsError(true);
            return;
        }
        else{
            setNoOfPositionsErrorTxt('');
            setIsNoOfPositionsError(false);
        }

        if(jobLocation == ''){
            setJobLocationErrorTxt('Job location is required');
            setIsJobLocationError(true);
            return;
        }
        else{
            setJobLocationErrorTxt('');
            setIsJobLocationError(false);
        }

        const data = {
            title: title,
            experience: experience,
            no_of_positions: noOfPositions,
            location: jobLocation,
            description: description
        }

        try {
            const updateCareerOpeningReq = await axios.patch(`${API_BASE_URL}career/opening/${opening_id}`, data, config);
            notifySuccess(OPENING_UPDATED);
            navigate('/dashboard/pages/careers/openings');
            LOGGER &&  console.log(updateCareerOpeningReq);
        }
        catch (error) {
            notifyError(REQUEST_ERROR);
            LOGGER &&  console.log(error);
        }
    }

    const resetForm = () => {
        
    }

    return (
        <Section heading="Openings" description={routeText} action={() => navigate("/dashboard/pages/careers/openings")} actionLable="back" showAction={true}>
            <PlainContainer styles={{width: '100%', display: 'flex', flexDirection: 'column', position: 'static'}}>
                <form onSubmit={(e) => submitEditThisForm(e)} noValidate>
                    <Form>
                        <TextInput lable="title" isMandatory={true} isError={isTitleError} error={titleErrorTxt}>
                            <input type="text" className="formStepInputTxt capitalize" value={title} placeholder="Please Enter The Title" onChange={(e) => setTitle(e.target.value)} />
                        </TextInput>
                        <SplitContainer type={`fullwidth`}>
                            <TextInput lable="experience" isMandatory={true} isError={isExperienceError} error={experienceErrorTxt}>
                                <input type="text" className="formStepInputTxt capitalize" value={experience} placeholder="Please Enter The Experience" onChange={(e) => setExperience(e.target.value)} />
                            </TextInput>
                            <TextInput lable="No. of Positions" isMandatory={true} isError={isNoOfPositionsError} error={noOfPositionsErrorTxt}>
                                <input type="text" className="formStepInputTxt capitalize" value={noOfPositions} placeholder="Please Enter The No. of Positions" onChange={(e) => setNoOfPositions(e.target.value)} />
                            </TextInput>
                        </SplitContainer>
                        <TextInput lable="job location" isMandatory={true} isError={isJobLocationError} error={jobLocationErrorTxt}>
                            <input type="text" className="formStepInputTxt capitalize" value={jobLocation} placeholder="Please Enter The Job Location" onChange={(e) => setJobLocation(e.target.value)} />
                        </TextInput>
                        <TextArea lable="description" rows={3} isMandatory={true} placeholder="Please Enter The Description" value={description} onchange={(data) => setDescription(data)} isError={isDescriptionError} error={descriptionErrorTxt} />
                        <FormActions isPrimary={true} isSecondary={true} primaryLable="submit" secondaryLable="reset" secondaryAction={() => resetForm()} />
                    </Form>
                </form>
            </PlainContainer>
        </Section>
    )
}

export default EditCareerOpeningPage