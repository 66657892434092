import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import Section from '../../../../components/general/containers/Section';
import PageLinkButton from '../../../../components/general/forms/PageLinkButton';
import PageLinkSection from '../../../../components/general/forms/PageLinkSection';

const HomePage = () => {
    let navigate = useNavigate();
    const location = useLocation();
    const [routeArray, setRouteArray] = useState(location.pathname.substring(1).split('/'));
    const [routeText, setRouteText] = useState('');

    useEffect(() => {
        let tempRoute = '';
        routeArray.map(route => {
            tempRoute = tempRoute.concat(`${route} / `);
            tempRoute = tempRoute.replace('-', ' ');
        });
        setRouteText(removeLastChars(tempRoute, 3));
    }, []);

    let removeLastChars = (str, chars) => {
        return str.substring(0, str.length - chars);
    }

    return (
        <Section heading="home page" description={routeText} action={""} showAction={false}>
            <PageLinkSection>
                <PageLinkButton lable="SEO" to="seo" />
                <PageLinkButton lable="banner" to="banner" />
                <PageLinkButton lable="specializations" to="specializations" />
                <PageLinkButton lable="about us" to="about-us" />
                <PageLinkButton lable="products" to="products" />
                <PageLinkButton lable="gallery" to="gallery" />
                <PageLinkButton lable="ad banner" to="ad-banner" />
                <PageLinkButton lable="testimonials" to="testimonials" />
                <PageLinkButton lable="make appointment" to="make-appointment" />
                <PageLinkButton lable="clients" to="clients" />
                <PageLinkButton lable="partner portals" to="partner-portals" />
                <PageLinkButton lable="main video" to="video" />
                <PageLinkButton lable="main slider ads" to="main-ads-slider" /> {/* this is add by hemendra*/}
                <PageLinkButton lable="popup ad" to="popup-ad" />{/* this is add by hemendra*/}
            </PageLinkSection>
        </Section>
    )
}

export default HomePage