import React, { useState, useEffect, useRef, forwardRef} from 'react';
import { useLocation, useNavigate } from "react-router-dom";

import PlainContainer from '../../../../../../components/general/containers/PlainContainer';
import SplitContainer from '../../../../../../components/general/containers/SplitContainer';
import Section from '../../../../../../components/general/containers/Section';
import Form from '../../../../../../components/general/forms/core/Form';
import FormActions from '../../../../../../components/general/forms/core/FormActions';
import TextInput from '../../../../../../components/general/forms/TextInput';
import MultiFileInput from '../../../../../../components/general/forms/MultiFileInput';
import TextArea from '../../../../../../components/general/forms/TextArea';
import Button from '../../../../../../components/general/forms/Button';

import axios from 'axios';

import { useSelector } from 'react-redux';
import { API_BASE_URL, LOGGER } from '../../../../../../config';
import { notifyError, notifySuccess } from '../../../../../../utils/toastify';
import { REQUEST_ERROR, HEADINGS_UPDATED } from '../../../../../../utils/messages';

const AddGalleryImagesPage = () => {
    let navigate = useNavigate();
    const location = useLocation();

    const [routeArray, setRouteArray] = useState(location.pathname.substring(1).split('/'));
    const [routeText, setRouteText] = useState('');

    useEffect(() => {
        let tempRoute = '';
        routeArray.map(route => {
            tempRoute = tempRoute.concat(`${route} / `);
            tempRoute = tempRoute.replace('-', ' ');
        });
        setRouteText(removeLastChars(tempRoute, 3));
    }, []);

    let removeLastChars = (str, chars) => {
        return str.substring(0, str.length - chars);
    }

    const admin = useSelector((state) => state.admin.value);

    const config = {
        headers: {
            'Authorization': admin.access_token
        }
    }

    const [images, setImages] = useState('');
    const [isImagesError, setIsImagesError] = useState(false);
    const [imagesErrorTxt, setImagesErrorTxt] = useState("");

    const [resetAttachment, setResetAttachment] = useState(false);

    const getGalleryImagesHandler = async () => {
        try {
            const getGalleryImagesReq = await axios.get(`${API_BASE_URL}gallery/image`);
            console.log(getGalleryImagesReq);
        }
        catch (error) {
            if(error.response.status === 404){
                console.log('not found');
            }
        }
    }

    useEffect(() => {
        // getGalleryImagesHandler();
    }, []);

    const getUploadedImages = async (e) => {
        console.log(e);
        setImages(e);
    }

    const uploadImagesHandler = async (data) => {

        const config_temp = {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': admin.access_token
            }
        };

        var formData = new FormData();

        data.image_urls.map(image => {
            formData.append(`image_urls`, image);
        });

        console.log(formData);

        try {
            const uploadImagesReq = await axios.post(`${API_BASE_URL}media/multi`, formData, config_temp);
            console.log(uploadImagesReq);
            return uploadImagesReq.data
        }
        catch (error) {
            notifyError(REQUEST_ERROR);
            LOGGER &&  console.log(error);
            return
        }
    }

    const submitEditThisForm = async (e) => {
        e.preventDefault();

        if(images.length == 0){
            setImagesErrorTxt('Images is required');
            setIsImagesError(true);
            return;
        }
        else{
            setImagesErrorTxt('');
            setIsImagesError(false);
        }

        const data = {
            image_urls: images
        }

        // upload the images and get their links
        let getUploadedImagesURL = await uploadImagesHandler(data);
        console.log(getUploadedImagesURL);

        let upload_images = [];

        getUploadedImagesURL.image_urls.map(image => {
            let thisImage = {
                image_url: image.id
            }
            upload_images.push(thisImage);
        });

        let upload_data = {
            images: JSON.stringify(upload_images)
        }

        try {
            const saveGalleryImagesReq = await axios.post(`${API_BASE_URL}gallery/image`, upload_data, config);
            notifySuccess(HEADINGS_UPDATED);
            navigate('/dashboard/pages/gallery/images');
            LOGGER &&  console.log(saveGalleryImagesReq);
        }
        catch (error) {
            notifyError(REQUEST_ERROR);
            LOGGER &&  console.log(error);
        }
    }

    const resetForm = () => {
        
    }

    return (
        <Section heading="Images" description={routeText} action={() => navigate("/dashboard/pages/gallery/images")} actionLable="back" showAction={true}>
            <PlainContainer styles={{width: '100%', display: 'flex', flexDirection: 'column', position: 'static'}}>
                <form onSubmit={(e) => submitEditThisForm(e)} noValidate>
                    <Form>
                        <MultiFileInput setImages={(e) => getUploadedImages(e)} />
                        {
                            isImagesError
                            ?
                            <div className="formStepError">
                                <p className="formStepErrorTxt">{imagesErrorTxt}</p>
                            </div>
                            :
                            <></>
                        }
                        <FormActions isPrimary={true} isSecondary={true} primaryLable="submit" secondaryLable="reset" secondaryAction={() => resetForm()} />
                    </Form>
                </form>
            </PlainContainer>
        </Section>
    )
}

export default AddGalleryImagesPage