import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PlainContainer from '../../../../../../components/general/containers/PlainContainer';
import Section from '../../../../../../components/general/containers/Section';
import Form from '../../../../../../components/general/forms/core/Form';
import FormActions from '../../../../../../components/general/forms/core/FormActions';
import TextInput from '../../../../../../components/general/forms/TextInput';
import TextArea from '../../../../../../components/general/forms/TextArea';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { API_BASE_URL, LOGGER } from '../../../../../../config';
import { notifyError, notifySuccess } from '../../../../../../utils/toastify';
import { REQUEST_ERROR, AD_SLIDER_UPDATED, OPENING_CREATED } from '../../../../../../utils/messages';
import SplitContainer from '../../../../../../components/general/containers/SplitContainer';
import FileInput from '../../../../../../components/general/forms/FileInput';




const HomeAddSliderMain = () => {
    let navigate = useNavigate();
    const location = useLocation();

    const admin = useSelector((state) => state.admin.value);

    const config = {
        headers: {
            'Authorization': admin.access_token
        }
    };

    const [routeArray, setRouteArray] = useState(location.pathname.substring(1).split('/'));
    const [routeText, setRouteText] = useState('');

    useEffect(() => {
        let tempRoute = '';
        routeArray.map(route => {
            tempRoute = tempRoute.concat(`${route} / `);
            tempRoute = tempRoute.replace('-', ' ');
        });
        setRouteText(removeLastChars(tempRoute, 3));
    }, []);
    let removeLastChars = (str, chars) => {
        return str.substring(0, str.length - chars);
    }
    const [productTitle, setProductTitle] = useState('');
    const [seoURL, setSeoURL] = useState('');
    const [adDescription, setAdDescription] = useState('');
    const [image, setImage] = useState('');
    const [imageID, setImageID] = useState('');
    const [resetAttachment, setResetAttachment] = useState(false);


    const [isProductTitleError, setIsProductTitleError] = useState(false);
    const [isSeoURLError, setIsSeoURLError] = useState(false);
    const [isAdDescriptionError, setIsAdDescriptionError] = useState(false);
    const [isFeaturedImageError, setIsFeaturedImageError] = useState(false);

    const [productTitleErrorTxt, setProductTitleErrorTxt] = useState('');
    const [seoURLErrorTxt, setSeoURLErrorTxt] = useState('');
    const [adDescriptionErrorTxt, setAdDescriptionErrorTxt] = useState('');
    const [featuredImageErrorTxt, setFeaturedImageErrorTxt] = useState('');

    const resetForm = () => {
        setProductTitle('');
        setSeoURL('');
        setAdDescription('');
        setImageID(null);
        setIsProductTitleError(false);
        setIsSeoURLError(false);
        setIsAdDescriptionError(false);
        setIsFeaturedImageError(false);
        setProductTitleErrorTxt('');
        setSeoURLErrorTxt('');
        setAdDescriptionErrorTxt('');
        setFeaturedImageErrorTxt('');
    };


    const submitFormData = async (e) => {
        e.preventDefault();

        // Validation logic
        if (productTitle.trim() === '') {
            setProductTitleErrorTxt('Product Title is required');
            setIsProductTitleError(true);
            return;
        } else {
            setProductTitleErrorTxt('');
            setIsProductTitleError(false);
        }

        if (seoURL.trim() === '') {
            setSeoURLErrorTxt('SEO URL is required');
            setIsSeoURLError(true);
            return;
        } else {
            setSeoURLErrorTxt('');
            setIsSeoURLError(false);
        }

        if (adDescription.trim() === '') {
            setAdDescriptionErrorTxt('Ad Description is required');
            setIsAdDescriptionError(true);
            return;
        } else {
            setAdDescriptionErrorTxt('');
            setIsAdDescriptionError(false);
        }

        if (imageID === null) {
            setFeaturedImageErrorTxt('Featured Image is required');
            setIsFeaturedImageError(true);
            return;
        } else {
            setFeaturedImageErrorTxt('');
            setIsFeaturedImageError(false);
        }

        // // Placeholder API call
        // const formData = new FormData();
        // formData.append('title', productTitle);
        // formData.append('seoUrl', seoURL);
        // formData.append('description', adDescription);
        // formData.append('image', imageID);

        const data = {
            title: productTitle,
            seoUrl: seoURL,
            description: adDescription,
            image: imageID

        }

       
        try {
            const response = await axios.post(`${API_BASE_URL}home/mainadslide/create`, data, config);
            notifySuccess(OPENING_CREATED);
            navigate('/dashboard/pages/home/main-ads-slider');
            LOGGER && console.log(response);
        } catch (error) {
            notifyError('Error submitting data');
            LOGGER && console.error(error);
        }
    };


    return (
        <>
            <Section heading="Add new Slide" description={routeText} action={() => navigate("/dashboard/pages/home/main-ads-slider")} actionLable="back" showAction={true}>

                <PlainContainer styles={{ width: '100%', display: 'flex', flexDirection: 'column', position: 'static' }}>
                    <form onSubmit={(e) => submitFormData(e)} noValidate>

                        <Form>

                            <SplitContainer type={`fullwidth`}>
                                <TextInput lable="Product title" isMandatory={true} isError={isProductTitleError} error={productTitleErrorTxt} >
                                    <input type="text" className="formStepInputTxt capitalize" placeholder='Enter product Title'
                                        value={productTitle}
                                        onChange={(e) => setProductTitle(e.target.value)}

                                    />

                                </TextInput>
                                <TextInput lable="SEO URL" isMandatory={true} isError={isSeoURLError} error={seoURLErrorTxt}>
                                    <input type="text" className="formStepInputTxt capitalize" placeholder='Enter the product full url'
                                        value={seoURL}
                                        onChange={(e) => setSeoURL(e.target.value)}
                                    />
                                </TextInput>
                            </SplitContainer>
                            <TextArea lable="Ad Description" rows={3}
                                placeholder='Enter Description'
                                isMandatory={true} isError={isAdDescriptionError} error={adDescriptionErrorTxt} value={adDescription} onchange={(e) => setAdDescription(e)}

                            />
                            <FileInput lable="Featured image"
                                isMandatory={true}
                                value={imageID}
                                preview={image}
                                onchange={(data) => { setImageID(data); LOGGER && console.log(data) }}
                                isError={isFeaturedImageError}
                                error={featuredImageErrorTxt}
                                reset={resetAttachment}
                            />

                            <FormActions isPrimary={true} isSecondary={true} primaryLable="submit" secondaryLable="reset" secondaryAction={() => resetForm()} />

                        </Form>
                    </form>
                </PlainContainer>
            </Section>
        </>
    )
}
export default HomeAddSliderMain;

