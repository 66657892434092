import React from 'react'

const Button = ({action, type, isSubmit, styles, children }) => {
  return (
    <>
      {
        type === "primary"
        ?
          <button type={isSubmit === true ? "submit" : "button"} style={styles} onClick={action} className="actionButton primary">{children}</button>
        :
        type === "success"
        ?
          <button type={isSubmit === true ? "submit" : "button"} style={styles} onClick={action} className="actionButton success">{children}</button>
        :
        type === "danger"
        ?
          <button type="button" style={styles} onClick={action} className="actionButton danger">{children}</button>
        :
          <button type="button" style={styles} onClick={action} className="actionButton primary">{children}</button>
      }
    </>
    
  )
}

export default Button