import React from 'react';
import { Outlet } from 'react-router-dom';
import Layout from '../../components/dashboard/layout/Layout';

const RootOutlet = () => {
    return (
        <>
            <Layout>
                <Outlet />
            </Layout>
        </>
    );
};

export default RootOutlet;
