import React, { useState, useEffect, useRef, forwardRef} from 'react';
import { useLocation, useNavigate } from "react-router-dom";

import PlainContainer from '../../../../../../components/general/containers/PlainContainer';
import SplitContainer from '../../../../../../components/general/containers/SplitContainer';
import Section from '../../../../../../components/general/containers/Section';
import Form from '../../../../../../components/general/forms/core/Form';
import FormActions from '../../../../../../components/general/forms/core/FormActions';
import TextInput from '../../../../../../components/general/forms/TextInput';
import TextArea from '../../../../../../components/general/forms/TextArea';
import FileInput from '../../../../../../components/general/forms/FileInput';
import Button from '../../../../../../components/general/forms/Button';

import axios from 'axios';

import { useSelector } from 'react-redux';
import { API_BASE_URL, LOGGER } from '../../../../../../config';
import { notifyError, notifySuccess } from '../../../../../../utils/toastify';
import { BANNER_UPDATED, REQUEST_ERROR } from '../../../../../../utils/messages';

const EditHomeBannerPage = () => {
    let navigate = useNavigate();
    const location = useLocation();

    const [routeArray, setRouteArray] = useState(location.pathname.substring(1).split('/'));
    const [routeText, setRouteText] = useState('');

    useEffect(() => {
        let tempRoute = '';
        routeArray.map(route => {
            tempRoute = tempRoute.concat(`${route} / `);
            tempRoute = tempRoute.replace('-', ' ');
        });
        setRouteText(removeLastChars(tempRoute, 3));
    }, []);

    let removeLastChars = (str, chars) => {
        return str.substring(0, str.length - chars);
    }

    const admin = useSelector((state) => state.admin.value);

    const config = {
        headers: {
        'Authorization': admin.access_token
        }
    }

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [primaryButtonLable, setPrimaryButtonLable] = useState('');
    const [primaryButtonURL, setPrimaryButtonURL] = useState('');
    const [secondaryButtonLable, setSecondaryButtonLable] = useState('');
    const [secondaryButtonURL, setSecondaryButtonURL] = useState('');
    const [image, setImage] = useState('');
    const [imageID, setImageID] = useState('');

    const [isTitleError, setIsTitleError] = useState(false);
    const [isDescriptionError, setIsDescriptionError] = useState(false);
    const [isPrimaryButtonLableError, setIsPrimaryButtonLableError] = useState(false);
    const [isPrimaryButtonURLError, setIsPrimaryButtonURLError] = useState(false);
    const [isSecondaryButtonLableError, setIsSecondaryButtonLableError] = useState(false);
    const [isSecondaryButtonURLError, setIsSecondaryButtonURLError] = useState(false);
    const [isImageError, setIsImageError] = useState(false);

    const [titleErrorTxt, setTitleErrorTxt] = useState("");
    const [descriptionErrorTxt, setDescriptionErrorTxt] = useState("");
    const [primaryButtonLableErrorTxt, setPrimaryButtonLableErrorTxt] = useState("");
    const [primaryButtonURLErrorTxt, setPrimaryButtonURLErrorTxt] = useState("");
    const [secondaryButtonLableErrorTxt, setSecondaryButtonLableErrorTxt] = useState("");
    const [secondaryButtonURLErrorTxt, setSecondaryButtonURLErrorTxt] = useState("");
    const [imageErrorTxt, setImageErrorTxt] = useState("");

    const [resetAttachment, setResetAttachment] = useState(false);

    const [showImagePreview, setShowImagePreview] = useState(false);

    const getHomeBannerHandler = async () => {
        try {
            const getHomeBannerReq = await axios.get(`${API_BASE_URL}home/banner`);
            console.log(getHomeBannerReq.data);
            setTitle(getHomeBannerReq.data.title);
            setDescription(getHomeBannerReq.data.description);
            setPrimaryButtonLable(getHomeBannerReq.data.primary_button_lable);
            setPrimaryButtonURL(getHomeBannerReq.data.primary_button_url);
            setSecondaryButtonLable(getHomeBannerReq.data.secondary_button_lable);
            setSecondaryButtonURL(getHomeBannerReq.data.secondary_button_url);
            setImage(getHomeBannerReq.data.media.url);
            setShowImagePreview(true);
        }
        catch (error) {
            if(error.response.status === 404){
                console.log('not found');
            }
        }
    }

    useEffect(() => {
        getHomeBannerHandler();
    }, []);

    const submitEditThisForm = async (e) => {
        e.preventDefault();

        if(title == ''){
            setTitleErrorTxt('Title is required');
            setIsTitleError(true);
            return;
        }
        else{
            setTitleErrorTxt('');
            setIsTitleError(false);
        }

        if(description == ''){
            setDescriptionErrorTxt('Description is required');
            setIsDescriptionError(true);
            return;
        }
        else{
            setDescriptionErrorTxt('');
            setIsDescriptionError(false);
        }

        if(primaryButtonLable == ''){
            setPrimaryButtonLableErrorTxt('Primary Button Lable is required');
            setIsPrimaryButtonLableError(true);
            return;
        }
        else{
            setPrimaryButtonLableErrorTxt('');
            setIsPrimaryButtonLableError(false);
        }

        if(primaryButtonURL == ''){
            setPrimaryButtonURLErrorTxt('Primary Button URL is required');
            setIsPrimaryButtonURLError(true);
            return;
        }
        else{
            setPrimaryButtonURLErrorTxt('');
            setIsPrimaryButtonURLError(false);
        }

        if(secondaryButtonLable == ''){
            setSecondaryButtonLableErrorTxt('Secondary Button Lable is required');
            setIsSecondaryButtonLableError(true);
            return;
        }
        else{
            setSecondaryButtonLableErrorTxt('');
            setIsSecondaryButtonLableError(false);
        }

        if(secondaryButtonURL == ''){
            setSecondaryButtonURLErrorTxt('Secondary Button URL is required');
            setIsSecondaryButtonURLError(true);
            return;
        }
        else{
            setSecondaryButtonURLErrorTxt('');
            setIsSecondaryButtonURLError(false);
        }

        if(imageID == ''){
            setImageErrorTxt('Featured image is required');
            setIsImageError(true);
            return;
        }
        else{
            setImageErrorTxt('');
            setIsImageError(false);
        }

        const data = {
            title: title,
            description: description,
            primary_button_lable: primaryButtonLable,
            primary_button_url: primaryButtonURL,
            secondary_button_lable: secondaryButtonLable,
            secondary_button_url: secondaryButtonURL,
            image: imageID
        }

        try {
            const updateHomeBannerReq = await axios.patch(`${API_BASE_URL}home/banner/edit`, data, config);
            notifySuccess(BANNER_UPDATED);
            navigate('/dashboard/pages/home/banner');
            LOGGER &&  console.log(updateHomeBannerReq);
        }
        catch (error) {
            notifyError(REQUEST_ERROR);
            LOGGER &&  console.log(error);
        }
    }

    const resetForm = () => {
        
    }

    return (
        <Section heading="banner" description={routeText} action={() => navigate("/dashboard/pages/home/banner")} actionLable="back" showAction={true}>
            <PlainContainer styles={{width: '100%', display: 'flex', flexDirection: 'column', position: 'static'}}>
                <form onSubmit={(e) => submitEditThisForm(e)} noValidate>
                    <Form>
                        <TextInput lable="title" isMandatory={true} isError={isTitleError} error={titleErrorTxt}>
                            <input type="text" className="formStepInputTxt capitalize" value={title} placeholder="Please Enter The Title" onChange={(e) => setTitle(e.target.value)} />
                        </TextInput>
                        <TextArea lable="description" rows={3} isMandatory={false} placeholder="Please Enter The Description" value={description} onchange={(data) => setDescription(data)} isError={isDescriptionError} error={descriptionErrorTxt} />
                        <SplitContainer type={`fullwidth`}>
                            <TextInput lable="primary button lable" isMandatory={true} isError={isPrimaryButtonLableError} error={primaryButtonLableErrorTxt}>
                                <input type="text" className="formStepInputTxt capitalize" value={primaryButtonLable} placeholder="Please Enter The Primary Button Lable" onChange={(e) => setPrimaryButtonLable(e.target.value)} />
                            </TextInput>
                            <TextInput lable="primary button URL" isMandatory={true} isError={isPrimaryButtonURLError} error={primaryButtonURLErrorTxt}>
                                <input type="text" className="formStepInputTxt capitalize" value={primaryButtonURL} placeholder="Please Enter The Primary Button URL" onChange={(e) => setPrimaryButtonURL(e.target.value)} />
                            </TextInput>
                        </SplitContainer>
                        <SplitContainer type={`fullwidth`}>
                            <TextInput lable="secondary button lable" isMandatory={true} isError={isSecondaryButtonLableError} error={secondaryButtonLableErrorTxt}>
                                <input type="text" className="formStepInputTxt capitalize" value={secondaryButtonLable} placeholder="Please Enter The Secondary Button Lable" onChange={(e) => setSecondaryButtonLable(e.target.value)} />
                            </TextInput>
                            <TextInput lable="secondary button URL" isMandatory={true} isError={isSecondaryButtonURLError} error={secondaryButtonURLErrorTxt}>
                                <input type="text" className="formStepInputTxt capitalize" value={secondaryButtonURL} placeholder="Please Enter The Secondary Button URL" onChange={(e) => setSecondaryButtonURL(e.target.value)} />
                            </TextInput>
                        </SplitContainer>
                        <FileInput lable="featured image" isMandatory={true} value={imageID} preview={image} showPreview={showImagePreview} onchange={(data) => {setImageID(data); LOGGER &&  console.log(data)}} isError={isImageError} error={imageErrorTxt} reset={resetAttachment} />
                        <FormActions isPrimary={true} isSecondary={true} primaryLable="submit" secondaryLable="reset" secondaryAction={() => resetForm()} />
                    </Form>
                </form>
            </PlainContainer>
        </Section>
    )
}

export default EditHomeBannerPage