import React, { useEffect, useState } from 'react';
const NavSection = ({lable, children, showNav}) => {

    const [lableText, setLableText] = useState(lable);

    useEffect(() => {
        if(showNav === true){
            setLableText(lable);
        }
        else{
            setLableText(lable.charAt(0));
        }
    }, [showNav])

    return (
        <>
            <div className="dashboardNavSection">
                <div className="dashboardNavSectionHeading">
                    <p className={showNav ? "dashboardNavSectionHeadingTxt" : "dashboardNavSectionHeadingTxt txtCenter"}>{lableText}</p>
                </div>
                {children}
            </div>
        </>
    );
};

export default NavSection;
