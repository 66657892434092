import React, { useState, useEffect, useRef, forwardRef} from 'react';
import { useLocation, useNavigate } from "react-router-dom";

import PlainContainer from '../../../../../../components/general/containers/PlainContainer';
import SplitContainer from '../../../../../../components/general/containers/SplitContainer';
import Section from '../../../../../../components/general/containers/Section';
import Form from '../../../../../../components/general/forms/core/Form';
import FormActions from '../../../../../../components/general/forms/core/FormActions';
import TextInput from '../../../../../../components/general/forms/TextInput';
import TextArea from '../../../../../../components/general/forms/TextArea';
import Button from '../../../../../../components/general/forms/Button';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import axios from 'axios';

import { useSelector } from 'react-redux';
import { API_BASE_URL, LOGGER } from '../../../../../../config';
import { notifyError, notifySuccess } from '../../../../../../utils/toastify';
import { REQUEST_ERROR, ABOUT_US_CONTENTS_UPDATED } from '../../../../../../utils/messages';
import FileInput from '../../../../../../components/general/forms/FileInput';

const EditAboutUsContentsPage = () => {
    let navigate = useNavigate();
    const location = useLocation();

    const [routeArray, setRouteArray] = useState(location.pathname.substring(1).split('/'));
    const [routeText, setRouteText] = useState('');

    useEffect(() => {
        let tempRoute = '';
        routeArray.map(route => {
            tempRoute = tempRoute.concat(`${route} / `);
            tempRoute = tempRoute.replace('-', ' ');
        });
        setRouteText(removeLastChars(tempRoute, 3));
    }, []);

    let removeLastChars = (str, chars) => {
        return str.substring(0, str.length - chars);
    }

    const admin = useSelector((state) => state.admin.value);

    const config = {
        headers: {
        'Authorization': admin.access_token
        }
    }

    const [descriptionTop, setDescriptionTop] = useState('');
    const [descriptionBottom, setDescriptionBottom] = useState('');
    const [image, setImage] = useState('');
    const [imageID, setImageID] = useState('');

    const [isDescriptionTopError, setIsDescriptionTopError] = useState(false);
    const [isDescriptionBottomError, setIsDescriptionBottomError] = useState(false);
    const [isImageError, setIsImageError] = useState(false);

    const [descriptionTopErrorTxt, setDescriptionTopErrorTxt] = useState("");
    const [descriptionBottomErrorTxt, setDescriptionBottomErrorTxt] = useState("");
    const [imageErrorTxt, setImageErrorTxt] = useState("");

    const [resetAttachment, setResetAttachment] = useState(false);

    const [showImagePreview, setShowImagePreview] = useState(false);

    const getAboutUsContentsHandler = async () => {
        try {
            const getAboutUsContentsReq = await axios.get(`${API_BASE_URL}about-us/content`);
            console.log(getAboutUsContentsReq);
            setDescriptionTop(getAboutUsContentsReq.data.description_top);
            setDescriptionBottom(getAboutUsContentsReq.data.description_bottom);
            setImage(getAboutUsContentsReq.data.featured_image?.url);
            setImageID(getAboutUsContentsReq.data.featured_image?.id);
            setShowImagePreview(true);
        }
        catch (error) {
            if(error.response.status === 404){
                console.log('not found');
            }
        }
    }

    useEffect(() => {
        getAboutUsContentsHandler();
    }, []);

    const submitEditThisForm = async (e) => {
        e.preventDefault();

        if(descriptionTop == ''){
            setDescriptionTopErrorTxt('Description top is required');
            setIsDescriptionTopError(true);
            return;
        }
        else{
            setDescriptionTopErrorTxt('');
            setIsDescriptionTopError(false);
        }

        if(descriptionBottom == ''){
            setDescriptionBottomErrorTxt('Description bottom is required');
            setIsDescriptionBottomError(true);
            return;
        }
        else{
            setDescriptionBottomErrorTxt('');
            setIsDescriptionBottomError(false);
        }

        if(imageID == ''){
            setImageErrorTxt('Featured image is required');
            setIsImageError(true);
            return;
        }
        else{
            setImageErrorTxt('');
            setIsImageError(false);
        }

        const data = {
            description_top: descriptionTop,
            description_bottom: descriptionBottom,
            image: imageID
        }

        try {
            const updateAboutUsContentsReq = await axios.patch(`${API_BASE_URL}about-us/content/edit`, data, config);
            notifySuccess(ABOUT_US_CONTENTS_UPDATED);
            navigate('/dashboard/pages/about-us/content');
            LOGGER &&  console.log(updateAboutUsContentsReq);
        }
        catch (error) {
            notifyError(REQUEST_ERROR);
            LOGGER &&  console.log(error);
        }
    }

    const resetForm = () => {
        
    }

    return (
        <Section heading="Contents" description={routeText} action={() => navigate("/dashboard/pages/about-us/content")} actionLable="back" showAction={true}>
            <PlainContainer styles={{width: '100%', display: 'flex', flexDirection: 'column', position: 'static'}}>
                <form onSubmit={(e) => submitEditThisForm(e)} noValidate>
                    <Form>
                        <TextInput lable="description top" isMandatory={true} isError={isDescriptionTopError} error={descriptionTopErrorTxt}>
                            <ReactQuill style={{height: '200px', paddingBottom: '45px'}} theme="snow" value={descriptionTop} onChange={setDescriptionTop} />
                        </TextInput>
                        <TextInput lable="description bottom" isMandatory={true} isError={isDescriptionBottomError} error={descriptionBottomErrorTxt}>
                            <ReactQuill style={{height: '200px', paddingBottom: '45px'}} theme="snow" value={descriptionBottom} onChange={setDescriptionBottom} />
                        </TextInput>
                        <FileInput lable="featured image" isMandatory={true} value={imageID} preview={image} showPreview={showImagePreview} onchange={(data) => {setImageID(data); LOGGER &&  console.log(data)}} isError={isImageError} error={imageErrorTxt} reset={resetAttachment} />
                        <FormActions isPrimary={true} isSecondary={true} primaryLable="submit" secondaryLable="reset" secondaryAction={() => resetForm()} />
                    </Form>
                </form>
            </PlainContainer>
        </Section>
    )
}

export default EditAboutUsContentsPage