import React, { useState } from 'react';
import Footer from '../components/general/Footer';
import Form from '../components/general/forms/core/Form';
import FormActions from '../components/general/forms/core/FormActions';
import TextInput from '../components/general/forms/TextInput';
import Text from '../components/general/typography/Text';
import { validateEmail, validatePassword } from '../utils/validation';

import { API_BASE_URL, JWT_SECRET, LOGGER, BASE_URL } from '../config';

import { useDispatch, useSelector } from 'react-redux';
import { login } from '../reducers/admin';

import { useNavigate } from 'react-router-dom';

import { isExpired, decodeToken, useJwt } from "react-jwt";
import verifyToken from '../verify-jwt';

import axios from 'axios';
import PlainStep from '../components/general/forms/PlainStep';

import { notifyError, notifySuccess } from '../utils/toastify';
import { ADMIN_LOGIN_FAILED, ADMIN_LOGIN_SUCCESS, REQUEST_ERROR } from '../utils/messages';
import { ToastContainer } from 'react-toastify';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const admin = useSelector((state) => state.admin.value);

  const [emailID, setEmailID] = useState("");
  const [password, setPassword] = useState("");

  const [isEmailError, setIsEmailError] = useState(false);
  const [isPasswordError, setIsPasswordError] = useState(false);

  const [emailErrorTxt, setEmailErrorTxt] = useState("");
  const [passwordErrorTxt, setPasswordErrorTxt] = useState("");

  const [isInvalidAdmin, setIsInvalidAdmin] = useState(false);
  const [isRoleVerified, setRoleVerified] = useState(true);

  const [loading, setLoading] = useState(false);

  const submitLoginForm = async (e) => {
    e.preventDefault();
    setLoading(true); 

    console.log(emailID);
    console.log(password);

    let isEmailValid = validateEmail(emailID);
    if(isEmailValid === false){
      setIsEmailError(true);
      setEmailErrorTxt("Enter a Valid Email ID");
      return;
    }
    else{
      setIsEmailError(false);
      setEmailErrorTxt("");
    }


    let isPasswordValid = validatePassword(password);
    if(isPasswordValid === false){
      setIsPasswordError(true);
      setPasswordErrorTxt("Enter a Valid Password");
      return;
    }
    else{
      setIsPasswordError(false);
      setPasswordErrorTxt("");
    }

    const admin = {
      email_id: emailID,
      password: password
    }

    axios.post(`${API_BASE_URL}admin/login`, admin).then(res => {
      const status = res.status;
      if (status === 200) {
        const data = res.data;
        const {role} = decodeToken(data.access_token, JWT_SECRET);
        if (role === 'ADMIN' || role === 'SUPER_ADMIN' || role === 'STORE_ADMIN') {
          dispatch(login({ id: data.admin_id, name: data.name, role: role, email_id: data.email_id, access_token: data.access_token, refresh_token: data.refresh_token, profile_pic: data?.profile_pic, is_logged_in: true }));
          navigate('/dashboard');
        } 
        else {
          setRoleVerified(false);
        }
        setLoading(false);
      }
      else {
        setLoading(false);
        setIsInvalidAdmin(true);
        notifyError(ADMIN_LOGIN_FAILED);
      }
    }).catch(err => {
      notifyError(ADMIN_LOGIN_FAILED);
      setLoading(false);
      setIsInvalidAdmin(true);
      LOGGER && console.log(err);
    });
  }

  const resetForm = () => {
    setEmailID("");
    setPassword("");
    setIsEmailError(false);
    setIsPasswordError(false);
    setEmailErrorTxt("");
    setPasswordErrorTxt("");
  }

  return (
    <>
      <div className="login">
        <div className="loginContent">
          <div className="loginContentInner">
            <div className="loginContentLogo hCenter">
              <div className="loginContentLogoImage center">
                <div className="loginContentLogoImageInner">
                  <img src={`${BASE_URL}images/logo-login.png`} className='loginContentLogoImg' />
                </div>
              </div>
            </div>
            <div className="loginFormContent">
              <div className="loginFormContentHeading hCenter">
                <Text variant="page-heading" type="default">login</Text>
              </div>

              <form onSubmit={(e) => submitLoginForm(e)} noValidate>
                <Form paddingTop={true}>
                  <TextInput lable="email id" isMandatory={true} isError={isEmailError} error={emailErrorTxt} >
                    <input type="email" onChange={(e) => setEmailID(e.target.value)} className="formStepInputTxt" placeholder="Please Enter Your Email ID" value={emailID} />
                  </TextInput>
                  <TextInput lable="password" isMandatory={true} isError={isPasswordError} error={passwordErrorTxt}>
                    <input type="password" className="formStepInputTxt passwordTxt" value={password} placeholder="Please Enter Your Password" onChange={(e) => setPassword(e.target.value)} />
                  </TextInput>
                  <FormActions isPrimary={true} isSecondary={true} primaryLable="submit" secondaryLable="reset" secondaryAction={resetForm} />
                </Form>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <ToastContainer />
    </>
  );
};

export default Login;
