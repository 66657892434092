import React, { useState, useEffect, useRef, forwardRef} from 'react';
import { useLocation, useNavigate } from "react-router-dom";

import PlainContainer from '../../../../../../components/general/containers/PlainContainer';
import SplitContainer from '../../../../../../components/general/containers/SplitContainer';
import Section from '../../../../../../components/general/containers/Section';
import Form from '../../../../../../components/general/forms/core/Form';
import FormActions from '../../../../../../components/general/forms/core/FormActions';
import TextInput from '../../../../../../components/general/forms/TextInput';
import FileInput from '../../../../../../components/general/forms/FileInput';
import Button from '../../../../../../components/general/forms/Button';

import axios from 'axios';

import { useSelector } from 'react-redux';
import { API_BASE_URL, LOGGER } from '../../../../../../config';
import { notifyError, notifySuccess } from '../../../../../../utils/toastify';
import { IMAGE_UPLOADED, REQUEST_ERROR } from '../../../../../../utils/messages';

const EditHeaderLogoPage = () => {
    let navigate = useNavigate();
    const location = useLocation();

    const [routeArray, setRouteArray] = useState(location.pathname.substring(1).split('/'));
    const [routeText, setRouteText] = useState('');

    useEffect(() => {
        let tempRoute = '';
        routeArray.map(route => {
            tempRoute = tempRoute.concat(`${route} / `);
            tempRoute = tempRoute.replace('-', ' ');
        });
        setRouteText(removeLastChars(tempRoute, 3));
    }, []);

    let removeLastChars = (str, chars) => {
        return str.substring(0, str.length - chars);
    }

    const admin = useSelector((state) => state.admin.value);

    const config = {
        headers: {
        'Authorization': admin.access_token
        }
    }

    const [logo, setLogo] = useState('');
    const [logoID, setLogoID] = useState('');

    const [isLogoError, setIsLogoError] = useState(false);

    const [logoErrorTxt, setLogoErrorTxt] = useState("");

    const [resetAttachment, setResetAttachment] = useState(false);

    const [showLogoPreview, setShowLogoPreview] = useState(false);

    const getHeaderLogoHandler = async () => {
        try {
            const getHeaderLogoReq = await axios.get(`${API_BASE_URL}header/logo`);
            console.log(getHeaderLogoReq.data);
            setLogo(getHeaderLogoReq.data.url);
            setShowLogoPreview(true);
        }
        catch (error) {
            if(error.response.status === 404){
                console.log('not found');
            }
        }
    }

    useEffect(() => {
        getHeaderLogoHandler();
    }, []);

    const submitEditThisForm = async (e) => {
        e.preventDefault();

        if(logoID == ''){
            setLogoErrorTxt('Logo is required');
            setIsLogoError(true);
            return;
        }
        else{
            setLogoErrorTxt('');
            setIsLogoError(false);
        }

        const data = {
            logo: logoID,
        }

        try {
            const updateHeaderLogoReq = await axios.patch(`${API_BASE_URL}header/logo/edit`, data, config);
            notifySuccess(IMAGE_UPLOADED);
            navigate('/dashboard/general/header/logo');
            LOGGER &&  console.log(updateHeaderLogoReq);
        }
        catch (error) {
            notifyError(REQUEST_ERROR);
            LOGGER &&  console.log(error);
        }
    }

    const resetForm = () => {
        
    }

    return (
        <Section heading="logo" description={routeText} action={() => navigate("/dashboard/general/header/logo")} actionLable="back" showAction={true}>
            <PlainContainer styles={{width: '100%', display: 'flex', flexDirection: 'column', position: 'static'}}>
                <form onSubmit={(e) => submitEditThisForm(e)} noValidate>
                    <Form>
                        <FileInput lable="logo" isMandatory={true} value={logoID} preview={logo} showPreview={showLogoPreview} onchange={(data) => {setLogoID(data); LOGGER &&  console.log(data)}} isError={isLogoError} error={logoErrorTxt} reset={resetAttachment} />
                        <FormActions isPrimary={true} isSecondary={true} primaryLable="submit" secondaryLable="reset" secondaryAction={() => resetForm()} />
                    </Form>
                </form>
            </PlainContainer>
        </Section>
    )
}

export default EditHeaderLogoPage