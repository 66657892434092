/**
 * DEV DEPENDENCIES
 */

//  export const API_BASE_URL = "http://localhost:4040/api/";
//  export const BASE_URL = "/";
//  export const SOCKET_URL = "http://localhost:4040";
//  export const BASE_LINK = "/";
//  export const SOCKET_PATH = "/";
//  export const GOOGLE_MAPS_API_KEY = "AIzaSyAEa1uvbs1m8u4OsFnEIqVwY2Ho3vDJhWY";


/**
 * PROD DEPENDENCIES
 */

// export const API_BASE_URL = "http://139.59.32.11/api/";
// export const BASE_URL = "/";
// export const SOCKET_URL = "http://139.59.32.11/api/";
// export const BASE_LINK = "/admin/";
// export const SOCKET_PATH = "/socket.io";
// export const GOOGLE_MAPS_API_KEY = "AIzaSyAEa1uvbs1m8u4OsFnEIqVwY2Ho3vDJhWY";


// hemendra 
export const API_BASE_URL = "https://api.futurasink.com/";
export const BASE_URL = "/";
export const SOCKET_URL = "https://api.futurasink.com/";
export const BASE_LINK = "/admin/";
export const SOCKET_PATH = "/socket.io";
export const GOOGLE_MAPS_API_KEY = "AIzaSyAEa1uvbs1m8u4OsFnEIqVwY2Ho3vDJhWY";





/**
 * COMMON FOR BOTH DEV & PROD
 */
export const BRAND_NAME = "Futura Sinks";
export const JWT_SECRET = "sinksLive";
export const FIRST_PAGE = 1;
export const DEFAULT_PAGE_SIZE = 10;
export const LOGGER = process.env.NODE_ENV === 'development';
export const ROLES = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  ADMIN: 'ADMIN',
  STORE_ADMIN: 'STORE_ADMIN'
}