import React, { useState } from 'react';
import FormButtons from '../FormButtons';

const FormActions = ({isPrimary, isSecondary, primaryLable, secondaryLable, primaryAction, secondaryAction}) => {

    const [showPrimary, setShowPrimary] = useState(isPrimary);
    const [showSecondary, setShowSecondary] = useState(isSecondary);
    const [primaryLableTxt, setPrimaryLableTxt] = useState(primaryLable);
    const [secondaryLableTxt, setSecondaryLableTxt] = useState(secondaryLable);

    return (
        <>
            <div className="formStep formActions">
                {
                    showSecondary === true
                    ?
                        <FormButtons isPrimary={false} action={secondaryAction}>{secondaryLableTxt}</FormButtons>
                    :
                        <></>
                }
                {
                    showPrimary === true
                    ?
                        <FormButtons isPrimary={true} action={primaryAction}>{primaryLableTxt}</FormButtons>
                    :
                        <></>
                }
            </div>
        </>
    );
};

export default FormActions;
