import React, { useState, useEffect, useRef, forwardRef} from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';

import PlainContainer from '../../../../../../components/general/containers/PlainContainer';
import SplitContainer from '../../../../../../components/general/containers/SplitContainer';
import Section from '../../../../../../components/general/containers/Section';
import Form from '../../../../../../components/general/forms/core/Form';
import FormActions from '../../../../../../components/general/forms/core/FormActions';
import TextInput from '../../../../../../components/general/forms/TextInput';
import TextArea from '../../../../../../components/general/forms/TextArea';
import Button from '../../../../../../components/general/forms/Button';

import axios from 'axios';

import { useSelector } from 'react-redux';
import { API_BASE_URL, LOGGER } from '../../../../../../config';
import { notifyError, notifySuccess } from '../../../../../../utils/toastify';
import { REQUEST_ERROR, OPENING_CREATED } from '../../../../../../utils/messages';

const EditBlogCategoryPage = () => {
    let navigate = useNavigate();
    const location = useLocation();
    let { blog_id } = useParams(); 

    const [routeArray, setRouteArray] = useState(location.pathname.substring(1).split('/'));
    const [routeText, setRouteText] = useState('');

    useEffect(() => {
        let tempRoute = '';
        routeArray.map(route => {
            tempRoute = tempRoute.concat(`${route} / `);
            tempRoute = tempRoute.replace('-', ' ');
        });
        setRouteText(removeLastChars(tempRoute, 3));
    }, []);

    let removeLastChars = (str, chars) => {
        return str.substring(0, str.length - chars);
    }

    const admin = useSelector((state) => state.admin.value);

    const config = {
        headers: {
        'Authorization': admin.access_token
        }
    }

    const [name, setName] = useState('');
    const [title, setTitle] = useState('');
    const [keywords, setKeywords] = useState('');
    const [description, setDescription] = useState('');

    const [isNameError, setIsNameError] = useState(false);
    const [isTitleError, setIsTitleError] = useState(false);
    const [isKeywordsError, setIsKeywordsError] = useState(false);
    const [isDescriptionError, setIsDescriptionError] = useState(false);

    const [nameErrorTxt, setNameErrorTxt] = useState("");
    const [titleErrorTxt, setTitleErrorTxt] = useState("");
    const [keywordsErrorTxt, setKeywordsErrorTxt] = useState("");
    const [descriptionErrorTxt, setDescriptionErrorTxt] = useState("");

    const getThisBlogCategoryHandler = async () => {
        try {
            const getThisBlogCategoryReq = await axios.get(`${API_BASE_URL}blog/category/${blog_id}`);
            console.log(getThisBlogCategoryReq.data);
            setName(getThisBlogCategoryReq.data.name);
            setTitle(getThisBlogCategoryReq.data.seo.title);
            setDescription(getThisBlogCategoryReq.data.seo.description);
            setKeywords(getThisBlogCategoryReq.data.seo.keywords);
        }
        catch (error) {
            if(error.response.status === 404){
                console.log('not found');
            }
        }
    }

    useEffect(() => {
        getThisBlogCategoryHandler();
    }, []);

    const submitEditThisForm = async (e) => {
        e.preventDefault();

        if(title == ''){
            setTitleErrorTxt('Title is required');
            setIsTitleError(true);
            return;
        }
        else{
            setTitleErrorTxt('');
            setIsTitleError(false);
        }

        if(name == ''){
            setNameErrorTxt('Name is required');
            setIsNameError(true);
            return;
        }
        else{
            setNameErrorTxt('');
            setIsNameError(false);
        }

        const data = {
            title: title,
            keywords: keywords,
            description: description,
            name: name
        }

        try {
            const updateBlogCategoryReq = await axios.patch(`${API_BASE_URL}blog/category/${blog_id}`, data, config);
            notifySuccess(OPENING_CREATED);
            navigate('/dashboard/pages/blogs/categories');
            LOGGER &&  console.log(updateBlogCategoryReq);
        }
        catch (error) {
            notifyError(REQUEST_ERROR);
            LOGGER &&  console.log(error);
        }
    }

    const resetForm = () => {
        
    }

    return (
        <Section heading="Categories" description={routeText} action={() => navigate("/dashboard/pages/blogs/categories")} actionLable="back" showAction={true}>
            <PlainContainer styles={{width: '100%', display: 'flex', flexDirection: 'column', position: 'static'}}>
                <form onSubmit={(e) => submitEditThisForm(e)} noValidate>
                    <Form>
                        
                        <SplitContainer type={`fullwidth`}>
                            <TextInput lable="name" isMandatory={true} isError={isNameError} error={nameErrorTxt}>
                                <input type="text" className="formStepInputTxt capitalize" value={name} placeholder="Please Enter The Category Name" onChange={(e) => setName(e.target.value)} />
                            </TextInput>
                            <TextInput lable="SEO Title" isMandatory={true} isError={isTitleError} error={titleErrorTxt}>
                                <input type="text" className="formStepInputTxt capitalize" value={title} placeholder="Please Enter The SEO Title" onChange={(e) => setTitle(e.target.value)} />
                            </TextInput>
                        </SplitContainer>
                        <TextArea lable="SEO description" rows={3} isMandatory={false} placeholder="Please Enter The SEO Description" value={description} onchange={(data) => setDescription(data)} isError={isDescriptionError} error={descriptionErrorTxt} />
                        <TextArea lable="SEO keywords" rows={3} isMandatory={false} placeholder="Please Enter The SEO Keywords" value={keywords} onchange={(data) => setKeywords(data)} isError={isKeywordsError} error={keywordsErrorTxt} />
                        <FormActions isPrimary={true} isSecondary={true} primaryLable="submit" secondaryLable="reset" secondaryAction={() => resetForm()} />
                    </Form>
                </form>
            </PlainContainer>
        </Section>
    )
}

export default EditBlogCategoryPage