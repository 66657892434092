import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Section from "../../../../../components/general/containers/Section";
import PlainContainer from "../../../../../components/general/containers/PlainContainer";
import DetailSection from "../../../../../components/dashboard/common/detailSection/DetailSection";
import DetailSectionContent from "../../../../../components/dashboard/common/detailSection/DetailSectionContent";
import DetailSectionRow from "../../../../../components/dashboard/common/detailSection/DetailSectionRow";
import { notifySuccess } from "../../../../../utils/toastify";
import { API_BASE_URL } from "../../../../../config";
import { OPENING_DELETED } from "../../../../../utils/messages";
import { useSelector } from "react-redux";


const HomePopupAd = () => {
    const admin = useSelector((state) => state.admin.value);

    let navigate = useNavigate();
    const location = useLocation();

    const [routeArray, setRouteArray] = useState(location.pathname.substring(1).split('/'));
    const [routeText, setRouteText] = useState('');
    const [popupAdData, setPopupAdData] = useState({});
    useEffect(() => {
        let tempRoute = '';
        routeArray.map(route => {
            tempRoute = tempRoute.concat(`${route} / `);
            tempRoute = tempRoute.replace('-', ' ');
        });
        setRouteText(removeLastChars(tempRoute, 3));
    }, []);

    const removeLastChars = (str, chars) => {
        return str.substring(0, str.length - chars);
    }

    const config = {
        headers: {
            'Authorization': admin.access_token
        }
    }

    const fetchSlidersData = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}home/popupad/get`);
            console.log(response.data)
            if (response.data) {
                setPopupAdData(response.data);
            }else setPopupAdData({})
        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        fetchSlidersData();
    }, []);



    const deletePopup = async (id) => {
        try {
            const deleteThisadslideReq = await axios.delete(`${API_BASE_URL}home/popupad/delete`, config);
            console.log(deleteThisadslideReq.data);
            await fetchSlidersData();
            notifySuccess(OPENING_DELETED);
        } catch (error) {
            if (error.response.status === 404) {
                console.log('not found');
            }
        }
    }


    return (
        <Section heading="Pop up ad" description={routeText} action={() => navigate("/dashboard/pages/home")} actionLable="back" showAction={true}>
            <PlainContainer type="fullwidth">
                {
                    popupAdData.title && popupAdData.description && popupAdData.seoUrl && popupAdData.image ?
                        <DetailSection lable="popup" hasPrimaryAction={true} lablePrimaryAction={`edit`} primaryAction={() => navigate("/dashboard/pages/home/popup-ad/edit")} hasSecondaryAction={true} lableSecondaryAction={`delete`} secondaryAction={deletePopup}>
                            <DetailSectionContent hasPadding={true}>
                                <DetailSectionRow lable={`title`} value={popupAdData.title} isImage={false} isIcon={false} hasChildren={false} />
                                <DetailSectionRow lable={`description`} value={popupAdData.description} isImage={false} isIcon={false} hasChildren={false} />
                                <DetailSectionRow lable={`path`} value={popupAdData.seoUrl} isImage={false} isIcon={false} hasChildren={false} />
                                <DetailSectionRow lable={`image`} value={popupAdData.image.url} isImage={true} isIcon={false} hasChildren={false} />

                            </DetailSectionContent>

                        </DetailSection>
                        : <DetailSection lable={`Not availble Add new `} hasPrimaryAction={true} lablePrimaryAction={`Add`} primaryAction={() => navigate("/dashboard/pages/home/popup-ad/edit")}> </DetailSection>
                }
            </PlainContainer>
        </Section>
    );
};

export default HomePopupAd