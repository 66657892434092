import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import DetailSection from '../../../../../components/dashboard/common/detailSection/DetailSection';
import DetailSectionContent from '../../../../../components/dashboard/common/detailSection/DetailSectionContent';
import DetailSectionRow from '../../../../../components/dashboard/common/detailSection/DetailSectionRow';

import Table from '../../../../../components/dashboard/common/table/Table';
import Heading from '../../../../../components/dashboard/common/table/Heading';
import HeadingItem from '../../../../../components/dashboard/common/table/HeadingItem';
import TableRow from '../../../../../components/dashboard/common/table/TableRow';
import RowItem from '../../../../../components/dashboard/common/table/RowItem';
import RowIcon from '../../../../../components/dashboard/common/table/RowIcon';
import TableButton from '../../../../../components/dashboard/common/table/TableButton';

import NoDataContainer from '../../../../../components/general/containers/NoDataContainer';
import PlainContainer from '../../../../../components/general/containers/PlainContainer';
import SplitContainer from '../../../../../components/general/containers/SplitContainer';
import Section from '../../../../../components/general/containers/Section';

import SelectInput from '../../../../../components/general/forms/SelectInput';

import { API_BASE_URL, LOGGER } from '../../../../../config';
import { notifyError, notifySuccess } from '../../../../../utils/toastify';

const ProductsListPage = () => {
    const admin = useSelector((state) => state.admin.value);
    let navigate = useNavigate();
    const location = useLocation();
    const [routeArray, setRouteArray] = useState(location.pathname.substring(1).split('/'));
    const [routeText, setRouteText] = useState('');
    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [category, setCategory] = useState(0);
    const [showProducts, setShowProducts] = useState(false);
    const [showNoProductsFound, setShowNoProductsFound] = useState(false);
  
    useEffect(() => {
        let tempRoute = '';
        routeArray.map(route => {
            tempRoute = tempRoute.concat(`${route} / `);
            tempRoute = tempRoute.replace('-', ' ');
        });
        setRouteText(removeLastChars(tempRoute, 3));
    }, []);
  
    let removeLastChars = (str, chars) => {
        return str.substring(0, str.length - chars);
    }
  
    const config = {
        headers: {
            'Authorization': admin.access_token
        }
    }

    const getProductCategoriesHandler = async () => {
        try {
            const getProductCategoriesReq = await axios.get(`${API_BASE_URL}product/category`);
            console.log(getProductCategoriesReq.data);
            if(getProductCategoriesReq.data.length != 0){

                let temp_categories = [];

                getProductCategoriesReq.data.map(category => {
                    let data = {
                        id: category.id,
                        name: category.category.name
                    }
                    temp_categories.push(data);
                });

                let category_id = temp_categories[0].id;
                console.log(category_id);

                setCategories(temp_categories);
                setCategory(category_id);
            }
            else{
                setShowNoProductsFound(true);
            }
        }
        catch (error) {
            if(error.response.status === 404){
                console.log('not found');
            }
        }
    }

    useEffect(() => {
        getProductCategoriesHandler();
    }, []);

    const getProductsHandler = async () => {
        try {
            const getProductsReq = await axios.get(`${API_BASE_URL}product/product/category/${category}`);
            console.log(getProductsReq.data);
            if(getProductsReq.data.length != 0){
                setProducts(getProductsReq.data);
                setShowNoProductsFound(false);
                setShowProducts(true);
            }
            else{
                showProducts([]);
                setShowProducts(false);
                setShowNoProductsFound(true);
            }
        }
        catch (error) {
            if(error.response.status === 404){
                console.log('not found');
            }
        }
    }

    useEffect(() => {
        console.log(category);
        if(category != 0){
            getProductsHandler();
        }
        else{
            setProducts([]);
            setShowNoProductsFound(true);
            setShowProducts(false);
        }
    }, [category]);

    useEffect(() => {
        console.log(products);
    }, [products]);

    const deleteItem = async (item_id) => {

    }

    return (
        <>
            <Section heading="Products" description={routeText} action={() => navigate("/dashboard/pages/products/products/create")} actionLable="add new" showAction={true}>
                <SplitContainer type="fullwidth">
                    <SelectInput lable="category" capitalize={true} isMandatory={false} placeholder="Please Select The Category" value={category} onchange={(data) => setCategory(data)} isError={false} error={``} options={categories} />
                </SplitContainer>

                {
                    showProducts 
                    ?
                    <PlainContainer type="fullwidth">
                        <Table>
                            <Heading>
                                <HeadingItem size="1" isIcon={false} icon="" lable={`sl no`} />
                                <HeadingItem size="3" isIcon={false} icon="" lable={`category`} />
                                <HeadingItem size="4" isIcon={false} icon="" lable={`name`} />
                                <HeadingItem size="1" isIcon={false} icon="" lable={``} />
                                <HeadingItem size="3" isIcon={false} icon="" lable={`action`} />
                            </Heading>
                            <PlainContainer type="fullwidth">
                            {
                                products.map((product, i) => {
                                    return (
                                        <TableRow key={product.id}>
                                            <RowItem size="1" lable={i+1} isCenter={true} hasChildren={false}></RowItem>
                                            <RowItem size="3" lable={product.product_category.category.name} isCenter={false} hasChildren={false}></RowItem>
                                            <RowItem size="4" lable={product.product.name} isCenter={false} hasChildren={false}></RowItem>
                                            <RowItem size="1" isCenter={false} hasChildren={true}>
                                                <RowIcon name="fa-solid fa-eye" action={()=> navigate(`/dashboard/pages/products/products/details/${product.id}`)} styles={{cursor: 'pointer'}} />
                                            </RowItem>
                                            <RowItem size="3" lable="3" isCenter={false} hasChildren={true}>
                                                <TableButton styles={{width: 'calc(50% - 5px)', marginRight: '10px'}} action={() => navigate(`edit/${product.id}`)} type="primary">edit</TableButton>
                                                <TableButton styles={{width: 'calc(50% - 5px)'}} action={() => deleteItem(product.id)} type="danger">delete</TableButton>
                                            </RowItem>
                                        </TableRow>
                                    )
                                })
                            }
                            </PlainContainer>
                        </Table>
                    </PlainContainer>
                    :
                    <></>
                }
                
            </Section>
        </>
    )
}

export default ProductsListPage

