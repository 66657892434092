import { createSlice } from "@reduxjs/toolkit";

const initialValue = {id: '', name: '', role: '', email_id: '', access_token: '', refresh_token: '', profile_pic: '', is_logged_in: false};

export const adminSlice = createSlice({
    name: 'admin',
    initialState: {
        value: initialValue
    },
    reducers: {
        login: (state, action) => {
            state.value = action.payload;
        },
        logout: (state) => {
            state.value = initialValue;
        }
    }
});

export const { login, logout } = adminSlice.actions;

export default adminSlice.reducer;