import React, { useState, useEffect, useRef, forwardRef} from 'react';
import { useLocation, useNavigate } from "react-router-dom";

import PlainContainer from '../../../../../../components/general/containers/PlainContainer';
import SplitContainer from '../../../../../../components/general/containers/SplitContainer';
import Section from '../../../../../../components/general/containers/Section';
import Form from '../../../../../../components/general/forms/core/Form';
import FormActions from '../../../../../../components/general/forms/core/FormActions';
import TextInput from '../../../../../../components/general/forms/TextInput';
import TextArea from '../../../../../../components/general/forms/TextArea';
import FileInput from '../../../../../../components/general/forms/FileInput';
import Button from '../../../../../../components/general/forms/Button';

import axios from 'axios';

import { useSelector } from 'react-redux';
import { API_BASE_URL, LOGGER } from '../../../../../../config';
import { notifyError, notifySuccess } from '../../../../../../utils/toastify';
import { HOME_ABOUT_US_UPDATED, REQUEST_ERROR } from '../../../../../../utils/messages';

const EditHomeAboutUsPage = () => {
    let navigate = useNavigate();
    const location = useLocation();

    const [routeArray, setRouteArray] = useState(location.pathname.substring(1).split('/'));
    const [routeText, setRouteText] = useState('');

    useEffect(() => {
        let tempRoute = '';
        routeArray.map(route => {
            tempRoute = tempRoute.concat(`${route} / `);
            tempRoute = tempRoute.replace('-', ' ');
        });
        setRouteText(removeLastChars(tempRoute, 3));
    }, []);

    let removeLastChars = (str, chars) => {
        return str.substring(0, str.length - chars);
    }

    const admin = useSelector((state) => state.admin.value);

    const config = {
        headers: {
        'Authorization': admin.access_token
        }
    }

    const [sectionHeading, setSectionHeading] = useState('');
    const [headingBold, setHeadingBold] = useState('');
    const [headingRegular, setHeadingRegular] = useState('');
    const [description, setDescription] = useState('');
    const [missionStatement, setMissionStatement] = useState('');
    const [quote, setQuote] = useState('');
    const [autherName, setAutherName] = useState('');
    const [autherDesignation, setAutherDesignation] = useState('');
    const [image, setImage] = useState('');
    const [imageID, setImageID] = useState('');

    const [isSectionHeadingError, setIsSectionHeadingError] = useState(false);
    const [isHeadingBoldError, setIsHeadingBoldError] = useState(false);
    const [isHeadingRegularError, setIsHeadingRegularError] = useState(false);
    const [isDescriptionError, setIsDescriptionError] = useState(false);
    const [isMissionStatementError, setIsMissionStatementError] = useState(false);
    const [isQuoteError, setIsQuoteError] = useState(false);
    const [isAutherNameError, setIsAutherNameError] = useState(false);
    const [isAutherDesignationError, setIsAutherDesignationError] = useState(false);
    const [isImageError, setIsImageError] = useState(false);

    const [sectionHeadingErrorTxt, setSectionHeadingErrorTxt] = useState("");
    const [headingBoldErrorTxt, setHeadingBoldErrorTxt] = useState("");
    const [headingRegularErrorTxt, setHeadingRegularErrorTxt] = useState("");
    const [descriptionErrorTxt, setDescriptionErrorTxt] = useState("");
    const [missionStatementErrorTxt, setMissionStatementErrorTxt] = useState("");
    const [quoteErrorTxt, setQuoteErrorTxt] = useState("");
    const [autherNameErrorTxt, setAutherNameErrorTxt] = useState("");
    const [autherDesignationErrorTxt, setAutherDesignationErrorTxt] = useState("");
    const [imageErrorTxt, setImageErrorTxt] = useState("");

    const [resetAttachment, setResetAttachment] = useState(false);

    const [showImagePreview, setShowImagePreview] = useState(false);

    const getHomeAboutUsHandler = async () => {
        try {
            const getHomeAboutUsReq = await axios.get(`${API_BASE_URL}home/about-us`);
            console.log(getHomeAboutUsReq.data);
            setSectionHeading(getHomeAboutUsReq.data.section_heading);
            setHeadingBold(getHomeAboutUsReq.data.heading_bold);
            setHeadingRegular(getHomeAboutUsReq.data.heading_regular);
            setDescription(getHomeAboutUsReq.data.description);
            setMissionStatement(getHomeAboutUsReq.data.mission_statement);
            setQuote(getHomeAboutUsReq.data.quote);
            setAutherName(getHomeAboutUsReq.data.quote_author);
            setAutherDesignation(getHomeAboutUsReq.data.author_designation);
            setImage(getHomeAboutUsReq.data.featured_image.url);
            setShowImagePreview(true);
        }
        catch (error) {
            if(error.response.status === 404){
                console.log('not found');
            }
        }
    }

    useEffect(() => {
        getHomeAboutUsHandler();
    }, []);

    const submitEditThisForm = async (e) => {
        e.preventDefault();

        if(sectionHeading == ''){
            setSectionHeadingErrorTxt('Section heading is required');
            setIsSectionHeadingError(true);
            return;
        }
        else{
            setSectionHeadingErrorTxt('');
            setIsSectionHeadingError(false);
        }

        if(headingBold == ''){
            setHeadingBoldErrorTxt('Heading bold is required');
            setIsHeadingBoldError(true);
            return;
        }
        else{
            setHeadingBoldErrorTxt('');
            setIsHeadingBoldError(false);
        }

        if(headingRegular == ''){
            setHeadingRegularErrorTxt('Heading regular is required');
            setIsHeadingRegularError(true);
            return;
        }
        else{
            setHeadingRegularErrorTxt('');
            setIsHeadingRegularError(false);
        }

        if(description == ''){
            setDescriptionErrorTxt('Heading regular is required');
            setIsDescriptionError(true);
            return;
        }
        else{
            setDescriptionErrorTxt('');
            setIsDescriptionError(false);
        }

        if(missionStatement == ''){
            setMissionStatementErrorTxt('Mission statement is required');
            setIsMissionStatementError(true);
            return;
        }
        else{
            setMissionStatementErrorTxt('');
            setIsMissionStatementError(false);
        }

        if(quote == ''){
            setQuoteErrorTxt('Quote is required');
            setIsQuoteError(true);
            return;
        }
        else{
            setQuoteErrorTxt('');
            setIsQuoteError(false);
        }

        // if(autherName == ''){
        //     setAutherNameErrorTxt('Auther name is required');
        //     setIsAutherNameError(true);
        //     return;
        // }
        // else{
        //     setAutherNameErrorTxt('');
        //     setIsAutherNameError(false);
        // }

        // if(autherDesignation == ''){
        //     setAutherDesignationErrorTxt('Auther designation is required');
        //     setIsAutherDesignationError(true);
        //     return;
        // }
        // else{
        //     setAutherDesignationErrorTxt('');
        //     setIsAutherDesignationError(false);
        // }

        if(imageID == ''){
            setImageErrorTxt('Featured image is required');
            setIsImageError(true);
            return;
        }
        else{
            setImageErrorTxt('');
            setIsImageError(false);
        }

        const data = {
            section_heading: sectionHeading,
            heading_bold: headingBold,
            heading_regular: headingRegular,
            description: description,
            mission_statement: missionStatement,
            quote: quote,
            quote_author: autherName,
            author_designation: autherDesignation,
            image: imageID
        }

        console.log(data);

        try {
            const updateHomeAboutUsReq = await axios.patch(`${API_BASE_URL}home/about-us/edit`, data, config);
            notifySuccess(HOME_ABOUT_US_UPDATED);
            navigate('/dashboard/pages/home/about-us');
            LOGGER &&  console.log(updateHomeAboutUsReq);
        }
        catch (error) {
            notifyError(REQUEST_ERROR);
            LOGGER &&  console.log(error);
        }
    }

    const resetForm = () => {
        
    }

    return (
        <Section heading="about us" description={routeText} action={() => navigate("/dashboard/pages/home/about-us")} actionLable="back" showAction={true}>
            <PlainContainer styles={{width: '100%', display: 'flex', flexDirection: 'column', position: 'static'}}>
                <form onSubmit={(e) => submitEditThisForm(e)} noValidate>
                    <Form>
                        <TextInput lable="section heading" isMandatory={true} isError={isSectionHeadingError} error={sectionHeadingErrorTxt}>
                            <input type="text" className="formStepInputTxt capitalize" value={sectionHeading} placeholder="Please Enter The Section Heading" onChange={(e) => setSectionHeading(e.target.value)} />
                        </TextInput>
                        <SplitContainer type={`fullwidth`}>
                            <TextInput lable="heading bold" isMandatory={true} isError={isHeadingBoldError} error={headingBoldErrorTxt}>
                                <input type="text" className="formStepInputTxt capitalize" value={headingBold} placeholder="Please Enter The Heading Bold" onChange={(e) => setHeadingBold(e.target.value)} />
                            </TextInput>
                            <TextInput lable="heading regular" isMandatory={true} isError={isHeadingRegularError} error={headingRegularErrorTxt}>
                                <input type="text" className="formStepInputTxt capitalize" value={headingRegular} placeholder="Please Enter The Heading Regular" onChange={(e) => setHeadingRegular(e.target.value)} />
                            </TextInput>
                        </SplitContainer>
                        <TextArea lable="description" rows={5} isMandatory={false} placeholder="Please Enter The Description" value={description} onchange={(data) => setDescription(data)} isError={isDescriptionError} error={descriptionErrorTxt} />
                        <TextArea lable="mission statement" rows={2} isMandatory={false} placeholder="Please Enter The Mission Statement" value={missionStatement} onchange={(data) => setMissionStatement(data)} isError={isMissionStatementError} error={missionStatementErrorTxt} />
                        <TextArea lable="quote" rows={2} isMandatory={false} placeholder="Please Enter The Quote" value={quote} onchange={(data) => setQuote(data)} isError={isQuoteError} error={quoteErrorTxt} />
                        <SplitContainer type={`fullwidth`}>
                            <TextInput lable="auther name" isMandatory={false} isError={isAutherNameError} error={autherNameErrorTxt}>
                                <input type="text" className="formStepInputTxt capitalize" value={autherName} placeholder="Please Enter The Auther Name" onChange={(e) => setAutherName(e.target.value)} />
                            </TextInput>
                            <TextInput lable="auther designation" isMandatory={false} isError={isAutherDesignationError} error={autherDesignationErrorTxt}>
                                <input type="text" className="formStepInputTxt capitalize" value={autherDesignation} placeholder="Please Enter The Auther Designation" onChange={(e) => setAutherDesignation(e.target.value)} />
                            </TextInput>
                        </SplitContainer>
                        <FileInput lable="featured image" isMandatory={true} value={imageID} preview={image} showPreview={showImagePreview} onchange={(data) => {setImageID(data); LOGGER &&  console.log(data)}} isError={isImageError} error={imageErrorTxt} reset={resetAttachment} />
                        <FormActions isPrimary={true} isSecondary={true} primaryLable="submit" secondaryLable="reset" secondaryAction={() => resetForm()} />
                    </Form>
                </form>
            </PlainContainer>
        </Section>
    )
}

export default EditHomeAboutUsPage