import React, { useState, useEffect, useRef, forwardRef} from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';

import PlainContainer from '../../../../../../components/general/containers/PlainContainer';
import SplitContainer from '../../../../../../components/general/containers/SplitContainer';
import Section from '../../../../../../components/general/containers/Section';
import Form from '../../../../../../components/general/forms/core/Form';
import FormActions from '../../../../../../components/general/forms/core/FormActions';
import TextInput from '../../../../../../components/general/forms/TextInput';
import TextArea from '../../../../../../components/general/forms/TextArea';
import Button from '../../../../../../components/general/forms/Button';

import axios from 'axios';

import { useSelector } from 'react-redux';
import { API_BASE_URL, LOGGER } from '../../../../../../config';
import { notifyError, notifySuccess } from '../../../../../../utils/toastify';
import { REQUEST_ERROR, CONTACT_ADDRESS_ADDED } from '../../../../../../utils/messages';

const EditContactAddressPage = () => {
    let navigate = useNavigate();
    const location = useLocation();
    let { address_id } = useParams(); 

    const [routeArray, setRouteArray] = useState(location.pathname.substring(1).split('/'));
    const [routeText, setRouteText] = useState('');

    useEffect(() => {
        let tempRoute = '';
        routeArray.map(route => {
            tempRoute = tempRoute.concat(`${route} / `);
            tempRoute = tempRoute.replace('-', ' ');
        });
        setRouteText(removeLastChars(tempRoute, 3));
    }, []);

    let removeLastChars = (str, chars) => {
        return str.substring(0, str.length - chars);
    }

    const admin = useSelector((state) => state.admin.value);

    const config = {
        headers: {
        'Authorization': admin.access_token
        }
    }

    const [city, setCity] = useState('');
    const [address, setAddress] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [emailID, setEmailID] = useState('');

    const [isCityError, setIsCityError] = useState(false);
    const [isAddressError, setIsAddressError] = useState(false);
    const [isPhoneNumberError, setIsPhoneNumberError] = useState(false);
    const [isEmailIDError, setIsEmailIDError] = useState(false);

    const [cityErrorTxt, setCityErrorTxt] = useState("");
    const [addressErrorTxt, setAddressErrorTxt] = useState("");
    const [phoneNumberErrorTxt, setPhoneNumberErrorTxt] = useState("");
    const [emailIDErrorTxt, setEmailIDErrorTxt] = useState("");

    const getThisContactAddressHandler = async () => {
        try {
            const getThisContactAddressReq = await axios.get(`${API_BASE_URL}contact-us/address/${address_id}`);
            setCity(getThisContactAddressReq.data.city);
            setAddress(getThisContactAddressReq.data.address);
            setPhoneNumber(getThisContactAddressReq.data.phone_number);
            setEmailID(getThisContactAddressReq.data.email_id);
        }
        catch (error) {
            if(error.response.status === 404){
                console.log('not found');
            }
        }
    }

    useEffect(() => {
        getThisContactAddressHandler();
    }, []);

    const submitEditThisForm = async (e) => {
        e.preventDefault();

        if(city == ''){
            setCityErrorTxt('City is required');
            setIsCityError(true);
            return;
        }
        else{
            setCityErrorTxt('');
            setIsCityError(false);
        }

        if(address == ''){
            setAddressErrorTxt('Address is required');
            setIsAddressError(true);
            return;
        }
        else{
            setAddressErrorTxt('');
            setIsAddressError(false);
        }

        if(phoneNumber == ''){
            setPhoneNumberErrorTxt('Phone number is required');
            setIsPhoneNumberError(true);
            return;
        }
        else{
            setPhoneNumberErrorTxt('');
            setIsPhoneNumberError(false);
        }

        if(emailID == ''){
            setEmailIDErrorTxt('Email ID is required');
            setIsEmailIDError(true);
            return;
        }
        else{
            setEmailIDErrorTxt('');
            setIsEmailIDError(false);
        }

        const data = {
            city: city,
            address: address,
            phone_number: phoneNumber,
            email_id: emailID
        }

        try {
            const updateContactUsAddressReq = await axios.patch(`${API_BASE_URL}contact-us/address/${address_id}`, data, config);
            notifySuccess(CONTACT_ADDRESS_ADDED);
            navigate('/dashboard/pages/contact-us/address');
            LOGGER &&  console.log(updateContactUsAddressReq);
        }
        catch (error) {
            notifyError(REQUEST_ERROR);
            LOGGER &&  console.log(error);
        }
    }

    const resetForm = () => {
        
    }

    return (
        <Section heading="Openings" description={routeText} action={() => navigate("/dashboard/pages/contact-us/address")} actionLable="back" showAction={true}>
            <PlainContainer styles={{width: '100%', display: 'flex', flexDirection: 'column', position: 'static'}}>
                <form onSubmit={(e) => submitEditThisForm(e)} noValidate>
                    <Form>
                        <SplitContainer type={`fullwidth`}>
                            <TextInput lable="phone number" isMandatory={true} isError={isPhoneNumberError} error={phoneNumberErrorTxt}>
                                <input type="text" className="formStepInputTxt capitalize" value={phoneNumber} placeholder="Please Enter The Phone Number" onChange={(e) => setPhoneNumber(e.target.value)} />
                            </TextInput>
                            <TextInput lable="email ID" isMandatory={true} isError={isEmailIDError} error={emailIDErrorTxt}>
                                <input type="text" className="formStepInputTxt capitalize" value={emailID} placeholder="Please Enter The Email ID" onChange={(e) => setEmailID(e.target.value)} />
                            </TextInput>
                        </SplitContainer>
                        <TextInput lable="city" isMandatory={true} isError={isCityError} error={cityErrorTxt}>
                            <input type="text" className="formStepInputTxt capitalize" value={city} placeholder="Please Enter The City" onChange={(e) => setCity(e.target.value)} />
                        </TextInput>
                        <TextArea lable="address" rows={3} isMandatory={true} placeholder="Please Enter The Address" value={address} onchange={(data) => setAddress(data)} isError={isAddressError} error={addressErrorTxt} />
                        <FormActions isPrimary={true} isSecondary={true} primaryLable="submit" secondaryLable="reset" secondaryAction={() => resetForm()} />
                    </Form>
                </form>
            </PlainContainer>
        </Section>
    )
}

export default EditContactAddressPage