import React, { useState, useEffect, useRef, forwardRef} from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';

import PlainContainer from '../../../../../../components/general/containers/PlainContainer';
import SplitContainer from '../../../../../../components/general/containers/SplitContainer';
import Section from '../../../../../../components/general/containers/Section';
import Form from '../../../../../../components/general/forms/core/Form';
import FormActions from '../../../../../../components/general/forms/core/FormActions';
import TextInput from '../../../../../../components/general/forms/TextInput';
import TextArea from '../../../../../../components/general/forms/TextArea';
import Button from '../../../../../../components/general/forms/Button';

import axios from 'axios';

import { useSelector } from 'react-redux';
import { API_BASE_URL, LOGGER } from '../../../../../../config';
import { notifyError, notifySuccess } from '../../../../../../utils/toastify';
import { REQUEST_ERROR, PHONE_NUMBER_UPDATED } from '../../../../../../utils/messages';

const EditContactPhoneNumberPage = () => {
    let navigate = useNavigate();
    const location = useLocation();
    let { phone_number_id } = useParams(); 

    const [routeArray, setRouteArray] = useState(location.pathname.substring(1).split('/'));
    const [routeText, setRouteText] = useState('');

    useEffect(() => {
        let tempRoute = '';
        routeArray.map(route => {
            tempRoute = tempRoute.concat(`${route} / `);
            tempRoute = tempRoute.replace('-', ' ');
        });
        setRouteText(removeLastChars(tempRoute, 3));
    }, []);

    let removeLastChars = (str, chars) => {
        return str.substring(0, str.length - chars);
    }

    const admin = useSelector((state) => state.admin.value);

    const config = {
        headers: {
        'Authorization': admin.access_token
        }
    }

    const [lable, setLable] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');

    const [isLableError, setIsLableError] = useState(false);
    const [isPhoneNumberError, setIsPhoneNumberError] = useState(false);

    const [lableErrorTxt, setLableErrorTxt] = useState("");
    const [phoneNumberErrorTxt, setPhoneNumberErrorTxt] = useState("");

    const getThisContactPhoneNumberHandler = async () => {
        try {
            const getThisContactPhoneNumberReq = await axios.get(`${API_BASE_URL}contact-us/phone-number/${phone_number_id}`);
            setLable(getThisContactPhoneNumberReq.data.lable);
            setPhoneNumber(getThisContactPhoneNumberReq.data.number);
        }
        catch (error) {
            if(error.response.status === 404){
                console.log('not found');
            }
        }
    }

    useEffect(() => {
        getThisContactPhoneNumberHandler();
    }, []);

    const submitEditThisForm = async (e) => {
        e.preventDefault();

        if(lable == ''){
            setLableErrorTxt('City is required');
            setIsLableError(true);
            return;
        }
        else{
            setLableErrorTxt('');
            setIsLableError(false);
        }

        if(phoneNumber == ''){
            setPhoneNumberErrorTxt('Phone number is required');
            setIsPhoneNumberError(true);
            return;
        }
        else{
            setPhoneNumberErrorTxt('');
            setIsPhoneNumberError(false);
        }

        const data = {
            lable: lable,
            number: phoneNumber
        }

        try {
            const updateContactUsPhoneNumberReq = await axios.patch(`${API_BASE_URL}contact-us/phone-number/${phone_number_id}`, data, config);
            notifySuccess(PHONE_NUMBER_UPDATED);
            navigate('/dashboard/pages/contact-us/phone-numbers');
            LOGGER &&  console.log(updateContactUsPhoneNumberReq);
        }
        catch (error) {
            notifyError(REQUEST_ERROR);
            LOGGER &&  console.log(error);
        }
    }

    const resetForm = () => {
        
    }

    return (
        <Section heading="Phone Numbers" description={routeText} action={() => navigate("/dashboard/pages/contact-us/phone-numbers")} actionLable="back" showAction={true}>
            <PlainContainer styles={{width: '100%', display: 'flex', flexDirection: 'column', position: 'static'}}>
                <form onSubmit={(e) => submitEditThisForm(e)} noValidate>
                    <Form>
                        <SplitContainer type={`fullwidth`}>
                            <TextInput lable="lable" isMandatory={true} isError={isLableError} error={lableErrorTxt}>
                                <input type="text" className="formStepInputTxt capitalize" value={lable} placeholder="Please Enter The Lable" onChange={(e) => setLable(e.target.value)} />
                            </TextInput>
                            <TextInput lable="phone number" isMandatory={true} isError={isPhoneNumberError} error={phoneNumberErrorTxt}>
                                <input type="text" className="formStepInputTxt capitalize" value={phoneNumber} placeholder="Please Enter The Phone Number" onChange={(e) => setPhoneNumber(e.target.value)} />
                            </TextInput>
                        </SplitContainer>
                        <FormActions isPrimary={true} isSecondary={true} primaryLable="submit" secondaryLable="reset" secondaryAction={() => resetForm()} />
                    </Form>
                </form>
            </PlainContainer>
        </Section>
    )
}

export default EditContactPhoneNumberPage