import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from "react-router-dom";

import DetailSection from '../../../../../../components/dashboard/common/detailSection/DetailSection';
import DetailSectionContent from '../../../../../../components/dashboard/common/detailSection/DetailSectionContent';
import DetailSectionRow from '../../../../../../components/dashboard/common/detailSection/DetailSectionRow';

import Table from '../../../../../../components/dashboard/common/table/Table';
import Heading from '../../../../../../components/dashboard/common/table/Heading';
import HeadingItem from '../../../../../../components/dashboard/common/table/HeadingItem';
import TableRow from '../../../../../../components/dashboard/common/table/TableRow';
import RowItem from '../../../../../../components/dashboard/common/table/RowItem';
import RowIcon from '../../../../../../components/dashboard/common/table/RowIcon';
import TableButton from '../../../../../../components/dashboard/common/table/TableButton';

import NoDataContainer from '../../../../../../components/general/containers/NoDataContainer';
import PlainContainer from '../../../../../../components/general/containers/PlainContainer';
import Section from '../../../../../../components/general/containers/Section';
import { API_BASE_URL, LOGGER } from '../../../../../../config';
import { notifyError, notifySuccess } from '../../../../../../utils/toastify';
import { REQUEST_ERROR } from '../../../../../../utils/messages';
import RowImage from '../../../../../../components/dashboard/common/table/RowImage';

const ProductsCategoryDetailsPage = () => {
    const admin = useSelector((state) => state.admin.value);
    let { category_id } = useParams();

    let navigate = useNavigate();
    const location = useLocation();
    const [routeArray, setRouteArray] = useState(location.pathname.substring(1).split('/'));
    const [routeText, setRouteText] = useState('');
    const [productCategory, setProductCategory] = useState({});
    const [showCategory, setShowCategory] = useState(false);
  
    useEffect(() => {
        let tempRoute = '';
        routeArray.map(route => {
            tempRoute = tempRoute.concat(`${route} / `);
            tempRoute = tempRoute.replace('-', ' ');
        });
        setRouteText(removeLastChars(tempRoute, 3));
    }, []);
  
    let removeLastChars = (str, chars) => {
        return str.substring(0, str.length - chars);
    }
  
    const config = {
        headers: {
            'Authorization': admin.access_token
        }
    }

    const getThisProductCategoryHandler = async () => {
        try {
            const getProductCategoryReq = await axios.get(`${API_BASE_URL}product/category/${category_id}`);
            console.log(getProductCategoryReq.data);
            setProductCategory(getProductCategoryReq.data);
            if(getProductCategoryReq.data){
                setShowCategory(true);
            }
            else{
                setShowCategory(false);
            }
        }
        catch (error) {
            if(error.response.status === 404){
                console.log('not found');
            }
        }
    }

    useEffect(() => {
        getThisProductCategoryHandler();
    }, []);

    const deleteHighlight = async (highlight_id) => {
        try {
            const deleteCategoryHighlightReq = await axios.delete(`${API_BASE_URL}product/category/highlight/${highlight_id}`, config);
            //notifySuccess(CATEGORY_ADDED);
            getThisProductCategoryHandler();
            LOGGER &&  console.log(deleteCategoryHighlightReq);
        }
        catch (error) {
            notifyError(REQUEST_ERROR);
            LOGGER &&  console.log(error);
        }
    }

    const deletePdf = async (pdf_id) => {
        try {
            const deleteCategoryPdfReq = await axios.delete(`${API_BASE_URL}product/category/pdf/${pdf_id}`, config);
            //notifySuccess(CATEGORY_ADDED);
            getThisProductCategoryHandler();
            LOGGER &&  console.log(deleteCategoryPdfReq);
        }
        catch (error) {
            notifyError(REQUEST_ERROR);
            LOGGER &&  console.log(error);
        }
    }

    const deleteAccessory = async (accessory_id) => {
        try {
            const deleteCategoryAccessoryReq = await axios.delete(`${API_BASE_URL}product/category/accessory/${accessory_id}`, config);
            //notifySuccess(CATEGORY_ADDED);
            getThisProductCategoryHandler();
            LOGGER &&  console.log(deleteCategoryAccessoryReq);
        }
        catch (error) {
            notifyError(REQUEST_ERROR);
            LOGGER &&  console.log(error);
        }
    }

    return (
        <>
            <Section heading="Category Details" description={routeText} action={() => navigate(`/dashboard/pages/products/categories/edit/${category_id}`)} actionLable="edit" showAction={true}>
                <PlainContainer type="fullwidth">
                    {
                        showCategory === true
                        ?
                        <>
                            <DetailSection lable="Category" hasPrimaryAction={false} lablePrimaryAction={`edit`} primaryAction={() => navigate("/dashboard/pages/products/categories/edit")} hasSecondaryAction={false}>
                                <DetailSectionContent hasPadding={true}>
                                    <DetailSectionRow lable={`name`} value={productCategory.category.name} isImage={false} isIcon={false} hasChildren={false} />
                                    <DetailSectionRow lable={`description`} value={productCategory.category.description} isImage={false} isIcon={false} hasChildren={false} />
                                    <DetailSectionRow lable={`SEO title`} value={productCategory.seo.title} isImage={false} isIcon={false} hasChildren={false} />
                                    <DetailSectionRow lable={`SEO page URL`} value={productCategory.seo.slug} isImage={false} isIcon={false} hasChildren={false} />
                                    <DetailSectionRow lable={`SEO description`} value={productCategory.seo.description} isImage={false} isIcon={false} hasChildren={false} />
                                    <DetailSectionRow lable={`SEO keywords`} value={productCategory.seo.keywords} isImage={false} isIcon={false} hasChildren={false} />
                                    <DetailSectionRow lable={`featured image`} value={productCategory.category.featured_image?.url} isImage={true} isIcon={false} hasChildren={false} />
                                </DetailSectionContent>
                            </DetailSection>

                            <PlainContainer type={`fullwidth`} styles={{marginTop: `25px`}}>
                                <DetailSection lable="Category Highlights" hasPrimaryAction={true} lablePrimaryAction={`add new`} primaryAction={() => navigate(`/dashboard/pages/products/categories/highlights/create/${category_id}`)} hasSecondaryAction={false}>
                                    <DetailSectionContent hasPadding={true}>
                                        <Table>
                                            <Heading>
                                                <HeadingItem size="1" isIcon={false} icon="" lable={`sl no`} />
                                                <HeadingItem size="4" isIcon={false} icon="" lable={`title`} />
                                                <HeadingItem size="2" isIcon={false} icon="" lable={`action`} />
                                            </Heading>
                                            <PlainContainer type="fullwidth">
                                            {
                                                productCategory.highlights.map((highlight, i) => {
                                                    return (
                                                        <TableRow key={highlight.id}>
                                                            <RowItem size="1" lable={i+1} isCenter={true} hasChildren={false}></RowItem>
                                                            <RowItem size="4" lable={highlight.description} isCenter={false} hasChildren={false}></RowItem>
                                                            <RowItem size="2" lable="3" isCenter={false} hasChildren={true}>
                                                                <TableButton styles={{width: 'calc(50% - 5px)', marginRight: '10px'}} action={() => navigate(`/dashboard/pages/products/categories/highlights/edit/${highlight.id}`)} type="primary">edit</TableButton>
                                                                <TableButton styles={{width: 'calc(50% - 5px)'}} action={() => deleteHighlight(highlight.id)} type="danger">delete</TableButton>
                                                            </RowItem>
                                                        </TableRow>
                                                    )
                                                })
                                            }
                                            </PlainContainer>
                                        </Table>
                                    </DetailSectionContent>
                                </DetailSection>
                            </PlainContainer>

                            <PlainContainer type={`fullwidth`} styles={{marginTop: `25px`}}>
                                <DetailSection lable="Category PDF's" hasPrimaryAction={true} lablePrimaryAction={`add new`} primaryAction={() => navigate(`/dashboard/pages/products/categories/pdfs/create/${category_id}`)} hasSecondaryAction={false}>
                                    <DetailSectionContent hasPadding={true}>
                                        <Table>
                                            <Heading>
                                                <HeadingItem size="1" isIcon={false} icon="" lable={`sl no`} />
                                                <HeadingItem size="4" isIcon={false} icon="" lable={`title`} />
                                                <HeadingItem size="1" isIcon={false} icon="" lable={``} />
                                                <HeadingItem size="2" isIcon={false} icon="" lable={`action`} />
                                            </Heading>
                                            <PlainContainer type="fullwidth">
                                            {
                                                productCategory.pdfs.map((pdf, i) => {
                                                    return (
                                                        <TableRow key={pdf.id}>
                                                            <RowItem size="1" lable={i+1} isCenter={true} hasChildren={false}></RowItem>
                                                            <RowItem size="4" lable={pdf.title} isCenter={false} hasChildren={false}></RowItem>
                                                            <RowItem size="1" isCenter={false} hasChildren={true}>
                                                                <RowIcon name="fa-solid fa-eye" action={()=> window.open(pdf.pdf_url?.url)} styles={{cursor: 'pointer'}} />
                                                            </RowItem>
                                                            <RowItem size="2" lable="3" isCenter={false} hasChildren={true}>
                                                                <TableButton styles={{width: 'calc(50% - 5px)', marginRight: '10px'}} action={() => navigate(`/dashboard/pages/products/categories/pdfs/edit/${pdf.id}`)} type="primary">edit</TableButton>
                                                                <TableButton styles={{width: 'calc(50% - 5px)'}} action={() => deletePdf(pdf.id)} type="danger">delete</TableButton>
                                                            </RowItem>
                                                        </TableRow>
                                                    )
                                                })
                                            }
                                            </PlainContainer>
                                        </Table>
                                    </DetailSectionContent>
                                </DetailSection>
                            </PlainContainer>

                            <PlainContainer type={`fullwidth`} styles={{marginTop: `25px`}}>
                                <DetailSection lable="Category Accessories" hasPrimaryAction={true} lablePrimaryAction={`add new`} primaryAction={() => navigate(`/dashboard/pages/products/categories/accessories/create/${category_id}`)} hasSecondaryAction={false}>
                                    <DetailSectionContent hasPadding={true}>
                                        <Table>
                                            <Heading>
                                                <HeadingItem size="1" isIcon={false} icon="" lable={`sl no`} />
                                                <HeadingItem size="4" isIcon={false} icon="" lable={`title`} />
                                                <HeadingItem size="1" isIcon={false} icon="" lable={``} />
                                                <HeadingItem size="2" isIcon={false} icon="" lable={`action`} />
                                            </Heading>
                                            <PlainContainer type="fullwidth">
                                            {
                                                productCategory.accessories.map((accessory, i) => {
                                                    return (
                                                        <TableRow key={accessory.id}>
                                                            <RowItem size="1" lable={i+1} isCenter={true} hasChildren={false}></RowItem>
                                                            <RowItem size="4" lable={accessory.title} isCenter={false} hasChildren={false}></RowItem>
                                                            <RowItem size="1" isCenter={false} hasChildren={true}>
                                                                <RowImage src={accessory.image_url} />
                                                            </RowItem>
                                                            <RowItem size="2" lable="3" isCenter={false} hasChildren={true}>
                                                                <TableButton styles={{width: 'calc(50% - 5px)', marginRight: '10px'}} action={() => navigate(`/dashboard/pages/products/categories/accessories/edit/${accessory.id}`)} type="primary">edit</TableButton>
                                                                <TableButton styles={{width: 'calc(50% - 5px)'}} action={() => deleteAccessory(accessory.id)} type="danger">delete</TableButton>
                                                            </RowItem>
                                                        </TableRow>
                                                    )
                                                })
                                            }
                                            </PlainContainer>
                                        </Table>
                                    </DetailSectionContent>
                                </DetailSection>
                            </PlainContainer>
                            
                        </>
                        :
                        <></>
                    }
                    
                </PlainContainer>
            </Section>
        </>
    )
}

export default ProductsCategoryDetailsPage