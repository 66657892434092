import React from 'react';

const PlainContainer = ({type, styles, children}) => {

    return (
        <div className={type === 'fullwidth' ? "plainContainer fullWidth" : "plainContainer"} style={styles}>
            {children}
        </div>    
    );
};

export default PlainContainer;
