import React from 'react'

const RowImage = ({src}) => {
  return (
    <div className="tableRowItemImage center">
        <div className="tableRowItemImageMain">
            <img src={src?.url} className="tableRowItemImg" alt={src?.alt_text} />
        </div>
    </div>
  )
}

export default RowImage